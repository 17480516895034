import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { useTitle } from '@dimatech/shared/lib/hooks';
import { Projects as ProjectsFeature } from 'features/project/Projects';
import { ProjectsBaseLicense as ProjectsBaseLicenseFeature } from 'features/project/ProjectsBaseLicense';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { View } from 'views';

export const Projects = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const location = useLocation();
  const { setTitle } = useTitle();

  useEffect(() => {
    let title = t('Projects.KeyStatistics.Title');

    switch (true) {
      case location.pathname.endsWith('balance-over-time'):
        title = t('Projects.BudgetAndBalanceOverTime.Title');
        break;
      case location.pathname.endsWith('balance'):
        title = t('Projects.BudgetAndBalance.Title');
        break;
      case location.pathname.endsWith('tag'):
        title = t('Projects.TagTable.Title');
        break;
      case location.pathname.endsWith('table'):
        title = t('Projects.List.Title');
        break;
      case location.pathname.endsWith('timeline'):
        title = t('Projects.Timeline.Title');
        break;
    }

    setTitle(`${t('Project.Project')} - ${title}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <View title={t('Projects.Title')}>
      {!accessToken.isBaseLicense ? (
        <ProjectsFeature />
      ) : (
        <ProjectsBaseLicenseFeature />
      )}
    </View>
  );
};

Projects.displayName = 'Projects';
