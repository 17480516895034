import { selectSelectedEntity } from '@dimatech/features-core/lib/api/entity/entitySlice';
import {
  useCreateOrUpdateCustomerAdminMutation,
  useDeleteCustomerAdminMutation,
  useGetCustomerAdminsQuery,
} from '@dimatech/features-core/lib/api/user/userApi';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  Impersonate,
  SendForgotPasswordEmail,
} from '@dimatech/features-core/lib/features/authentication/components';
import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import {
  HelpIcon,
  HelpIconLeft,
} from '@dimatech/shared/lib/components/HelpIcon';
import {
  Button,
  ButtonLink,
  ButtonSecondary,
  Buttons,
  Checkbox,
  Label,
} from '@dimatech/shared/lib/components/form';
import { Loader } from '@dimatech/shared/lib/components/loader';
import { Modal } from '@dimatech/shared/lib/components/modal';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdRight,
  Th,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Heading3 } from '@dimatech/shared/lib/components/typography';
import { useSortableData } from '@dimatech/shared/lib/hooks';
import { TransArray } from '@dimatech/shared/lib/localization';
import { Admin, ApiError, SortDirection } from '@dimatech/shared/lib/models';
import { SerializedError } from '@reduxjs/toolkit';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { parseISO } from 'date-fns';
import { useAppSelector } from 'hooks';
import { CommonRoles } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPersonBadge, BsPersonPlus, BsTrash } from 'react-icons/bs';
import { formatDate, isAdminReadOnly } from 'utils';
import { EntityUserAdd } from './EntityUserAdd';

export const EntityAdmins = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);
  const selectedEntity = useAppSelector(selectSelectedEntity);

  const [createOrUpdate, { error: createError }] =
    useCreateOrUpdateCustomerAdminMutation();
  const { data, isFetching } = useGetCustomerAdminsQuery(
    selectedEntity && selectedEntity.id && accessToken.customerId
      ? {
          filter: {
            entityIds: [selectedEntity.id],
          },
          _customerId: accessToken.customerId,
        }
      : skipToken
  );

  const { items, sorter } = useSortableData(data?.records ?? [], {
    key: 'email',
    direction: SortDirection.Asc,
  });

  const [isAdding, setIsAdding] = useState(false);
  const [includeChildEntities, setIncludeChildEntities] = useState(true);
  const [error, setError] = useState<ApiError | SerializedError>();

  const handleAddAdmin = (user: Admin) => {
    setError(undefined);

    if (!selectedEntity?.id || !user.email) {
      return;
    }

    createOrUpdate({
      user: {
        email: user.email,
        isAdmin: true,
        entityId: selectedEntity.id,
        includeChildEntities,
      },
    });
    setIncludeChildEntities(true);
  };

  useEffect(() => {
    setError(undefined);
  }, [selectedEntity]);

  useEffect(() => {
    setError(createError);
  }, [createError]);

  if (isFetching) {
    return (
      <div style={{ marginTop: 20 }}>
        <Loader>{t('Common.UI.Loading')}</Loader>
      </div>
    );
  }

  return selectedEntity?.id ? (
    <>
      <Heading3 style={{ marginTop: 30 }}>
        {t('UserRoles.CustomerAdmin.Title')}
      </Heading3>

      <HelpIconLeft style={{ marginTop: 10, marginBottom: 10 }}>
        {t('UserRoles.CustomerAdmin.Text')}

        <HelpIcon
          style={{ marginLeft: 7 }}
          title={t('UserRoles.CustomerAdmin.Title')}
          text={<TransArray i18nKey="UserRoles.CustomerAdmin.Help" />}
        />
      </HelpIconLeft>

      <div style={{ display: 'flex', marginTop: 10, marginBottom: 10 }}>
        <TooltipContent
          id="tt-admin-add"
          title={t('Administrate.Entity.Admin.Add.TooltipTitle')}
          text={t('Administrate.Entity.Admin.Add.Tooltip')}
        />
        <BadgeMini
          data-tip
          data-for="tt-admin-add"
          onClick={() => setIsAdding(true)}
        >
          <BsPersonPlus />
        </BadgeMini>

        <ButtonLink
          data-tip
          data-for="tt-admin-add"
          onClick={() => setIsAdding(true)}
          style={{ marginLeft: 10 }}
        >
          {t('Administrate.Entity.Admin.Add.Title')}
        </ButtonLink>
      </div>

      <AlertErrors
        error={error}
        translationPath="Administrate.Entity.User.ValidationError"
      />

      {data?.totalRecords === 0 && (
        <div style={{ marginTop: 20, marginBottom: 10 }}>
          {t('Administrate.Entity.Admin.NoAdmins')}
        </div>
      )}

      {isAdding && (
        <EntityUserAdd
          setIsAdding={setIsAdding}
          handleAdd={handleAddAdmin}
          role={CommonRoles.CustomerAdmin}
          includeChildEntities={includeChildEntities}
          setIncludeChildEntities={setIncludeChildEntities}
        />
      )}

      {data && data.totalRecords > 0 && (
        <TableResponsiveContainer>
          <Table style={{ marginTop: 10, marginBottom: 10 }}>
            <thead>
              <tr>
                <Th />
                <Th sortKey="email" sorter={sorter}>
                  {t('Administrate.Entity.Email')}
                </Th>
                <ThRight sortKey="lastLoginDate" sorter={sorter}>
                  {t('Administrate.Entity.LastLogin')}
                </ThRight>
                <Th />
              </tr>
            </thead>
            <tbody>
              {items?.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td style={{ width: 25 }}>
                      <BsPersonBadge />
                    </Td>
                    <Td>{item.email}</Td>
                    <TdRight
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {item.lastLoginDate
                        ? formatDate(parseISO(item.lastLoginDate))
                        : '-'}
                    </TdRight>
                    <TdRight>
                      <div
                        style={{
                          verticalAlign: 'middle',
                          display: 'flex',
                          justifyContent: 'end',
                        }}
                      >
                        <EditButtons
                          user={item}
                          setError={setError}
                          isReadOnly={isReadOnly}
                        />
                      </div>
                    </TdRight>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
        </TableResponsiveContainer>
      )}
    </>
  ) : null;
};

EntityAdmins.displayName = 'EntityAdmins';

const EditButtons = ({
  user,
  setError,
  isReadOnly,
}: {
  user: Admin;
  setError: (error: ApiError | SerializedError | undefined) => void;
  isReadOnly: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const [deleteAdmin] = useDeleteCustomerAdminMutation();
  const [isDeleting, setIsDeleting] = useState(false);
  const selectedEntity = useAppSelector(selectSelectedEntity);
  const [includeChildEntities, setIncludeChildEntities] = useState(true);

  const handleConfirmDelete = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (isReadOnly) {
      return;
    }

    deleteAdmin({
      user: {
        email: user.email,
        isAdmin: true,
        entityId: selectedEntity?.id,
        includeChildEntities,
      },
    });

    setIsDeleting(false);
    setIncludeChildEntities(true);
  };

  const handleDelete = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setError(undefined);
    setIsDeleting(show);
  };

  return (
    <>
      {isDeleting && (
        <Modal
          title={t('Administrate.Entity.Admin.Delete.ConfirmTitle')}
          handleKeyEnter={(e) => handleConfirmDelete(e)}
          handleKeyEsc={(e) => handleDelete(e, false)}
        >
          <div>{t('Administrate.Entity.Admin.Delete.ConfirmText')}</div>

          {selectedEntity?.childCount !== undefined &&
            selectedEntity.childCount > 0 && (
              <div>
                <Label htmlFor="includeChildEntities">
                  <Checkbox
                    id="includeChildEntities"
                    name="includeChildEntities"
                    style={{ marginRight: 10 }}
                    checked={includeChildEntities ?? true}
                    onChange={(e) =>
                      setIncludeChildEntities(e.currentTarget.checked)
                    }
                  />
                  {t('Administrate.Entity.Admin.Delete.IncludeChildEntities')}
                </Label>
              </div>
            )}

          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={(e) => handleDelete(e, false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>

            <Button
              type="button"
              onClick={(e) => handleConfirmDelete(e)}
              disabled={isReadOnly}
            >
              {t('Common.UI.Yes')}
            </Button>
          </Buttons>
        </Modal>
      )}

      <Impersonate email={user.email as string} />
      <SendForgotPasswordEmail email={user.email as string} />

      <TooltipContent
        id="tt-admin-delete"
        title={t('Administrate.Entity.Admin.Delete.TooltipTitle')}
        text={t('Administrate.Entity.Admin.Delete.Tooltip')}
      />
      <BadgeMini
        data-tip
        data-for="tt-admin-delete"
        onClick={(e) => handleDelete(e, true)}
        style={{ marginLeft: 7 }}
      >
        <BsTrash />
      </BadgeMini>
    </>
  );
};
