import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { HelpIcon } from '@dimatech/shared/lib/components/HelpIcon';
import {
  Button,
  ButtonSecondary,
  Buttons,
  Checkbox,
  Input,
  InputNumber,
  Label,
  MonthPicker,
  TextArea,
  YearPicker,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { Td, Tr } from '@dimatech/shared/lib/components/table';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Heading4, Heading5 } from '@dimatech/shared/lib/components/typography';
import { CardRow, CardTile } from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { Theme } from '@dimatech/shared/lib/themes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetGoalsQuery } from 'api/project/projectGoalApi';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Permission,
  Phase,
  Project,
  ProjectDimensionMutation,
  ProjectPeriod,
} from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCalculator } from 'react-icons/bs';
import styled from 'styled-components';
import {
  calculatePeriodsAndBudgets,
  formatAsCurrency,
  hasPermission,
  isPiosReadOnly,
  isReadOnly,
} from 'utils';
import { ProjectDataTable } from './ProjectDataTable';
import { ProjectDimensionsEdit } from './ProjectDimensionsEdit';
import { ProjectDimensionsList } from './ProjectDimensionsList';
import { ProjectPeriodEdit } from './ProjectPeriodEdit';
import { ProjectPeriodList } from './ProjectPeriodList';
import { ProjectPhaseEdit } from './ProjectPhaseEdit';
import { ProjectPublishedPubliclyConfirmation } from './ProjectPublishedPubliclyConfirmation';
import { ProjectPublishedPubliclyIcon } from './ProjectPublishedPubliclyIcon';
import { ProjectStatusEdit } from './ProjectStatusEdit';

// TODO: Split code
/* eslint-disable max-lines-per-function */

export const ProjectEdit = ({
  project,
  setProject,
  periods,
  setPeriods,
  dimensions,
  setDimensions,
  setHasChanges,
  isValid,
}: {
  project: Project;
  setProject: (project: Project) => void;
  periods?: ProjectPeriod[];
  setPeriods: (periods: ProjectPeriod[]) => void;
  dimensions?: ProjectDimensionMutation[];
  setDimensions: (dimensions?: ProjectDimensionMutation[]) => void;
  setHasChanges: (hasChanges: boolean) => void;
  isValid: boolean;
}): JSX.Element | null => {
  const { t } = useTranslation();

  const isPublicApiEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isPublicApiEnabled];
  const isProjectEffectRealisationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectEffectRealisationEnabled];
  const isStartAndEndMonthEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isStartAndEndMonthEnabled];
  const isPublishPubliclyEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isPublishPubliclyEnabled];

  const { accessToken } = useContext(AuthenticationContext);

  const isViewReadOnly = isPiosReadOnly(accessToken);
  const isViewLimitedAsEffectRealisationResponsible =
    !hasPermission(Permission.Edit, project) &&
    hasPermission(Permission.EditEffectRealization, project);

  const [isChangingReadyForExport, setIsChangingReadyForExport] =
    useState(false);

  const [isChangingPublishedPublicly, setIsChangingPublishedPublicly] =
    useState(false);

  const { data: goals } = useGetGoalsQuery(
    accessToken.customerId &&
      project.id &&
      isProjectEffectRealisationEnabledFlagOn
      ? {
          projectId: project.id,
          _customerId: accessToken.customerId,
        }
      : skipToken
  );

  const handelChangePublishedPublicly = () => {
    setIsChangingPublishedPublicly(false);
    setHasChanges(true);
    setProject({
      ...project,
      isPublishedPublicly: !project.isPublishedPublicly,
    });
  };

  const calculatePeriods = (distributeBudget: boolean) => {
    const calculatedPeriods = calculatePeriodsAndBudgets(
      project,
      periods,
      distributeBudget,
      isStartAndEndMonthEnabledFlagOn
    );

    if (!calculatedPeriods) {
      return;
    }

    setPeriods(calculatedPeriods);
  };

  useEffect(() => {
    calculatePeriods(!periods || periods.length === 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.budget, project?.startYear, project?.endYear]);

  return project && !isReadOnly(project) ? (
    <Style>
      <CardRow>
        <CardTile>
          <Label htmlFor="title">
            {t('Project.Title')}
            <ProjectPublishedPubliclyIcon project={project} />
          </Label>
          <Input
            autoFocus={!project.id}
            type="text"
            id="title"
            name="title"
            value={project.title ?? ''}
            invalid={!isValid && !project?.title?.trim()}
            onChange={(e) => {
              setHasChanges(true);
              setProject({ ...project, title: e.currentTarget.value });
            }}
            readOnly={isViewLimitedAsEffectRealisationResponsible}
          />
        </CardTile>
      </CardRow>

      <CardRow style={{ gap: 0 }}>
        <CardTile
          style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        >
          <TableStyle>
            <tbody>
              <Tr>
                <Td>
                  <Label htmlFor="externalId">
                    {t('Project.ExternalId')}

                    <HelpIcon
                      showTiny={true}
                      style={{ marginLeft: 7 }}
                      title={t('Project.ExternalId')}
                      text={t('Project.ExternalIdHelp')}
                    />
                  </Label>
                </Td>
                <Td>
                  <Input
                    type="text"
                    id="externalId"
                    name="externalId"
                    value={project.externalId ?? ''}
                    style={{ width: 150 }}
                    onChange={(e) => {
                      setHasChanges(true);
                      setProject({
                        ...project,
                        externalId: e.currentTarget.value,
                      });
                    }}
                    readOnly={isViewLimitedAsEffectRealisationResponsible}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Label htmlFor="projectPhase">
                    {t('Project.Phase.Phase')}
                    <ProjectPublishedPubliclyIcon project={project} />
                  </Label>
                </Td>
                <Td>
                  <ProjectPhaseEdit
                    project={project}
                    handleChange={(phase) => {
                      setHasChanges(true);
                      setProject({
                        ...project,
                        projectPhase: phase,
                      });
                    }}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Label>{t('Project.Status.Status')}</Label>
                </Td>
                <Td>
                  <ProjectStatusEdit
                    project={project}
                    setProject={(project) => {
                      setHasChanges(true);
                      setProject(project);
                    }}
                  />
                </Td>
              </Tr>
            </tbody>
          </TableStyle>
        </CardTile>

        <CardTile style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
          <TableStyle>
            <tbody>
              <Tr>
                <Td>
                  <Label htmlFor="hasEffectTakeHomePlan">
                    {t('Project.EffectTakeHome.Label')}
                  </Label>
                </Td>
                <Td>
                  <Checkbox
                    id="hasEffectTakeHomePlan"
                    name="hasEffectTakeHomePlan"
                    checked={!!project.hasEffectTakeHomePlan}
                    style={{ marginRight: 7 }}
                    onChange={(e) => {
                      if (isViewLimitedAsEffectRealisationResponsible) {
                        return;
                      }

                      setHasChanges(true);
                      setProject({
                        ...project,
                        hasEffectTakeHomePlan: e.currentTarget.checked,
                      });
                    }}
                    readOnly={isViewLimitedAsEffectRealisationResponsible}
                  />
                </Td>
              </Tr>

              {isPublicApiEnabledFlagOn && (
                <Tr>
                  <Td>
                    <Label htmlFor="isReadyForExport">
                      {t('Project.IsReadyForExport.Label')}
                    </Label>
                  </Td>
                  <Td>
                    <Checkbox
                      id="isReadyForExport"
                      name="isReadyForExport"
                      checked={!!project.isReadyForExport}
                      style={{ marginRight: 7 }}
                      onChange={() => {
                        if (isViewLimitedAsEffectRealisationResponsible) {
                          return;
                        }

                        setIsChangingReadyForExport(true);
                      }}
                      readOnly={isViewLimitedAsEffectRealisationResponsible}
                    />
                  </Td>
                </Tr>
              )}

              {isPublishPubliclyEnabledFlagOn && (
                <>
                  <Tr>
                    <Td>
                      <Label htmlFor="isPublishedPublicly">
                        {t('Project.IsPublishedPublicly.Label')}
                      </Label>
                    </Td>
                    <Td>
                      {project.projectPhase !== Phase.Draft &&
                      project.projectPhase !== Phase.Ready ? (
                        <Checkbox
                          id="isPublishedPublicly"
                          name="isPublishedPublicly"
                          checked={!!project.isPublishedPublicly}
                          style={{ marginRight: 7 }}
                          onChange={() => {
                            if (isViewLimitedAsEffectRealisationResponsible) {
                              return;
                            }

                            setIsChangingPublishedPublicly(true);
                          }}
                          readOnly={isViewLimitedAsEffectRealisationResponsible}
                        />
                      ) : (
                        <Checkbox checked={false} disabled={true} />
                      )}
                    </Td>
                  </Tr>
                  {(project.projectPhase === Phase.Draft ||
                    project.projectPhase === Phase.Ready) && (
                    <Tr style={{ height: '10px' }}>
                      <Td colSpan={2}>
                        <HelpText>
                          {t('Project.IsPublishedPublicly.HelpText')}
                        </HelpText>
                      </Td>
                    </Tr>
                  )}
                </>
              )}
            </tbody>
          </TableStyle>
        </CardTile>
      </CardRow>

      {dimensions && dimensions.length > 0 && (
        <CardRow style={{ gap: 0 }}>
          {isViewLimitedAsEffectRealisationResponsible && (
            <ProjectDimensionsList
              project={project}
              customDimensions={dimensions}
              isProject={true}
            />
          )}

          {!isViewLimitedAsEffectRealisationResponsible && (
            <ProjectDimensionsEdit
              projectDimensions={dimensions ?? []}
              setProjectDimensions={setDimensions}
              setHasChanges={setHasChanges}
            />
          )}
        </CardRow>
      )}

      <CardRow>
        <CardTile>
          <div>
            <Label htmlFor="description">
              {t('Project.Description')}
              <ProjectPublishedPubliclyIcon project={project} />
            </Label>
          </div>
          <div>
            <TextArea
              style={{ height: 150 }}
              id="description"
              name="description"
              value={project.description ?? ''}
              onChange={(e) => {
                setHasChanges(true);
                setProject({
                  ...project,
                  description: e.currentTarget.value,
                });
              }}
              readOnly={isViewLimitedAsEffectRealisationResponsible}
            />
          </div>
        </CardTile>

        <CardTile>
          <div>
            <Label htmlFor="benefitsAndEffects">
              {t('Project.BenefitsAndEffects')}
              <ProjectPublishedPubliclyIcon project={project} />
            </Label>
          </div>

          <div>
            <TextArea
              style={{ height: 150 }}
              id="benefitsAndEffects"
              name="benefitsAndEffects"
              value={project.benefitsAndEffects ?? ''}
              onChange={(e) => {
                setHasChanges(true);
                setProject({
                  ...project,
                  benefitsAndEffects: e.currentTarget.value,
                });
              }}
              readOnly={isViewLimitedAsEffectRealisationResponsible}
            />
          </div>
        </CardTile>
      </CardRow>

      {isPublicApiEnabledFlagOn && isChangingReadyForExport && (
        <Modal
          title={
            !project.isReadyForExport
              ? t('Project.IsReadyForExport.ConfirmTitleEnable')
              : t('Project.IsReadyForExport.ConfirmTitleDisable')
          }
        >
          <div>
            {!project.isReadyForExport
              ? t('Project.IsReadyForExport.ConfirmTextEnable')
              : t('Project.IsReadyForExport.ConfirmTextDisable')}
          </div>

          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={(e) => setIsChangingReadyForExport(false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>
            <Button
              type="button"
              onClick={() => {
                setIsChangingReadyForExport(false);
                setHasChanges(true);
                setProject({
                  ...project,
                  isReadyForExport: !project.isReadyForExport,
                });
              }}
              disabled={isViewReadOnly}
            >
              {t('Common.UI.Yes')}
            </Button>
          </Buttons>
        </Modal>
      )}

      {isPublishPubliclyEnabledFlagOn && isChangingPublishedPublicly && (
        <ProjectPublishedPubliclyConfirmation
          project={project}
          setIsChangingPublishedPublicly={setIsChangingPublishedPublicly}
          handelChangePublishedPublicly={handelChangePublishedPublicly}
        />
      )}

      <CardRow>
        <CardTile>
          {isViewLimitedAsEffectRealisationResponsible && (
            <ProjectPeriodList project={project} />
          )}

          {!isViewLimitedAsEffectRealisationResponsible && (
            <>
              <Heading4 style={{ marginBottom: 10 }}>
                {t('Project.Period.Title')}
              </Heading4>
              <div className="field">
                <div>
                  <Label htmlFor="budget">
                    {t('Project.Budget', {
                      currency: t(
                        `Common.Currency.${accessToken.locale.currency}.Name`
                      ),
                    })}
                    <ProjectPublishedPubliclyIcon project={project} />
                  </Label>
                  <InputNumber
                    id="budget"
                    name="budget"
                    value={project.budget ?? ''}
                    onValueChange={({ value }) => {
                      setHasChanges(true);
                      setProject({
                        ...project,
                        budget: !isNaN(parseInt(value)) ? +value : undefined,
                      });
                    }}
                  />
                </div>

                <div>
                  <Label htmlFor="startYear">{t('Project.StartYear')}</Label>
                  <YearPicker
                    name="startYear"
                    date={project.startYear?.toString() ?? ''}
                    min={new Date(1987, 0, 0)}
                    max={new Date(2100, 0, 0)}
                    setDate={(date: Date | null) => {
                      setHasChanges(true);
                      setProject({
                        ...project,
                        startYear: date ? date.getFullYear() : undefined,
                      });
                    }}
                  />
                </div>

                {isStartAndEndMonthEnabledFlagOn && (
                  <div>
                    <Label htmlFor="startMonth">
                      {t('Project.StartMonth')}
                    </Label>
                    <MonthPicker
                      name="startMonth"
                      date={project.startMonth?.toString() ?? ''}
                      setDate={(date: Date | null) => {
                        setHasChanges(true);
                        setProject({
                          ...project,
                          startMonth: date ? date.getMonth() + 1 : undefined,
                        });
                      }}
                    />
                  </div>
                )}

                <div>
                  <Label htmlFor="endYear">{t('Project.EndYear')}</Label>
                  <YearPicker
                    name="endYear"
                    min={new Date(1987, 0, 0)}
                    max={new Date(2100, 0, 0)}
                    date={project.endYear?.toString() ?? ''}
                    setDate={(date: Date | null) => {
                      setHasChanges(true);
                      setProject({
                        ...project,
                        endYear: date ? date.getFullYear() : undefined,
                      });
                    }}
                  />
                </div>
                {isStartAndEndMonthEnabledFlagOn && (
                  <div>
                    <Label htmlFor="endMonth">{t('Project.EndMonth')}</Label>
                    <MonthPicker
                      name="endMonth"
                      date={project.endMonth?.toString() ?? ''}
                      setDate={(date: Date | null) => {
                        setHasChanges(true);
                        setProject({
                          ...project,
                          endMonth: date ? date.getMonth() + 1 : undefined,
                        });
                      }}
                    />
                  </div>
                )}

                <div style={{ alignSelf: 'flex-end', marginBottom: 5 }}>
                  <TooltipContent
                    id="tt-calculate"
                    title={t('Project.Period.CalculateTooltipTitle')}
                    text={t('Project.Period.CalculateTooltip')}
                  />

                  <Button
                    data-tip
                    data-for="tt-calculate"
                    onClick={() => {
                      setHasChanges(true);
                      calculatePeriods(true);
                    }}
                    className="size-xl"
                    style={{ padding: '7px 7px 5px 7px' }}
                  >
                    <BsCalculator />
                  </Button>
                </div>

                {isProjectEffectRealisationEnabledFlagOn && (
                  <div className="benefits">
                    <div>
                      <Heading5>
                        {t('ImplementedProjects.ExpectedBenefit', {
                          currency: t(
                            `Common.Currency.${accessToken.locale.currency}.Name`
                          ),
                        })}
                      </Heading5>
                      {formatAsCurrency(
                        goals?.reduce(
                          (total, goal) =>
                            total + (goal.totalExpectedBenefit ?? 0),
                          0
                        )
                      )}
                    </div>
                    <div>
                      <Heading5>
                        {t('ImplementedProjects.ActualBenefit', {
                          currency: t(
                            `Common.Currency.${accessToken.locale.currency}.Name`
                          ),
                        })}
                      </Heading5>
                      {formatAsCurrency(
                        goals?.reduce(
                          (total, goal) =>
                            total + (goal.totalActualBenefit ?? 0),
                          0
                        )
                      )}
                    </div>

                    {(project.projectPhase === Phase.Finished ||
                      project.projectPhase ===
                        Phase.FinishedEffectRealizationStarted ||
                      project.projectPhase ===
                        Phase.FinishedEffectRealizationConcluded) && (
                      <div>
                        <Heading5>
                          {t('ImplementedProjects.Deviation', {
                            currency: t(
                              `Common.Currency.${accessToken.locale.currency}.Name`
                            ),
                          })}
                        </Heading5>

                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                          }}
                        >
                          {formatAsCurrency(
                            goals?.reduce(
                              (total, goal) => total + (goal.deviation ?? 0),
                              0
                            )
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

              <ProjectPeriodEdit
                budget={project.budget}
                projectPeriods={periods ?? []}
                setProjectPeriods={setPeriods}
                setHasChanges={setHasChanges}
              />
            </>
          )}
        </CardTile>
      </CardRow>
    </Style>
  ) : null;
};

ProjectEdit.displayName = 'ProjectEdit';

export const TableStyle = styled(ProjectDataTable)`
  tr {
    height: 45px;
  }
`;

export const Style = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  label {
    font-weight: bold;
  }

  .field {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;

    > div {
      padding: 0 0 10px 0;

      button {
        margin-right: 20px;
      }

      &.benefits {
        display: flex;

        > div {
          padding: 0 20px 10px 0;
          text-align: right;
        }
      }
    }
  }
`;

const HelpText = styled.div`
  font-style: italic;
  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.s};
`;
