import { useGetPhaseText } from 'hooks/useGetPhaseText';
import { Phase } from 'models';

export const ProjectPhase = ({
  phase,
}: {
  phase?: Phase | null;
}): JSX.Element | null => {
  const getPhaseText = useGetPhaseText();

  return phase ? (
    <span>
      {getPhaseText({
        value: phase,
      })}
    </span>
  ) : null;
};

ProjectPhase.displayName = 'ProjectPhase';
