import { useTitle } from '@dimatech/shared/lib/hooks';
import { ProjectEdit as ProjectEditFeature } from 'features/project/ProjectEdit';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { View } from 'views';

export const ProjectEdit = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { setTitle } = useTitle();
  const location = useLocation();

  const { projectId = '' } = useParams();

  useEffect(() => {
    if (location.pathname.endsWith('new')) {
      setTitle(`${t('Project.Project')} - ${t('Project.Add.Title')}`);
    } else {
      setTitle(`${t('Project.Project')} - ${t('Project.Edit.Title')}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, projectId]);

  return (
    <View title={t('Project.Project')}>
      <ProjectEditFeature />
    </View>
  );
};

ProjectEdit.displayName = 'ProjectEdit';
