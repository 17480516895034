import {
  Button,
  Buttons,
  ButtonSecondary,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const IdeaReadyForReviewConfirmation = ({
  isReadyingForReview,
  handleSave,
}: {
  isReadyingForReview: boolean;
  handleSave: (isConfirmed: boolean) => void;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const isProjectIdeasReviewersEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectIdeasReviewersEnabled];

  const [translationPath, setTranslationPath] = useState<string>();

  useEffect(() => {
    if (isProjectIdeasReviewersEnabledFlagOn) {
      setTranslationPath('ReadyForReview');
    } else {
      setTranslationPath('Publish');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProjectIdeasReviewersEnabledFlagOn]);

  if (!isReadyingForReview) {
    return null;
  }

  return (
    <Modal
      title={t(`FutureProjects.ProjectIdea.${translationPath}.Title`)}
      handleKeyEnter={() => handleSave(true)}
      handleKeyEsc={() => handleSave(false)}
    >
      <div>
        {t(`FutureProjects.ProjectIdea.${translationPath}.ConfirmText`)}
      </div>

      <Buttons>
        <ButtonSecondary type="button" onClick={() => handleSave(false)}>
          {t('Common.Form.Cancel')}
        </ButtonSecondary>
        <Button type="button" onClick={() => handleSave(true)}>
          {t('Common.UI.Yes')}
        </Button>
      </Buttons>
    </Modal>
  );
};

IdeaReadyForReviewConfirmation.displayName = 'IdeaReadyForReviewConfirmation';
