import { SelectEntitiesWithoutSurvey } from '@dimatech/features-core/lib/components/SelectEntitiesWithoutSurvey';
import { SelectTags } from '@dimatech/features-core/lib/components/SelectTags';
import {
  Button,
  Input,
  InputSearch,
} from '@dimatech/shared/lib/components/form';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  HeaderFilter,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import {
  EventType,
  Paginator,
  SortDirection,
} from '@dimatech/shared/lib/models';
import { trackEvent } from '@dimatech/shared/lib/tracking';
import { searchPortfolios } from 'api/portfolio/portfolioSearchSlice';
import { SelectCustomDimensions } from 'components/SelectCustomDimensions';
import { SelectPeriod } from 'components/SelectPeriod';
import { SelectPeriodMonth } from 'components/SelectPeriodMonth';
import { useAppDispatch } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ProjectDimension } from 'models';
import { KeyboardEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { SearchPortfolioResult } from './components/SearchPortfolioResult';

export const Search = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const searchedForName = (location?.state as Record<string, string>)
    ?.name as string;
  const searchedForOwner = (location?.state as Record<string, string>)
    ?.owner as string;
  const searchedForEntityId = (location?.state as Record<string, string>)
    ?.id as string;

  const [name, setName] = useState<string>(searchedForName || '');
  const [period, setPeriod] = useState<number>();
  const [periodMonth, setPeriodMonth] = useState<number>();
  const [entityIds, setEntityIds] = useState<string[]>(
    [searchedForEntityId] || []
  );
  const [tagIds, setTagIds] = useState<string[]>();
  const [dimensions, setDimensions] = useState<ProjectDimension[]>([]);
  const [owner, setOwner] = useState<string>(searchedForOwner || '');
  const [projectManager, setProjectManager] = useState<string>('');
  const [effectRealisationResponsible, setEffectRealisationResponsible] =
    useState<string>('');

  const isTagsEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isTagsEnabled];
  const isProjectEffectRealisationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectEffectRealisationEnabled];
  const isStartAndEndMonthEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isStartAndEndMonthEnabled];

  const initialPaginator: Paginator = {
    page: 1,
    pageSize: 25,
    orderBy: 'projectName',
    orderDirection: SortDirection.Asc,
  };

  const handleSearch = (paginator: Paginator = initialPaginator) => {
    dispatch(
      searchPortfolios({
        name,
        period: !period || isNaN(period) ? undefined : period,
        periodMonth:
          !periodMonth || isNaN(periodMonth) ? undefined : periodMonth,
        owner,
        tagIds,
        entityIds,
        paginator,
        dimensions,
        projectManager,
        effectRealisationResponsible,
      })
    );

    trackEvent(EventType.Search);
  };

  const handleChangeEntity = (entityChildrenIds: string[]) => {
    setEntityIds(entityChildrenIds);
  };

  useEffect(() => {
    setOwner(searchedForOwner || '');
  }, [searchedForOwner]);

  const handleKeyDown = (
    e: KeyboardEvent<HTMLInputElement> | undefined
  ): void => {
    if (e?.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Search.Title')}</Heading1>
      </ViewHeader>

      <Card>
        <CardBody>
          <HeaderFilter>
            <div style={{ minWidth: 250 }}>
              <InputSearch
                id="name"
                name="name"
                value={name}
                placeholder={t('Search.Placeholder')}
                onChange={(e) => setName(e.currentTarget.value)}
                onKeyDown={handleKeyDown}
              />
            </div>

            <SelectPeriod
              period={period}
              setPeriod={setPeriod}
              isAnyAllowed={true}
              inverted={false}
            />

            {isStartAndEndMonthEnabledFlagOn && period && (
              <SelectPeriodMonth
                period={periodMonth}
                setPeriod={setPeriodMonth}
              />
            )}

            <SelectEntitiesWithoutSurvey
              entityIds={entityIds}
              setEntityIds={handleChangeEntity}
            />

            <div style={{ minWidth: 250 }}>
              <Input
                id="owner"
                name="owner"
                value={owner}
                placeholder={t('Search.PortfolioOwner')}
                onChange={(e) => setOwner(e.currentTarget.value)}
                onKeyDown={handleKeyDown}
              />
            </div>

            <div style={{ minWidth: 250 }}>
              <InputSearch
                id="projectManager"
                name="projectManager"
                value={projectManager}
                placeholder={t('Search.ProjectManager')}
                onChange={(e) => setProjectManager(e.currentTarget.value)}
                onKeyDown={handleKeyDown}
              />
            </div>

            {isProjectEffectRealisationEnabledFlagOn && (
              <div style={{ minWidth: 250 }}>
                <InputSearch
                  id="effectRealisationResponsible"
                  name="effectRealisationResponsible"
                  value={effectRealisationResponsible}
                  placeholder={t('Search.EffectRealisationResponsible')}
                  onChange={(e) =>
                    setEffectRealisationResponsible(e.currentTarget.value)
                  }
                  onKeyDown={handleKeyDown}
                />
              </div>
            )}

            {isTagsEnabledFlagOn && (
              <div>
                <SelectTags tagIds={tagIds} setTagIds={setTagIds} />
              </div>
            )}

            <SelectCustomDimensions
              isProject={true}
              handleChangeDimensions={(dimensions) => setDimensions(dimensions)}
            />

            <Button
              type="button"
              onClick={() => handleSearch()}
              style={{ marginLeft: 7 }}
            >
              {t('Search.Search')}
            </Button>
          </HeaderFilter>

          <SearchPortfolioResult
            handlePaginatorChange={handleSearch}
            initialPaginator={initialPaginator}
          />
        </CardBody>
      </Card>
    </>
  );
};

Search.displayName = 'Search';
