import {
  Heatmap,
  HeatmapConfig,
  HeatmapConfigTooltip,
} from '@dimatech/shared/lib/components/heatmap';
import { DataItem, DataPlot } from '@dimatech/shared/lib/models';
import { Theme } from '@dimatech/shared/lib/themes';
import { OverviewRangeDetails } from 'components/OverviewRangeDetails';
import i18n from 'localization';
import { Overview } from 'models';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';

export const BudgetAndBalanceHeatmap = withTheme(
  ({
    overviews,
    tooltip,
    cellSizePixels = window.innerWidth < 600 ? 25 : 40,
    name,
    theme,
  }: {
    overviews: Overview[];
    tooltip: HeatmapConfigTooltip;
    cellSizePixels?: number;
    name: string;
    theme: Theme;
  }): JSX.Element => {
    const { t } = useTranslation();

    const cellSize = 10;

    const [showDetailsFor, setShowDetailsFor] = useState<
      Overview & { similar: Overview[] }
    >();

    const formatter = (value: number) =>
      `${100 - value - cellSize}-${100 - value} % - ${value}-${
        value + cellSize
      } %`;

    const formatterZ = (value: number) =>
      `${value.toLocaleString(i18n.language)}`;

    const data = overviews.map(
      (overview) =>
        ({
          id: overview.id,
          y: overview.internalExternal,
          x: overview.efficiencyInnovation,
          z: overview.budget ?? null,
          label: overview.label,
          info: overview.entityName,
        } as DataPlot)
    );

    const config: HeatmapConfig = {
      gridSize: { x: 10, y: 10 },
      cellSize,
      cellSizePixels,
      calcX: (index: number) => index * 10,
      calcY: (index: number) => index * 10,
      colorFrom: theme.colors.surface,
      colorTo: theme.colors.primary,
      tooltip: {
        ...tooltip,
        formatterX: formatter,
        formatterY: formatter,
        formatterZ,
      },
    };

    const handleClick = (item: DataItem) => {
      const overview = overviews.find((overview) => overview.id === item.id);

      if (!overview) {
        return;
      }

      // Move to prop of OverviewDetails
      const similar = overviews.filter(
        (overview) =>
          item.items?.findIndex((inner) => inner.id === overview.id) !== -1
      );

      setShowDetailsFor({ ...overview, similar });
    };

    return (
      <>
        <Heatmap
          data={data}
          config={config}
          handleClick={handleClick}
          name={name}
        />

        {showDetailsFor && (
          <OverviewRangeDetails
            showDetailsFor={showDetailsFor}
            setShowDetailsFor={setShowDetailsFor}
            title={t('Projects')}
          />
        )}
      </>
    );
  }
);

BudgetAndBalanceHeatmap.displayName = 'BudgetAndBalanceHeatmap';
