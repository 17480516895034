import { AdministrateOrganisation as AdministrateOrganisationFeature } from 'features/administrate-organisation';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const AdministrateOrganisation = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View
      title={`${t('Navigation.Administrate.Administrate')} - ${t(
        'Navigation.Administrate.Organisation'
      )}`}
    >
      <AdministrateOrganisationFeature />
    </View>
  );
};

AdministrateOrganisation.displayName = 'AdministrateOrganisation';
