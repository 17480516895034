import { useTitle } from '@dimatech/shared/lib/hooks';
import { Portfolios as PortfoliosFeature } from 'features/portfolio/Portfolios';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { View } from 'views';

export const Portfolios = (): JSX.Element | null => {
  const { t } = useTranslation();

  const location = useLocation();
  const { setTitle } = useTitle();

  useEffect(() => {
    let title = t('Portfolios.KeyStatistics.Title');

    switch (true) {
      case location.pathname.endsWith('balance-over-time'):
        title = t('Portfolios.BudgetAndBalanceOverTime.Title');
        break;
      case location.pathname.endsWith('balance'):
        title = t('Portfolios.BudgetAndBalance.Title');
        break;
      case location.pathname.endsWith('tag'):
        title = t('Portfolios.TagTable.Title');
        break;
      case location.pathname.endsWith('table'):
        title = t('Portfolios.List.Title');
        break;
      case location.pathname.endsWith('timeline'):
        title = t('Projects.Timeline.Title');
        break;
    }

    setTitle(`${t('Portfolios.Title')} - ${title}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <View title={t('Portfolios.Title')}>
      <PortfoliosFeature />
    </View>
  );
};

Portfolios.displayName = 'Portfolios';
