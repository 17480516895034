import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { PortfolioMutation } from 'models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowRightSquare, BsClockHistory } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';

export const PortfolioListEditButtons = ({
  item,
}: {
  item: PortfolioMutation;
}): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    navigate(`/portfolio/${item.id}`);
  };

  const handleNavigateToHistory = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    navigate(`/portfolio/${item.id}/history`, {
      state: { from: location.pathname },
    });
  };

  return (
    <div style={{ display: 'flex', gap: 10 }}>
      <TooltipContent
        id="tt-portfolio-history"
        text={t('Portfolio.History.TooltipTitle')}
      />
      <BadgeMini
        data-tip
        data-for="tt-portfolio-history"
        onClick={(e) => handleNavigateToHistory(e)}
      >
        <BsClockHistory />
      </BadgeMini>

      <TooltipContent
        id="tt-portfolio-open"
        text={t('Administrate.OpenPortfolio')}
      />
      <BadgeMini
        data-tip
        data-for="tt-portfolio-open"
        onClick={(e) => handleNavigate(e)}
      >
        <BsArrowRightSquare />
      </BadgeMini>
    </div>
  );
};

PortfolioListEditButtons.displayName = 'PortfolioListEditButtons';
