import { Phase } from 'models';

export const projectOngoingPhases = [
  Phase.NotStartedDecided,
  Phase.Started,
  Phase.Paused,
  Phase.Finished,
  Phase.FinishedEffectRealizationStarted,
  Phase.FinishedEffectRealizationConcluded,
  Phase.NotSet,
];

export const projectIdeaPhases = [
  Phase.Draft,
  Phase.Ready,
  Phase.ReadyForReview,
];

export const isProjectOngoing = (phase?: Phase) => {
  if (!phase) {
    return false;
  }

  return projectOngoingPhases.includes(phase);
};
