import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { HeatmapConfigTooltip } from '@dimatech/shared/lib/components/heatmap';
import {
  Card,
  CardBody,
  CardFocused,
  CardFocusedBody,
} from '@dimatech/shared/lib/components/workspace';
import { getPercentRemainder } from '@dimatech/shared/lib/utils';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectFilter } from 'api/piosSlice';
import { useGetProjectsOverviewQuery } from 'api/project/projectOverviewApi';
import { BudgetAndBalanceHeatmap } from 'components/BudgetAndBalanceHeatmap';
import { BudgetAndBalanceScatterChart } from 'components/BudgetAndBalanceScatterChart';
import { useAppSelector } from 'hooks';
import { Overview } from 'models';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const ProjectsBudgetAndBalance = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const filter = useAppSelector(selectFilter);

  const [overviews, setOverviews] = useState<Overview[]>();

  const { data, isFetching } = useGetProjectsOverviewQuery(
    accessToken.customerId && accessToken.user?.id
      ? {
          filter,
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const tooltip: HeatmapConfigTooltip = {
    showDetails: true,
    title: t('Projects'),
    labelZ: t('Project.Budget', {
      currency: t(`Common.Currency.${accessToken.locale.currency}.Name`),
    }),
    labelX: t('Project.EfficiencyInnovation'),
    labelY: t('Project.InternalExternal'),
  };

  useEffect(() => {
    if (data) {
      const overviews = data.projects.map(
        (project): Overview => ({
          id: project.id,
          externalId: project.externalId,
          label: project.title as string,
          entityName: project.entityName,
          portfolioId: project.portfolioId,
          internalExternal: getPercentRemainder(project.internalExternal),
          efficiencyInnovation: getPercentRemainder(
            project.efficiencyInnovation
          ),
          budget: project.budget === 0 ? undefined : project.budget,
        })
      );

      setOverviews(overviews);
    }
  }, [data]);

  if (data && data.projects.length === 0) {
    return null;
  }

  return (
    <Card style={{ paddingTop: 10 }}>
      <CardBody isLoading={isFetching}>
        {overviews && (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-around',
            }}
          >
            <CardFocused
              helpTextTitle={t('Projects.BudgetAndBalance.Title')}
              helpText={
                <Trans
                  i18nKey={'Projects.BudgetAndBalance.ScatterChart.Help'}
                />
              }
              canBeSavedAsPng={true}
            >
              <CardFocusedBody style={{ paddingTop: 10 }}>
                <BudgetAndBalanceScatterChart
                  overviews={overviews}
                  tooltip={{ title: t('Projects.Title') }}
                  name={t('Projects.BudgetAndBalance.Title')}
                  style={{ width: 450, height: 450 }}
                />
              </CardFocusedBody>
            </CardFocused>

            <CardFocused
              helpTextTitle={t('Projects.BudgetAndBalance.Title')}
              helpText={
                <Trans i18nKey={'Projects.BudgetAndBalance.Heatmap.Help'} />
              }
              canBeSavedAsPng={true}
            >
              <CardFocusedBody style={{ paddingTop: 10 }}>
                <BudgetAndBalanceHeatmap
                  overviews={overviews}
                  tooltip={tooltip}
                  name={t('Projects.BudgetAndBalance.Title')}
                />
              </CardFocusedBody>
            </CardFocused>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

ProjectsBudgetAndBalance.displayName = 'ProjectsBudgetAndBalance';
