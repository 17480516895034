import {
  HistoryLogChange,
  HistoryLogChangeMetaData,
  HistoryLogChangeType,
} from '@dimatech/features-core/lib/models/history';
import { getMetaData } from '@dimatech/features-core/lib/utils/history';
import { formatAsNumber, nameOf } from '@dimatech/shared/lib/utils';
import {
  Currency,
  HistoryObjectType,
  Phase,
  Portfolio,
  Project,
  ProjectGoal,
  ProjectGoalBenefit,
  ProjectIdea,
  ProjectPeriod,
} from 'models';
import { TFunction } from 'react-i18next';

/* eslint-disable max-lines-per-function */

export const parseHistoryChange = (
  history: HistoryLogChange,
  locale: { currency: Currency },
  t?: TFunction<'translation', undefined>,
  getPhaseText?: ({ value }: { value: Phase }) => string
): HistoryLogChangeMetaData => {
  const metaData = getMetaData(history);
  const piosMetaData = getPiosMetaData(
    history,
    metaData,
    locale,
    t,
    getPhaseText
  );

  return piosMetaData;
};

const getPiosMetaData = (
  history: HistoryLogChange,
  metaData: HistoryLogChangeMetaData,
  locale: { currency: Currency },
  t?: TFunction<'translation', undefined>,
  getPhaseText?: ({ value }: { value: Phase }) => string
  // eslint-disable-next-line max-params
): HistoryLogChangeMetaData => {
  const piosMetaData: HistoryLogChangeMetaData = {
    type: history.type,
    valueName: metaData.valueName,
    newValue: history.newValue || metaData.newValue,
    oldValue: history.oldValue,
  };

  const normalizedPath = history.path.replace('.Value', '').toUpperCase();
  const normalizedName = metaData.name?.toUpperCase();

  switch (metaData.name) {
    case HistoryObjectType.Documents:
      piosMetaData.displayName = t?.(`Project.Documents`);
      piosMetaData.oldValue = history.displayName;
      piosMetaData.newValue = history.displayName;
      break;

    case HistoryObjectType.Projects:
      piosMetaData.displayName = t?.(`Project.Project`);
      piosMetaData.newValue = history.displayName;
      piosMetaData.oldValue = history.displayName;
      break;

    case HistoryObjectType.CustomDimensions:
      piosMetaData.displayName = t?.(`Administrate.CustomDimension.Title`);
      piosMetaData.additionalPropertyName = history.displayName;
      piosMetaData.type = HistoryLogChangeType.Update;

      piosMetaData.newValue = formatAsNumber(
        history.newValue,
        history.newValue
      );
      piosMetaData.oldValue = formatAsNumber(
        history.oldValue,
        history.oldValue
      );
      break;

    case HistoryObjectType.Goals:
      piosMetaData.displayName = t?.(`Project.Goals.Goals`);

      if (
        piosMetaData.type === HistoryLogChangeType.Create ||
        piosMetaData.type === HistoryLogChangeType.Delete
      ) {
        piosMetaData.oldValue = history.displayName;
        piosMetaData.newValue = history.displayName;
      } else {
        piosMetaData.additionalPropertyName = history.displayName;
        piosMetaData.oldValue = history.oldValue;
        piosMetaData.newValue = history.newValue;
      }

      break;

    case HistoryObjectType.Tags:
      piosMetaData.displayName = t?.(`Project.Tag.Tag`);
      break;

    case HistoryObjectType.NationalTags:
      piosMetaData.displayName = t?.(`Project.NationalTag.Tag`);
      break;

    case '':
      piosMetaData.displayName = t?.(`Portfolio.Portfolio`);
      piosMetaData.newValue = history.newValue;
      break;

    default:
      piosMetaData.displayName = t?.(`Project.${metaData.name}`);
      break;
  }

  if (
    normalizedPath.endsWith(
      nameOf<ProjectGoal>('internalExternalClassification').toUpperCase()
    )
  ) {
    piosMetaData.displayName = t?.(
      `Portfolio.Goal.InternalExternalClassification`
    );
    piosMetaData.oldValue = history.oldValue
      ? t?.(
          `Portfolio.Goal.Classification.InternalExternal.${history.oldValue}`
        )
      : '';
    piosMetaData.newValue = history.newValue
      ? t?.(
          `Portfolio.Goal.Classification.InternalExternal.${history.newValue}`
        )
      : '';
  }

  if (
    normalizedPath.endsWith(
      nameOf<ProjectGoal>('innovationEfficiencyClassification').toUpperCase()
    )
  ) {
    piosMetaData.displayName = t?.(
      `Portfolio.Goal.InnovationEfficiencyClassification`
    );
    piosMetaData.oldValue = history.oldValue
      ? t?.(
          `Portfolio.Goal.Classification.InnovationEfficiency.${history.oldValue}`
        )
      : '';
    piosMetaData.newValue = history.newValue
      ? t?.(
          `Portfolio.Goal.Classification.InnovationEfficiency.${history.newValue}`
        )
      : '';
  }

  if (
    normalizedPath.endsWith(nameOf<ProjectGoal>('takeHomePeriod').toUpperCase())
  ) {
    piosMetaData.additionalPropertyName = `${history.displayName},
    ${t?.(`FutureProjects.ProjectIdea.TakeHomePeriodYear`)} 
   `;
  }

  if (normalizedName === nameOf<Project>('goals').toUpperCase()) {
    piosMetaData.displayName = t?.(`Project.Goals.Goals`);

    const benefitType = (str: string) => {
      const match = str.match(/Type=([^=\]]+)/);
      return match ? match[1] : null;
    };

    const type = benefitType(metaData.newValue?.toString() ?? '');

    if (
      normalizedPath.endsWith(
        nameOf<ProjectGoalBenefit>('expected').toUpperCase()
      )
    ) {
      piosMetaData.additionalPropertyName = `${history.displayName},
       ${t?.(`ImplementedProjects.Benefits.${type}`)},
        ${t?.(`ImplementedProjects.ExpectedBenefit`, {
          currency: t(`Common.Currency.${locale.currency}.Name`),
        })}`;

      piosMetaData.oldValue = formatAsNumber(history.oldValue, '');
      piosMetaData.newValue = formatAsNumber(history.newValue, '');
    }

    if (
      normalizedPath.endsWith(
        nameOf<ProjectGoalBenefit>('expectedDescription').toUpperCase()
      )
    ) {
      piosMetaData.additionalPropertyName = `${history.displayName}, 
      ${t?.(`ImplementedProjects.Benefits.${type}`)}, 
      ${t?.(`ImplementedProjects.History.ExpectedBenefitDescription`)}`;
    }

    if (
      normalizedPath.endsWith(
        nameOf<ProjectGoalBenefit>('actual').toUpperCase()
      )
    ) {
      piosMetaData.additionalPropertyName = `${history.displayName},
       ${t?.(`ImplementedProjects.Benefits.${type}`)},
        ${t?.(`ImplementedProjects.ActualBenefit`, {
          currency: t(`Common.Currency.${locale.currency}.Name`),
        })} 
      `;

      piosMetaData.oldValue = formatAsNumber(history.oldValue, '');
      piosMetaData.newValue = formatAsNumber(history.newValue, '');
    }

    if (
      normalizedPath.endsWith(
        nameOf<ProjectGoalBenefit>('actualDescription').toUpperCase()
      )
    ) {
      piosMetaData.additionalPropertyName = `${history.displayName}, 
      ${t?.(`ImplementedProjects.Benefits.${type}`)}, 
      ${t?.(`ImplementedProjects.History.ActualBenefitDescription`)}`;
    }

    return piosMetaData;
  }

  if (normalizedName === nameOf<Project>('periods').toUpperCase()) {
    piosMetaData.displayName = t?.(`Project.Period.Title`);

    if (
      normalizedPath.endsWith(
        nameOf<ProjectPeriod>('staffingBudget').toUpperCase()
      )
    ) {
      piosMetaData.additionalPropertyName = `${metaData.newValue}, ${t?.(
        `Project.Period.StaffingBudget`,
        {
          currency: t(`Common.Currency.${locale.currency}.Name`),
        }
      )}`;
      piosMetaData.oldValue = formatAsNumber(history.oldValue, '');
      piosMetaData.newValue = formatAsNumber(history.newValue, '');
    }

    if (
      normalizedPath.endsWith(
        nameOf<ProjectPeriod>('totalBudget').toUpperCase()
      )
    ) {
      piosMetaData.additionalPropertyName = `${metaData.newValue}, ${t?.(
        `Project.Period.YearlyBudget`,
        {
          currency: t(`Common.Currency.${locale.currency}.Name`),
        }
      )}`;
      piosMetaData.oldValue = formatAsNumber(history.oldValue, '');
      piosMetaData.newValue = formatAsNumber(history.newValue, '');
    }

    return piosMetaData;
  }

  if (normalizedName === nameOf<Project>('projectPhase').toUpperCase()) {
    piosMetaData.displayName = t?.(`Project.Phase.Phase`);

    piosMetaData.oldValue = !history.oldValue
      ? t?.(`Common.UI.NotSet`)
      : getPhaseText?.({ value: history.oldValue as Phase });

    piosMetaData.newValue = !history.newValue
      ? t?.(`Common.UI.NotSet`)
      : getPhaseText?.({
          value: history.newValue as Phase,
        });

    return piosMetaData;
  }

  if (normalizedName === 'ideaStatus'.toUpperCase()) {
    piosMetaData.displayName = t?.(
      `FutureProjects.ProjectIdea.ProjectIdeaStatus`
    );

    piosMetaData.oldValue = !history.oldValue
      ? t?.(`Common.UI.NotSet`)
      : getPhaseText?.({
          value: history.oldValue as Phase,
        });
    piosMetaData.newValue = !history.newValue
      ? t?.(`Common.UI.NotSet`)
      : getPhaseText?.({
          value: history.newValue as Phase,
        });

    return piosMetaData;
  }

  if (normalizedPath.endsWith('IsArchived'.toUpperCase())) {
    piosMetaData.displayName = t?.(
      `FutureProjects.ProjectIdea.IsArchived.Title`
    );

    piosMetaData.oldValue = t?.(
      `FutureProjects.ProjectIdea.IsArchived.${history.oldValue}`
    );
    piosMetaData.newValue = t?.(
      `FutureProjects.ProjectIdea.IsArchived.${history.newValue}`
    );
  }

  if (normalizedName === nameOf<Project>('isReadyForExport').toUpperCase()) {
    piosMetaData.displayName = t?.(`Project.IsReadyForExport.Header`);
    piosMetaData.oldValue = t?.(`History.Common.${history.oldValue}`);
    piosMetaData.newValue = t?.(`History.Common.${history.newValue}`);
    return piosMetaData;
  }

  if (normalizedName === nameOf<Project>('isPublishedPublicly').toUpperCase()) {
    piosMetaData.displayName = t?.(`Project.IsPublishedPublicly.Header`);
    piosMetaData.oldValue = t?.(`History.Common.${history.oldValue}`);
    piosMetaData.newValue = t?.(`History.Common.${history.newValue}`);
    return piosMetaData;
  }

  if (
    normalizedName === nameOf<Project>('hasEffectTakeHomePlan').toUpperCase()
  ) {
    piosMetaData.displayName = t?.(`Project.EffectTakeHome.Title`);
    piosMetaData.oldValue = !history.oldValue
      ? t?.(`Common.UI.NotSet`)
      : t?.(`History.Common.${history.oldValue}`);
    piosMetaData.newValue = t?.(`History.Common.${history.newValue}`);

    return piosMetaData;
  }

  if (normalizedName === nameOf<Project>('projectStatus').toUpperCase()) {
    piosMetaData.displayName = t?.(`Project.Status.Status`);

    piosMetaData.oldValue = !history.oldValue
      ? t?.(`Project.Status.Zero`)
      : t?.(`Project.Status.${history.oldValue}`);
    piosMetaData.newValue = !history.newValue
      ? t?.(`Project.Status.Zero`)
      : t?.(`Project.Status.${history.newValue}`);

    return piosMetaData;
  }

  if (normalizedName === 'type'.toUpperCase()) {
    piosMetaData.displayName = t?.(`Project.ProjectType.ProjectType`);

    piosMetaData.oldValue = !history.oldValue
      ? t?.(`Project.ProjectType.Project`)
      : t?.(`Project.ProjectType.${history.oldValue}`);
    piosMetaData.newValue = !history.newValue
      ? t?.(`Project.ProjectType.Project`)
      : t?.(`Project.ProjectType.${history.newValue}`);

    return piosMetaData;
  }

  if (normalizedName === nameOf<Project>('budget').toUpperCase()) {
    piosMetaData.displayName = t?.(`Project.Budget`, {
      currency: t(`Common.Currency.${locale.currency}.Name`),
    });

    piosMetaData.oldValue = formatAsNumber(history.oldValue, '');
    piosMetaData.newValue = formatAsNumber(history.newValue, '');

    return piosMetaData;
  }

  if (normalizedName === nameOf<Project>('projectManagers').toUpperCase()) {
    piosMetaData.displayName = t?.(`UserRoles.PiosProjectManager.Name`);

    return piosMetaData;
  }

  if (
    normalizedName ===
    nameOf<Project>('effectRealizationResponsible').toUpperCase()
  ) {
    piosMetaData.displayName = t?.(
      `UserRoles.PiosEffectRealizationResponsible.Name`
    );

    return piosMetaData;
  }

  if (normalizedName === nameOf<Project>('orderingParties').toUpperCase()) {
    piosMetaData.displayName = t?.(`UserRoles.PiosOrderingParty.Name`);

    return piosMetaData;
  }

  if (normalizedName === nameOf<Portfolio>('owners').toUpperCase()) {
    piosMetaData.displayName = t?.(`UserRoles.PortfolioOwner.Name`);

    return piosMetaData;
  }

  if (normalizedName === nameOf<Portfolio>('calculationId').toUpperCase()) {
    piosMetaData.displayName = t?.(`Portfolio.Calculation`);

    return piosMetaData;
  }

  if (normalizedName === nameOf<ProjectIdea>('takeHomePeriod').toUpperCase()) {
    piosMetaData.displayName = t?.(
      `FutureProjects.ProjectIdea.TakeHomePeriodYear`
    );
    piosMetaData.oldValue = formatAsNumber(history.oldValue, '');
    piosMetaData.newValue = formatAsNumber(history.newValue, '');

    return piosMetaData;
  }

  if (normalizedName === nameOf<ProjectIdea>('benefit').toUpperCase()) {
    piosMetaData.displayName = t?.(`FutureProjects.ProjectIdea.Benefit`, {
      currency: t(`Common.Currency.${locale.currency}.Name`),
    });
    piosMetaData.oldValue = formatAsNumber(history.oldValue, '');
    piosMetaData.newValue = formatAsNumber(history.newValue, '');

    return piosMetaData;
  }

  if (normalizedName === nameOf<ProjectIdea>('benefitRisk').toUpperCase()) {
    piosMetaData.displayName = t?.(
      `FutureProjects.ProjectIdea.RiskPercentageBenefit`
    );
    piosMetaData.oldValue = formatAsNumber(history.oldValue, '');
    piosMetaData.newValue = formatAsNumber(history.newValue, '');

    return piosMetaData;
  }

  if (normalizedName === nameOf<ProjectIdea>('cost').toUpperCase()) {
    piosMetaData.displayName = t?.(`FutureProjects.ProjectIdea.Cost`, {
      currency: t(`Common.Currency.${locale.currency}.Name`),
    });
    piosMetaData.oldValue = formatAsNumber(history.oldValue, '');
    piosMetaData.newValue = formatAsNumber(history.newValue, '');

    return piosMetaData;
  }

  if (normalizedName === nameOf<ProjectIdea>('costRisk').toUpperCase()) {
    piosMetaData.displayName = t?.(
      `FutureProjects.ProjectIdea.RiskPercentageCost`
    );
    piosMetaData.oldValue = formatAsNumber(history.oldValue, '');
    piosMetaData.newValue = formatAsNumber(history.newValue, '');

    return piosMetaData;
  }

  return piosMetaData;
};
