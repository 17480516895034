import { useTitle } from '@dimatech/shared/lib/hooks';
import { AdministrateFeatureFlag as AdministrateFeatureFlagFeature } from 'features/administrate-feature-flag';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { View } from 'views';

export const AdministrateFeatureFlag = (): JSX.Element | null => {
  const { t } = useTranslation();

  const location = useLocation();
  const { setTitle } = useTitle();

  useEffect(() => {
    let title = t('Administrate.FeatureFlag.Features.Tags.Title');

    switch (true) {
      case location.pathname.endsWith('goal-ai-evaluation'):
        title = t('Administrate.FeatureFlag.Features.GoalAiEvaluation.Title');
        break;
      case location.pathname.endsWith('project-ideas-reviewers'):
        title = t(
          'Administrate.FeatureFlag.Features.ProjectIdeasReviewers.Title'
        );
        break;
      case location.pathname.endsWith('project-period-month'):
        title = t('Administrate.FeatureFlag.Features.ProjectPeriodMonth.Title');
        break;
      case location.pathname.endsWith('publish-functionality'):
        title = t(
          'Administrate.FeatureFlag.Features.PublishFunctionality.Title'
        );
        break;
      case location.pathname.endsWith('test-flag-1'):
        title = t('Administrate.FeatureFlag.Features.TestFlag1.Title');
        break;
      case location.pathname.endsWith('test-flag-2'):
        title = t('Administrate.FeatureFlag.Features.TestFlag2.Title');
        break;
      case location.pathname.endsWith('test-flag-3'):
        title = t('Administrate.FeatureFlag.Features.TestFlag3.Title');
        break;
      case location.pathname.endsWith('tags'):
        title = t('Administrate.FeatureFlag.Features.Tags.Title');
        break;
    }

    setTitle(
      `${t('Navigation.Administrate.Administrate')} - ${t(
        'Administrate.FeatureFlag.Title'
      )} - ${title}`
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <View
      title={`${t('Navigation.Administrate.Administrate')} - ${t(
        'Administrate.FeatureFlag.Title'
      )}`}
    >
      <AdministrateFeatureFlagFeature />
    </View>
  );
};

AdministrateFeatureFlag.displayName = 'AdministrateFeatureFlag';
