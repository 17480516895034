var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BadgeLarge, BadgeSmall } from '@dimatech/shared/lib/components/Badge';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody, CardRow, CardTile, ViewHeader, ViewHeaderActions, ViewRowBreak, } from '@dimatech/shared/lib/components/workspace';
import { LocalizationContext } from '@dimatech/shared/lib/localization';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowLeft, BsX } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useGetOrganisationHistoryQuery } from '../../api/account/accountApi';
import { HistoryLogChangeType, } from '../../models/history';
import { parseOrganisationHistoryChange } from '../../utils';
import { AuthenticationContext } from '../authentication';
import { HistoryChangeLog } from './HistoryChangeLog';
export var OrganisationHistory = function () {
    var _a;
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var locale = useContext(LocalizationContext).locale;
    var navigate = useNavigate();
    var _b = useState(), history = _b[0], setHistory = _b[1];
    var _c = useState(), entityId = _c[0], setEntityId = _c[1];
    var _d = useGetOrganisationHistoryQuery(accessToken.customerId
        ? {
            entityId: entityId,
            _customerId: accessToken.customerId,
        }
        : skipToken), data = _d.data, isLoading = _d.isLoading, isFetching = _d.isFetching;
    var handleCancel = function (e) {
        e.stopPropagation();
        navigate("/administrate/organisation");
    };
    useEffect(function () {
        if (data) {
            var parsed = data.map(function (historyUpdate) { return (__assign(__assign({}, historyUpdate), { changes: historyUpdate.changes.map(function (change) { return (__assign(__assign({}, change), { type: change.path === 'ParentId'
                        ? HistoryLogChangeType.Move
                        : change.type, metaData: parseOrganisationHistoryChange(__assign(__assign({}, change), { metaData: historyUpdate }), t) })); }) })); });
            setHistory(parsed);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, locale]);
    return (_jsxs(_Fragment, { children: [_jsxs(ViewHeader, { children: [_jsxs("div", { children: [_jsx(TooltipContent, { id: "tt-navigate-back", text: t('Common.UI.Back') }), _jsx(BadgeSmall, __assign({ "data-tip": true, "data-for": "tt-navigate-back", onClick: function (e) { return handleCancel(e); }, "$inverted": true, style: { marginRight: 15 } }, { children: _jsx(BsArrowLeft, {}) }))] }), _jsx("div", __assign({ style: {
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        } }, { children: _jsx(Heading1, { children: t('Administrate.History.HistoryForOrganisation') }) })), _jsx(ViewHeaderActions, { children: _jsxs("div", __assign({ style: {
                                display: 'flex',
                                gap: 10,
                            } }, { children: [_jsx(TooltipContent, { id: "tt-cancel", text: t('Common.Form.Cancel') }), _jsx(BadgeLarge, __assign({ "data-tip": true, "data-for": "tt-cancel", onClick: function (e) { return handleCancel(e); }, "$inverted": true }, { children: _jsx(BsX, {}) }))] })) }), _jsx(ViewRowBreak, { style: { margin: 0 } })] }), _jsx(Card, { children: _jsx(CardBody, __assign({ isLoading: isLoading || isFetching }, { children: _jsx(Style, { children: _jsx(CardRow, { children: _jsxs(CardTile, { children: [history && history.length === 0 && !entityId && (_jsx(_Fragment, { children: t("History.NoHistory") })), ((history && history.length > 0) ||
                                        (history && history.length === 0 && !!entityId)) && (_jsx(HistoryChangeLog, { history: history, currentUserEmail: ((_a = accessToken.user) === null || _a === void 0 ? void 0 : _a.email) || '', entityId: entityId, setEntityId: setEntityId, isEntityFilterShown: true }))] }) }) }) })) })] }));
};
OrganisationHistory.displayName = 'OrganisationHistory';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  padding: 10px;\n  gap: 15px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  padding: 10px;\n  gap: 15px;\n"])));
var templateObject_1;
