import { FeatureTestFlag1 } from '@dimatech/features-core/lib/features/administrate-feature-flag/components/FeatureTestFlag1';
import { FeatureTestFlag2 } from '@dimatech/features-core/lib/features/administrate-feature-flag/components/FeatureTestFlag2';
import { FeatureTestFlag3 } from '@dimatech/features-core/lib/features/administrate-feature-flag/components/FeatureTestFlag3';
import { Tab, Tabs } from '@dimatech/shared/lib/components/tab';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FeatureGoalAiEvaluation } from './components/FeatureGoalAiEvaluation';
import { FeatureProjectIdeasReviewers } from './components/FeatureProjectIdeasReviewers';

import { useLocation, useNavigate } from 'react-router-dom';
import { FeatureProjectPeriodMonth } from './components/FeatureProjectPeriodMonth';
import { FeaturePublishFunctionality } from './components/FeaturePublishFunctionality';
import { FeatureTags } from './components/FeatureTags';

enum TabIndexes {
  Tags = 1,
  GoalAiEvaluation = 2,
  ProjectIdeasReviewers = 3,
  ProjectPeriodMonth = 4,
  PublishFunctionality = 5,
  TestFlag1 = 100,
  TestFlag2 = 101,
  TestFlag3 = 102,
}

export const AdministrateFeatureFlag = (): JSX.Element | null => {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  const isShowDevDebugInfoFlagOn =
    useFlags()[flags.permanent.shared.showDevDebugInfo];

  const [selectedTabIndex, setSelectedTabIndex] = useState(
    location.pathname.endsWith('goal-ai-evaluation')
      ? TabIndexes.GoalAiEvaluation
      : location.pathname.endsWith('project-ideas-reviewers')
      ? TabIndexes.ProjectIdeasReviewers
      : location.pathname.endsWith('project-period-month')
      ? TabIndexes.ProjectPeriodMonth
      : location.pathname.endsWith('publish-functionality')
      ? TabIndexes.PublishFunctionality
      : location.pathname.endsWith('test-flag-1')
      ? TabIndexes.TestFlag1
      : location.pathname.endsWith('test-flag-2')
      ? TabIndexes.TestFlag2
      : location.pathname.endsWith('test-flag-3')
      ? TabIndexes.TestFlag3
      : TabIndexes.Tags
  );

  const handleSelectTab = (index: number) => {
    setSelectedTabIndex(index);

    navigate(
      `/administrate/features/${
        index === TabIndexes.GoalAiEvaluation
          ? 'goal-ai-evaluation'
          : index === TabIndexes.ProjectIdeasReviewers
          ? 'project-ideas-reviewers'
          : index === TabIndexes.ProjectPeriodMonth
          ? 'project-period-month'
          : index === TabIndexes.PublishFunctionality
          ? 'publish-functionality'
          : index === TabIndexes.TestFlag1
          ? 'test-flag-1'
          : index === TabIndexes.TestFlag2
          ? 'test-flag-2'
          : index === TabIndexes.TestFlag3
          ? 'test-flag-3'
          : 'tags'
      }`
    );
  };

  return (
    <div style={{ width: '100%' }}>
      <ViewHeader>
        <Heading1>{t('Administrate.FeatureFlag.Title')}</Heading1>
      </ViewHeader>

      <Card>
        <CardBody>{t('Administrate.FeatureFlag.Text')}</CardBody>
      </Card>

      <Tabs>
        <Tab
          isSelected={selectedTabIndex === TabIndexes.Tags}
          handleSelect={() => handleSelectTab(TabIndexes.Tags)}
        >
          {t('Administrate.FeatureFlag.Features.Tags.Title')}
        </Tab>

        <Tab
          isSelected={selectedTabIndex === TabIndexes.GoalAiEvaluation}
          handleSelect={() => handleSelectTab(TabIndexes.GoalAiEvaluation)}
        >
          {t('Administrate.FeatureFlag.Features.GoalAiEvaluation.Title')}
        </Tab>

        <Tab
          isSelected={selectedTabIndex === TabIndexes.ProjectIdeasReviewers}
          handleSelect={() => handleSelectTab(TabIndexes.ProjectIdeasReviewers)}
        >
          {t('Administrate.FeatureFlag.Features.ProjectIdeasReviewers.Title')}
        </Tab>
        <Tab
          isSelected={selectedTabIndex === TabIndexes.ProjectPeriodMonth}
          handleSelect={() => handleSelectTab(TabIndexes.ProjectPeriodMonth)}
        >
          {t('Administrate.FeatureFlag.Features.ProjectPeriodMonth.Title')}
        </Tab>
        <Tab
          isSelected={selectedTabIndex === TabIndexes.PublishFunctionality}
          handleSelect={() => handleSelectTab(TabIndexes.PublishFunctionality)}
        >
          {t('Administrate.FeatureFlag.Features.PublishFunctionality.Title')}
        </Tab>

        {isShowDevDebugInfoFlagOn && (
          <>
            <Tab
              isSelected={selectedTabIndex === TabIndexes.TestFlag1}
              handleSelect={() => handleSelectTab(TabIndexes.TestFlag1)}
            >
              {t('Administrate.FeatureFlag.Features.TestFlag1.Title')}
            </Tab>

            <Tab
              isSelected={selectedTabIndex === TabIndexes.TestFlag2}
              handleSelect={() => handleSelectTab(TabIndexes.TestFlag2)}
            >
              {t('Administrate.FeatureFlag.Features.TestFlag2.Title')}
            </Tab>

            <Tab
              isSelected={selectedTabIndex === TabIndexes.TestFlag3}
              handleSelect={() => handleSelectTab(TabIndexes.TestFlag3)}
            >
              {t('Administrate.FeatureFlag.Features.TestFlag3.Title')}
            </Tab>
          </>
        )}
      </Tabs>

      {selectedTabIndex === TabIndexes.Tags && <FeatureTags />}
      {selectedTabIndex === TabIndexes.GoalAiEvaluation && (
        <FeatureGoalAiEvaluation />
      )}
      {selectedTabIndex === TabIndexes.ProjectIdeasReviewers && (
        <FeatureProjectIdeasReviewers />
      )}
      {selectedTabIndex === TabIndexes.ProjectPeriodMonth && (
        <FeatureProjectPeriodMonth />
      )}
      {selectedTabIndex === TabIndexes.PublishFunctionality && (
        <FeaturePublishFunctionality />
      )}

      {isShowDevDebugInfoFlagOn && (
        <>
          {selectedTabIndex === TabIndexes.TestFlag1 && <FeatureTestFlag1 />}
          {selectedTabIndex === TabIndexes.TestFlag2 && <FeatureTestFlag2 />}
          {selectedTabIndex === TabIndexes.TestFlag3 && <FeatureTestFlag3 />}
        </>
      )}
    </div>
  );
};

AdministrateFeatureFlag.displayName = 'AdministrateFeatureFlag';
