import { trackEvent } from '@dimatech/shared/lib/tracking';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { config } from 'config';
import { saveAs } from 'file-saver';
import { EventType, SearchAdministrateUsers } from 'models';
import { RootState } from 'store';

const initialState: {
  isPosting: boolean;
  error?: string | null;
} = {
  isPosting: false,
  error: null,
};

export const getOwnersExport = createAsyncThunk(
  'portfolios/owners/export',
  async ({
    filter,
    name,
  }: {
    filter: SearchAdministrateUsers;
    name: string;
  }) => {
    const url = `portfolios/owners/export?1=1`;
    const filters = `${filter.entityIds
      ?.map((entity) => `&entityIds=${entity}`)
      .join('')}${filter.email ? `&email=${filter.email}` : ''}`;
    return axios({
      url: `${config.apiUrl.product.pios}${url}${filters}`,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const fileName = `${name}.xlsx`;

      const file = new File([response.data], fileName);

      saveAs(file, fileName);

      trackEvent(EventType.UsersExport, {
        name,
      });
    });
  }
);

export const { reducer: ownerExportReducer, actions: ownerExportActions } =
  createSlice({
    name: 'portfolios/owners/export',
    initialState,
    reducers: {},
    extraReducers: (build) => {
      build
        .addCase(getOwnersExport.fulfilled, (state) => {
          state.isPosting = false;
        })
        .addCase(getOwnersExport.pending, (state) => {
          state.isPosting = true;
          state.error = null;
        })
        .addCase(getOwnersExport.rejected, (state, action) => {
          state.isPosting = false;
          state.error = action.error.name;
        });
    },
  });

export const selectOwnerExportIsPosting = (state: RootState): boolean =>
  state.ownerExport.isPosting;
