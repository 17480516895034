import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Phase } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export const useGetPhaseText = () => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const isProjectEffectRealisationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectEffectRealisationEnabled];

  const isProjectIdeasReviewersEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectIdeasReviewersEnabled];

  const getPhaseText = ({ value }: { value: Phase }) => {
    if (value === Phase.Finished) {
      return isProjectEffectRealisationEnabledFlagOn
        ? t?.(`Project.Phase.FinishedEffectRealization`)
        : t?.(`Project.Phase.${value}`);
    } else if (value === Phase.ReadyForReview) {
      return accessToken.isBaseLicense
        ? t(`Project.Phase.Published`)
        : !isProjectIdeasReviewersEnabledFlagOn
        ? t(`Project.Phase.Published`)
        : t(`Project.Phase.${value}`);
    } else {
      return t(`Project.Phase.${value}`);
    }
  };

  return getPhaseText;
};
