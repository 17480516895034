import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { ApplicationContext } from '@dimatech/shared/lib/application';
import { Heading1, Heading4 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { TransArray } from '@dimatech/shared/lib/localization';
import { Theme } from '@dimatech/shared/lib/themes';
import { config } from 'config';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CommonRoles } from 'models';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BsExclamationCircleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { View } from 'views';

export const Help = (): JSX.Element => {
  const { t } = useTranslation();
  const application = useContext(ApplicationContext);
  const { accessToken } = useContext(AuthenticationContext);

  const isPublicApiEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isPublicApiEnabled];
  const isProjectIdeasReviewersEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectIdeasReviewersEnabled];
  const isViewersEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isViewersEnabled];
  const isProjectEffectRealisationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectEffectRealisationEnabled];

  const isGlobalAdmin = accessToken.isInRole(CommonRoles.GlobalAdmin);
  const isCustomerAccountAdmin =
    accessToken.customerId &&
    (isGlobalAdmin || accessToken.isInRole(CommonRoles.CustomerAccountAdmin));

  return (
    <View title={t('Help.Title')}>
      <ViewHeader>
        <Heading1>{t('Help.Title')}</Heading1>

        {config.version && (
          <Heading1>
            {' '}
            - {application.title} v{config.version}
          </Heading1>
        )}
      </ViewHeader>

      <Card width="100%" title={t('Help.Support')}>
        <CardBody>
          {t('Help.Text')}{' '}
          <a href={`mailto: ${config.support.email}`}>{config.support.email}</a>
        </CardBody>
      </Card>

      <Card width="100%" title={t('Help.Messaging.Title')}>
        <CardBody>
          <BsExclamationCircleFill
            className="size-xl"
            style={{ marginRight: 8 }}
          />
          {t('Help.Messaging.Text')}
        </CardBody>
      </Card>

      <Card width="100%" title={t('Help.Calculations.Title')}>
        <CardBody>
          <Style>
            <div>
              <Heading4>
                {t('Help.Calculations.Classifications.Title')}
              </Heading4>
              <Trans i18nKey="Help.Calculations.Classifications.Text" />
            </div>

            <div>
              <Heading4>{t('Help.Calculations.Budget.Title')}</Heading4>
              <Trans i18nKey="Help.Calculations.Budget.Text" />
            </div>
          </Style>
        </CardBody>
      </Card>

      <Card width="100%" title={t('Help.Roles.Title')}>
        <CardBody>
          <PermissionsStyle>
            <div>
              <Heading4>{t('UserRoles.CustomerAccountAdmin.Title')}</Heading4>
              <Trans i18nKey="UserRoles.CustomerAccountAdmin.Text" />
              <TransArray i18nKey="UserRoles.CustomerAccountAdmin.Help" />
            </div>

            <div>
              <Heading4>{t('UserRoles.CustomerAdmin.Title')}</Heading4>
              <Trans i18nKey="UserRoles.CustomerAdmin.Text" />
              <TransArray i18nKey="UserRoles.CustomerAdmin.Help" />
            </div>

            <div>
              <Heading4>{t('UserRoles.PortfolioOwner.Title')}</Heading4>
              <Trans i18nKey="UserRoles.PortfolioOwner.Text" />
              <TransArray i18nKey="UserRoles.PortfolioOwner.Help" />
            </div>

            {isViewersEnabledFlagOn && (
              <div>
                <Heading4>{t('UserRoles.Reader.Title')}</Heading4>
                <Trans i18nKey="UserRoles.Reader.Text" />
                <TransArray i18nKey="UserRoles.Reader.Help" />
              </div>
            )}

            {isProjectIdeasReviewersEnabledFlagOn && (
              <div>
                <Heading4>{t('UserRoles.PiosReviewer.Title')}</Heading4>
                <Trans i18nKey="UserRoles.PiosReviewer.Text" />
                <TransArray i18nKey="UserRoles.PiosReviewer.Help" />
              </div>
            )}

            <div>
              <Heading4>{t('UserRoles.PiosProjectManager.Title')}</Heading4>
              <Trans i18nKey="UserRoles.PiosProjectManager.Text" />
              <TransArray i18nKey="UserRoles.PiosProjectManager.Help" />
            </div>

            {isProjectEffectRealisationEnabledFlagOn && (
              <div>
                <Heading4>
                  {t('UserRoles.PiosEffectRealizationResponsible.Title')}
                </Heading4>
                <Trans i18nKey="UserRoles.PiosEffectRealizationResponsible.Text" />
                <TransArray i18nKey="UserRoles.PiosEffectRealizationResponsible.Help" />
              </div>
            )}
          </PermissionsStyle>
        </CardBody>
      </Card>

      {isPublicApiEnabledFlagOn && isCustomerAccountAdmin && (
        <Card width="100%" title={t('Help.PublicApi.Title')}>
          <CardBody>
            <Style>
              <div>
                <Heading4>{t('Help.PublicApi.ApiKey.Title')}</Heading4>
                <Trans i18nKey="Help.PublicApi.ApiKey.Text" />{' '}
                <Link to="/administrate/account">
                  {t('Common.UI.ClickHere')}
                </Link>
              </div>

              <div>
                <Heading4>{t('Help.PublicApi.Request.Title')}</Heading4>
                <Trans
                  i18nKey="Help.PublicApi.Request.Text"
                  values={{
                    url: `${config.apiUrl.product.current}public/projects{parametrar}`,
                  }}
                />
              </div>

              <div>
                <Heading4>{t('Help.PublicApi.Example.Title')}</Heading4>
                <Trans i18nKey="Help.PublicApi.Example.Text" />
                <p className="i">
                  GET
                  /api/pios/public/projects?orderBy.direction=Ascending&orderBy.propertyName=projectId&pageSize=50&pageNumber=1
                  HTTP/1.1
                  <br />
                  Accept: text/plain
                  <br />
                  ApiKey: ******************************************
                  <br />
                  Host: {`${config.defaultSubdomain}.${config.defaultDomain}`}
                  <br />
                </p>
              </div>

              <div>
                <Heading4>{t('Help.PublicApi.Response.Title')}</Heading4>
                <Trans i18nKey="Help.PublicApi.Response.Text" />
              </div>

              <div>
                <Heading4>{t('Help.PublicApi.Documentation.Title')}</Heading4>
                <Trans i18nKey="Help.PublicApi.Documentation.Text" />{' '}
                <a
                  target="_blank"
                  href={`${config.apiUrl.product.current}swagger/index.html?urls.primaryName=Dimatech.Backend.Api.Pios.Public`}
                  rel="noreferrer"
                >
                  {t('Common.UI.ClickHere')}
                </a>
              </div>
            </Style>
          </CardBody>
        </Card>
      )}
    </View>
  );
};

Help.displayName = 'Help';

const Style = styled.div`
  > div {
    margin-bottom: 20px;
  }
`;

const PermissionsStyle = styled(Style)`
  > div {
    > h4 {
      color: ${({ theme }: { theme: Theme }) => theme.colors.primaryVariant};
      width: fit-content;
    }
  }
`;
