import { ProjectHistory as ProjectHistoryFeature } from 'features/project/ProjectHistory';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const ProjectHistory = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View
      title={`${t('Project.Project')} - ${t('Project.History.TooltipTitle')}`}
    >
      <ProjectHistoryFeature />
    </View>
  );
};

ProjectHistory.displayName = 'ProjectHistory';
