import { Select } from '@dimatech/shared/lib/components/form';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Phase, ProjectIdea } from 'models';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { projectIdeaPhases } from 'utils';

export const IdeaStatusEdit = ({
  idea,
  handleChange,
  isViewReadOnly,
  style,
}: {
  idea: ProjectIdea;
  handleChange: (status?: Phase) => void;
  isViewReadOnly?: boolean;
  style?: CSSProperties;
}): JSX.Element | null => {
  const { t } = useTranslation();

  const isProjectIdeasReviewersEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectIdeasReviewersEnabled];

  return (
    <Select
      name="projectPhase"
      id="projectPhase"
      value={idea.projectPhase ?? undefined}
      onChange={(e) => {
        handleChange(
          e.currentTarget.value ? (e.currentTarget.value as Phase) : undefined
        );
      }}
      style={{ minWidth: 170, width: '100%', ...style }}
      disabled={isViewReadOnly}
      invalid={!idea.projectPhase}
    >
      <option />
      {projectIdeaPhases.map((value) => (
        <option key={value} value={value}>
          {!isProjectIdeasReviewersEnabledFlagOn &&
          value === Phase.ReadyForReview
            ? t(`Project.Phase.Published`)
            : t(`Project.Phase.${value}`)}
        </option>
      ))}
    </Select>
  );
};

IdeaStatusEdit.displayName = 'IdeaStatusEdit';
