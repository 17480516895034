import { Select } from '@dimatech/shared/lib/components/form';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useGetPhaseText } from 'hooks/useGetPhaseText';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Phase, Project } from 'models';

export const ProjectPhaseEdit = ({
  project,
  handleChange,
  isViewReadOnly,
}: {
  project: Project;
  handleChange: (pase?: Phase | null) => void;
  isViewReadOnly?: boolean;
}): JSX.Element | null => {
  const getPhaseText = useGetPhaseText();
  const isProjectEffectRealisationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectEffectRealisationEnabled];

  return (
    <Select
      name="projectPhase"
      id="projectPhase"
      value={project.projectPhase ?? undefined}
      onChange={(e) => {
        handleChange(
          e.currentTarget.value ? (e.currentTarget.value as Phase) : null
        );
      }}
      style={{ minWidth: 170, width: '100%' }}
      disabled={isViewReadOnly}
    >
      <option value={Phase.NotSet} />
      {Object.keys(Phase)
        .filter(
          (value) =>
            value !== Phase.NotSet &&
            // Filter out effect realisation phases if effect realisation flag is off
            (isProjectEffectRealisationEnabledFlagOn ||
              (value !== Phase.FinishedEffectRealizationStarted &&
                value !== Phase.FinishedEffectRealizationConcluded))
        )

        .map((value) => (
          <option key={value} value={value}>
            {getPhaseText({
              value: value as Phase,
            })}
          </option>
        ))}
    </Select>
  );
};

ProjectPhaseEdit.displayName = 'ProjectPhaseEdit';
