import { useUpgradeLicenseMutation } from '@dimatech/features-core/lib/api/account/accountApi';
import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import { useRefreshAuthTokenMutation } from '@dimatech/features-core/lib/api/authentication/authenticationApi';
import { useGetLicenseTypeQuery } from '@dimatech/features-core/lib/api/license/licenseApi';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { LicenseType } from '@dimatech/features-core/lib/features/authentication/components';
import {
  OrderProductBackLinkStyle,
  OrderProductButtonsStyle,
  OrderProductStyle,
} from '@dimatech/features-core/lib/features/authentication/components/OrderProduct';
import {
  Button,
  ButtonFooterWithToast,
  Checkbox,
  Label,
} from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { config } from '@dimatech/shared/lib/config';
import { CountryCode } from '@dimatech/shared/lib/models';
import { useAppDispatch } from 'hooks';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export const UpgradeLicense = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [isUpgradeLicense, setIsUpgradeLicense] = useState(false);
  const [isChangesSaved, setIsChangesSaved] = useState(false);

  const { accessToken, refreshToken, logout } = useContext(
    AuthenticationContext
  );

  const [refreshAuthToken] = useRefreshAuthTokenMutation();
  const licenseName = accessToken.user?.currentCustomer.license.name as string;

  const {
    data: licenseType,
    isLoading: isLoadingLicenseType,
    isFetching: isFetchingLicenseType,
    error: errorLicenseType,
  } = useGetLicenseTypeQuery({
    productId: config.products.pios.id as string,
    isConsortiumLicense: false,
    isBaseVersion: false,
    countryCode: accessToken.user?.currentCustomer.countryCode as CountryCode,
  });

  const [upgradeLicense, { isSuccess, isLoading }] =
    useUpgradeLicenseMutation();

  const handleUpgrade = (e: React.SyntheticEvent) => {
    e.preventDefault();

    upgradeLicense();
  };

  useEffect(() => {
    if (isSuccess && accessToken.customerId) {
      dispatch(appActions.resetApplication());

      refreshAuthToken({ refreshToken: refreshToken.token as string })
        .unwrap()
        .then((result) => {
          accessToken.update(result.accessToken);
          refreshToken.update(result.refreshToken);
        })
        .catch(() => {
          logout();
        })
        .finally(() => {
          navigate('/portfolios');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <>
      <Heading2>{t('UpgradeLicense.Title')}</Heading2>
      <Style>
        <p>
          {t('UpgradeLicense.Text2', {
            name: t(`LicenseType.${licenseName.replace(/\s/g, '_')}`),
          })}
        </p>
        <p>{t('UpgradeLicense.Text3')}</p>

        {(isLoadingLicenseType || isFetchingLicenseType) && <LoaderSmall />}

        {!accessToken.isInternational &&
          licenseType &&
          !isLoadingLicenseType &&
          !isFetchingLicenseType &&
          !errorLicenseType && (
            <LicenseType
              licenseType={licenseType}
              title={t('UpgradeLicense.NewLicence')}
            />
          )}

        <OrderProductButtonsStyle>
          <div
            style={{
              marginTop: '40px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Checkbox
              checked={isUpgradeLicense}
              name="upgradeLicense"
              id="upgradeLicense"
              onChange={(e) => setIsUpgradeLicense(e.currentTarget.checked)}
            />

            <Label
              htmlFor="upgradeLicense"
              style={{ display: 'inline-block', margin: 10 }}
            >
              {t('UpgradeLicense.UpgradeLicense', {
                name: accessToken.user?.currentCustomer.license.name,
              })}
            </Label>
          </div>

          <ButtonFooterWithToast
            isSaved={isChangesSaved}
            setIsSaved={setIsChangesSaved}
          >
            <Button
              type="button"
              style={{ width: 200 }}
              onClick={handleUpgrade}
              disabled={isLoading || !isUpgradeLicense}
            >
              {isLoading
                ? t('UpgradeLicense.Upgrading')
                : t('UpgradeLicense.Upgrade')}
            </Button>
          </ButtonFooterWithToast>
        </OrderProductButtonsStyle>
      </Style>

      <OrderProductBackLinkStyle>
        <Link to={(location.state as { from: string })?.from ?? '/'}>
          {t('UpgradeLicense.BackLink')}
        </Link>
      </OrderProductBackLinkStyle>
    </>
  );
};

UpgradeLicense.displayName = 'UpgradeLicense';

const Style = styled(OrderProductStyle).attrs({ as: 'div' })``;
