import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { Color, Theme } from '@dimatech/shared/lib/themes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Phase } from 'models';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCheckCircleFill, BsCircleFill } from 'react-icons/bs';
import styled, { withTheme } from 'styled-components';

export const IdeaStatus = withTheme(
  ({
    theme,
    status,
    isSelected = false,
    isLabelShown = false,
    isIconShown = true,
    style,
    handleClick,
  }: {
    theme: Theme;
    status?: Phase | 'Archived' | null;
    isSelected?: boolean;
    isLabelShown?: boolean;
    isIconShown?: boolean;
    style?: CSSProperties;
    handleClick?: (status: Phase | 'Archived' | null | undefined) => void;
  }): JSX.Element => {
    const { t } = useTranslation();

    const isProjectIdeasReviewersEnabledFlagOn =
      useFlags()[flags.permanent.app.pios.isProjectIdeasReviewersEnabled];

    return (
      <>
        <TooltipContent
          id="tt-idea-archived"
          title={t('FutureProjects.ProjectIdea.Archive.Name')}
          text={t('FutureProjects.ProjectIdea.Archive.Text')}
        />
        <TooltipContent
          id="tt-idea-draft"
          title={t('FutureProjects.ProjectIdea.Draft.Name')}
          text={t('FutureProjects.ProjectIdea.Draft.Text')}
        />
        <TooltipContent
          id="tt-idea-published"
          title={t('FutureProjects.ProjectIdea.Publish.Name')}
          text={t('FutureProjects.ProjectIdea.Publish.Text')}
        />
        <TooltipContent
          id="tt-idea-ready"
          title={t('FutureProjects.ProjectIdea.Ready.Name')}
          text={t('FutureProjects.ProjectIdea.Ready.Text')}
        />
        <TooltipContent
          id="tt-idea-ready-for-review"
          title={t('FutureProjects.ProjectIdea.ReadyForReview.Name')}
          text={t('FutureProjects.ProjectIdea.ReadyForReview.Text')}
        />

        <Style
          style={{
            borderBottom: `3px solid ${
              isSelected ? theme.colors.secondary : 'transparent'
            }`,
          }}
        >
          {isIconShown && (
            <>
              {(status === Phase.Draft || !status) && (
                <BsCircleFill
                  data-tip
                  data-for="tt-idea-draft"
                  style={{ ...style, color: Color.KojiOrangeLight }}
                  onClick={() => handleClick?.(Phase.Draft)}
                />
              )}

              {status === 'Archived' && (
                <BsCircleFill
                  data-tip
                  data-for="tt-idea-archived"
                  style={{ ...style, color: Color.Grey70 }}
                  onClick={() => handleClick?.(status)}
                />
              )}

              {status === Phase.ReadyForReview &&
                !isProjectIdeasReviewersEnabledFlagOn && (
                  <BsCircleFill
                    data-tip
                    data-for="tt-idea-published"
                    style={{ ...style, color: Color.SeaGreenLight }}
                    onClick={() => handleClick?.(status)}
                  />
                )}

              {status === Phase.Ready && (
                <BsCircleFill
                  data-tip
                  data-for="tt-idea-ready"
                  style={{ ...style, color: Color.CaribbeanGreenLight }}
                  onClick={() => handleClick?.(status)}
                />
              )}

              {status === Phase.ReadyForReview &&
                isProjectIdeasReviewersEnabledFlagOn && (
                  <BsCheckCircleFill
                    data-tip
                    data-for="tt-idea-ready-for-review"
                    style={{ ...style, color: Color.CaribbeanGreenLight }}
                    onClick={() => handleClick?.(status)}
                  />
                )}
            </>
          )}

          {isLabelShown && (
            <span style={{ marginLeft: isIconShown ? 7 : 0 }}>
              {!isProjectIdeasReviewersEnabledFlagOn &&
              status === Phase.ReadyForReview
                ? t(`Project.Phase.Published`)
                : t(`Project.Phase.${status ?? Phase.Draft}`)}
            </span>
          )}
        </Style>
      </>
    );
  }
);

IdeaStatus.displayName = 'IdeaStatus';

const Style = styled.div`
  svg {
    margin-right: 0 !important;
    vertical-align: middle;
    font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.xl};
  }
`;
