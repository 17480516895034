import { useTitle } from '@dimatech/shared/lib/hooks';
import { selectSelectedProjectIdeaAction } from 'api/piosSlice';
import { ProjectIdeas as ProjectIdeasFeature } from 'features/project-idea/Ideas';
import { useAppSelector } from 'hooks';
import { ProjectIdeaAction } from 'models';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const ProjectIdeas = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { setTitle } = useTitle();

  const selectedAction = useAppSelector(selectSelectedProjectIdeaAction);

  useEffect(() => {
    let title = t('FutureProjects.ProjectIdea.ProjectIdea');

    switch (selectedAction) {
      case ProjectIdeaAction.Identify:
        title = `${t('FutureProjects.ProjectIdea.Title')} - ${t(
          'FutureProjects.ProjectIdea.ProjectIdeaAction.Identify'
        )}`;
        break;

      case ProjectIdeaAction.Qualify:
        title = `${t('FutureProjects.ProjectIdea.Title')} - ${t(
          'FutureProjects.ProjectIdea.ProjectIdeaAction.Qualify'
        )}`;
        break;

      case ProjectIdeaAction.Prioritise:
        title = `${t('FutureProjects.ProjectIdea.Title')} - ${t(
          'FutureProjects.ProjectIdea.ProjectIdeaAction.Prioritise'
        )}`;
        break;

      case ProjectIdeaAction.History:
        title = `${t('FutureProjects.ProjectIdea.ProjectIdea')} - ${t(
          'Project.History.TooltipTitle'
        )}`;
        break;

      case ProjectIdeaAction.Classify:
        title = `${t('FutureProjects.ProjectIdea.Title')} - ${t(
          'FutureProjects.ProjectIdea.ProjectIdeaAction.Classify'
        )}`;
        break;

      case ProjectIdeaAction.Review:
        title = `${t('FutureProjects.ProjectIdea.Title')} - ${t(
          'FutureProjects.ProjectIdea.ProjectIdeaAction.Review'
        )}`;
        break;

      case ProjectIdeaAction.Create:
        title = `${t('FutureProjects.ProjectIdea.ProjectIdea')} - ${t(
          'FutureProjects.ProjectIdea.Add.Title'
        )}`;
        break;

      case ProjectIdeaAction.Edit:
        title = `${t('FutureProjects.ProjectIdea.ProjectIdea')} - ${t(
          'FutureProjects.ProjectIdea.Edit.Title'
        )}`;
        break;
    }

    setTitle(title);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAction]);

  return (
    <View title={t('FutureProjects.ProjectIdea.Title')}>
      <ProjectIdeasFeature />
    </View>
  );
};

ProjectIdeas.displayName = 'ProjectIdeas';
