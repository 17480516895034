import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Progress } from '@dimatech/shared/lib/components/Progress';
import { Tag } from '@dimatech/shared/lib/components/Tag';
import {
  Pagination,
  useUiSortablePaginator,
} from '@dimatech/shared/lib/components/paginator';
import {
  SortElement,
  SortElements,
} from '@dimatech/shared/lib/components/sorter';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdCentered,
  TdRight,
  Th,
  ThCentered,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { Color } from '@dimatech/shared/lib/themes';
import { selectFilter } from 'api/piosSlice';
import { useAppSelector } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Permission,
  PiosColors,
  PortfolioOverview,
  Project,
  Tag as TagModel,
} from 'models';
import { Fragment, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCheckCircleFill, BsCircle } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatAsNumber, hasPermission, isReadOnly } from 'utils';
import { ProjectActions } from './ProjectActions';
import { ProjectPhase } from './ProjectPhase';
import { ProjectStatus } from './ProjectStatus';
import { ProjectsTableBaseLicense } from './ProjectsTableBaseLicense';

export const ProjectsTable = ({
  isProjectEditDisabled = false,
  projects,
  portfolio,
  saveToPngElementId,
}: {
  isProjectEditDisabled?: boolean;
  projects: Project[];
  portfolio?: PortfolioOverview | null;
  saveToPngElementId?: string;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const navigate = useNavigate();
  const location = useLocation();
  const filter = useAppSelector(selectFilter);

  const isTagsEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isTagsEnabled];

  const { items, setPage, paginator, sorter } = useUiSortablePaginator({
    orderBy: 'title',
    pageSize: 25,
    data: {
      totalRecords: projects.length,
      records: projects,
    },
  });

  const handleSelect = (toPortfolioId: string, toProjectId: string) => {
    navigate(`/project/${toPortfolioId}/${toProjectId}`, {
      state: { from: location.pathname },
    });
  };

  useEffect(() => {
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  if (items && items.length === 0) {
    return null;
  }

  return (
    <>
      <Pagination
        currentPage={paginator.page}
        totalCount={projects.length}
        pageSize={paginator.pageSize}
        handlePageChange={(page) => setPage(page)}
      />

      {!accessToken.isBaseLicense && (
        <TableResponsiveContainer style={{ marginTop: 20 }}>
          <Table id={saveToPngElementId}>
            <thead>
              <tr>
                <ThCentered
                  className="narrow"
                  sortKey="projectStatus"
                  sorter={sorter}
                  style={{ width: 55 }}
                >
                  {t('Project.Status.Status')}
                </ThCentered>
                <Th
                  className="narrow"
                  sortKey="projectPhase"
                  sorter={sorter}
                  style={{ width: 55 }}
                >
                  {t('Project.Phase.Phase')}
                </Th>
                <ThCentered
                  className="narrow"
                  sortKey="hasEffectTakeHomePlan"
                  sorter={sorter}
                  style={{ width: 55 }}
                >
                  {t('Project.EffectTakeHome.Header')}
                </ThCentered>
                <Th className="narrow" sortKey="externalId" sorter={sorter}>
                  {t('Project.Id')}
                </Th>
                <Th>
                  <SortElements>
                    <SortElement sortKey="title" sorter={sorter}>
                      {t('Project.Project')}
                    </SortElement>
                    <>
                      {!portfolio && (
                        <SortElement sortKey="portfolioName" sorter={sorter}>
                          {t('Portfolio.Portfolio')}
                        </SortElement>
                      )}
                    </>
                  </SortElements>
                </Th>
                <Th sortKey="entityName" sorter={sorter}>
                  {t('Portfolio.Entity')}
                </Th>
                <ThRight className="narrow" sortKey="budget" sorter={sorter}>
                  {t('Project.Budget', {
                    currency: t(
                      `Common.Currency.${accessToken.locale.currency}.Name`
                    ),
                  })}
                </ThRight>
                {filter.period !== undefined && (
                  <ThRight
                    className="narrow"
                    sortKey="totalYearlyBudgetForYear"
                    sorter={sorter}
                  >
                    {t('StatisticsYearly.TotalYearlyBudgetForYear', {
                      year: filter.period,
                      currency: t(
                        `Common.Currency.${accessToken.locale.currency}.Name`
                      ),
                    })}
                  </ThRight>
                )}
                <ThRight className="narrow" sortKey="startYear" sorter={sorter}>
                  {t('Project.StartYear')}
                </ThRight>
                <ThRight className="narrow" sortKey="endYear" sorter={sorter}>
                  {t('Project.EndYear')}
                </ThRight>
                {isTagsEnabledFlagOn && <Th>{t('Project.Tag.Tags')}</Th>}

                <ThCentered
                  style={{ width: '1%' }}
                  sortKey="efficiencyInnovation"
                  sorter={sorter}
                >
                  {t('Portfolio.AverageEfficiencyInnovation')}
                </ThCentered>
                <ThCentered
                  className="narrow"
                  style={{ width: '1%' }}
                  sortKey="internalExternal"
                  sorter={sorter}
                >
                  {t('Portfolio.AverageInternalExternal')}
                </ThCentered>

                {!isProjectEditDisabled && portfolio && (
                  <Th style={{ width: 0, maxWidth: 60 }} />
                )}
              </tr>
            </thead>

            <tbody>
              {items.map((project) => (
                <Fragment key={project.id}>
                  <Tr
                    onSelect={() =>
                      handleSelect(
                        project.portfolioId as string,
                        project.id as string
                      )
                    }
                  >
                    <TdCentered className="narrow">
                      <ProjectStatus
                        status={project.projectStatus}
                        style={{ marginRight: 20 }}
                      />
                    </TdCentered>
                    <Td className="narrow">
                      <ProjectPhase phase={project.projectPhase} />
                    </Td>
                    <TdCentered className="narrow">
                      {!project.hasEffectTakeHomePlan && <BsCircle />}

                      {project.hasEffectTakeHomePlan && (
                        <BsCheckCircleFill
                          style={{ color: Color.CaribbeanGreenLight }}
                        />
                      )}
                    </TdCentered>
                    <Td className="narrow">{project.externalId}</Td>
                    <Td>
                      <div className="b">{project.title}</div>
                      {!portfolio && (
                        <div className="i">{project.portfolioName}</div>
                      )}
                    </Td>
                    <Td>{project.entityName}</Td>

                    <TdRight className="narrow">
                      {formatAsNumber(project.budget, null)}
                    </TdRight>
                    {filter.period !== undefined && (
                      <TdRight className="narrow">
                        {formatAsNumber(project.totalBudgetPeriod, null)}
                      </TdRight>
                    )}
                    <TdRight className="narrow">{project.startYear}</TdRight>
                    <TdRight className="narrow">{project.endYear}</TdRight>

                    {isTagsEnabledFlagOn && (
                      <Td>
                        {[...(project.tags ?? [])]
                          ?.sort((tagA, tagB) =>
                            tagA.displayName > tagB.displayName ? 1 : -1
                          )
                          .map((tag: TagModel) => (
                            <Tag key={tag.id} tag={tag} />
                          ))}
                      </Td>
                    )}

                    <TdCentered>
                      <Progress
                        width={110}
                        value={project.efficiencyInnovation}
                        color={PiosColors.efficiency}
                        backgroundColor={PiosColors.innovation}
                      />
                    </TdCentered>
                    <TdCentered className="narrow">
                      <Progress
                        width={110}
                        value={project.internalExternal}
                        color={PiosColors.internal}
                        backgroundColor={PiosColors.external}
                      />
                    </TdCentered>

                    {!isProjectEditDisabled && portfolio && (
                      <TdRight style={{ verticalAlign: 'middle' }}>
                        {hasPermission(Permission.Edit, project) && (
                          <ProjectActions
                            project={project}
                            userCanEditPortfolio={!isReadOnly(portfolio)}
                          />
                        )}
                      </TdRight>
                    )}
                  </Tr>
                </Fragment>
              ))}
            </tbody>
          </Table>
        </TableResponsiveContainer>
      )}

      {accessToken.isBaseLicense && (
        <ProjectsTableBaseLicense
          saveToPngElementId={saveToPngElementId}
          items={items}
          sorter={sorter}
          handleSelect={handleSelect}
        />
      )}

      <Pagination
        currentPage={paginator.page}
        totalCount={projects.length}
        pageSize={paginator.pageSize}
        handlePageChange={(page) => setPage(page)}
      />
    </>
  );
};

ProjectsTable.displayName = 'ProjectsTable';
