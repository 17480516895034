import { selectSelectedEntity } from '@dimatech/features-core/lib/api/entity/entitySlice';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  Impersonate,
  SendForgotPasswordEmail,
} from '@dimatech/features-core/lib/features/authentication/components';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import {
  HelpIcon,
  HelpIconLeft,
} from '@dimatech/shared/lib/components/HelpIcon';
import {
  Button,
  ButtonLink,
  ButtonSecondary,
  Buttons,
  Checkbox,
  Label,
} from '@dimatech/shared/lib/components/form';
import { Loader } from '@dimatech/shared/lib/components/loader';
import { Modal } from '@dimatech/shared/lib/components/modal';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdRight,
  Th,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Heading3 } from '@dimatech/shared/lib/components/typography';
import { useSortableData } from '@dimatech/shared/lib/hooks';
import { TransArray } from '@dimatech/shared/lib/localization';
import { Admin, ApiError, SortDirection } from '@dimatech/shared/lib/models';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetPortfoliosQuery } from 'api/portfolio/portfolioApi';
import {
  useAddPortfolioOwnerMutation,
  useGetPortfolioOwnersQuery,
  useRemovePortfolioOwnershipMutation,
} from 'api/portfolio/portfolioOwnerApi';
import {
  portfolioActions,
  selectSelectedPortfolio,
} from 'api/portfolio/portfolioSlice';
import { parseISO } from 'date-fns';
import { useAppDispatch, useAppSelector } from 'hooks';
import { CommonRoles, Portfolio } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPersonBadge, BsPersonPlus, BsTrash } from 'react-icons/bs';
import { formatDate, isAdminReadOnly } from 'utils';
import { EntityUserAdd } from './EntityUserAdd';

export const EntityOwners = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { accessToken } = useContext(AuthenticationContext);
  const isReadOnly = isAdminReadOnly(accessToken);
  const selectedEntity = useAppSelector(selectSelectedEntity);
  const selectedPortfolio = useAppSelector(selectSelectedPortfolio);
  const [owners, setOwners] = useState<Admin[]>([]);
  const [isAdding, setIsAdding] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    string[] | undefined
  >();

  const { data: dataOwners, isFetching } = useGetPortfolioOwnersQuery(
    accessToken.customerId
      ? {
          filter: {
            entityIds: [],
            email: '',
          },
          _customerId: accessToken.customerId,
        }
      : skipToken
  );

  const { data: dataPortfolios } = useGetPortfoliosQuery(
    accessToken.customerId && selectedEntity
      ? {
          entityId: selectedEntity.id,
          _customerId: accessToken.customerId,
        }
      : skipToken
  );

  const [addPortfolioOwner, { error }] = useAddPortfolioOwnerMutation();

  const { items, sorter } = useSortableData(owners ?? [], {
    key: 'email',
    direction: SortDirection.Asc,
  });

  const handleAddOwner = (user: Admin, portfolio: Portfolio) => {
    setValidationErrors(undefined);

    if (user?.email?.includes(' ')) {
      setValidationErrors?.(['Owners']);
      return;
    }

    if (user.email && portfolio.owners?.includes(user.email)) {
      setValidationErrors(['ErrorSameOwner']);
      return;
    }

    addPortfolioOwner({
      portfolioId: portfolio.id as string,
      owner: user.email as string,
    })
      .unwrap()
      .then((result) => {
        if (selectedPortfolio?.owners) {
          dispatch(
            portfolioActions.selectedPortfolio({
              ...selectedPortfolio,
              owners: [...selectedPortfolio.owners, `${result.owner}`],
            })
          );
        }
      });
  };

  useEffect(() => {
    setValidationErrors(undefined);
  }, []);

  useEffect(() => {
    setValidationErrors(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEntity]);

  useEffect(() => {
    if (error) {
      setValidationErrors((error as ApiError)?.validationErrors);
    } else {
      setValidationErrors(undefined);
    }
  }, [error]);

  useEffect(() => {
    const owners = dataOwners?.filter((owners) =>
      owners.portfolios?.some(
        (portfolio) => portfolio.entityId === selectedEntity?.id
      )
    );
    if (owners) {
      setOwners(owners);
    }
  }, [selectedEntity, dataOwners]);

  if (isFetching) {
    return (
      <div style={{ marginTop: 20 }}>
        <Loader>{t('Common.UI.Loading')}</Loader>
      </div>
    );
  }

  return selectedEntity?.id && dataPortfolios && dataPortfolios.length > 0 ? (
    <>
      <Heading3 style={{ marginTop: 30 }}>
        {t('UserRoles.PortfolioOwner.Title')}
      </Heading3>

      <HelpIconLeft style={{ marginTop: 10, marginBottom: 10 }}>
        {t('UserRoles.PortfolioOwner.Text')}

        <HelpIcon
          style={{ marginLeft: 7 }}
          title={t('UserRoles.PortfolioOwner.Title')}
          text={<TransArray i18nKey="UserRoles.PortfolioOwner.Help" />}
        />
      </HelpIconLeft>

      <div style={{ display: 'flex', marginTop: 10, marginBottom: 10 }}>
        <TooltipContent
          id="tt-owner-add"
          title={t('Portfolio.Owner.Add.TooltipTitle')}
          text={t('Portfolio.Owner.Add.Tooltip')}
        />
        <BadgeMini
          data-tip
          data-for="tt-owner-add"
          onClick={() => setIsAdding(true)}
        >
          <BsPersonPlus />
        </BadgeMini>

        <ButtonLink
          data-tip
          data-for="tt-owner-add"
          onClick={() => setIsAdding(true)}
          style={{ marginLeft: 10 }}
        >
          {t('Portfolio.Owner.Add.Title')}
        </ButtonLink>
      </div>

      {validationErrors && (
        <AlertWarning style={{ marginTop: 10, width: '100%' }}>
          {validationErrors.map((validationError) => (
            <div key={validationError}>
              {t(`Portfolio.Owner.ValidationError.${validationError}`)}
            </div>
          ))}
        </AlertWarning>
      )}

      {owners?.length === 0 && (
        <div style={{ marginTop: 20, marginBottom: 10 }}>
          {t('Portfolio.Owner.NoOwners')}
        </div>
      )}

      {isAdding && (
        <EntityUserAdd
          setIsAdding={setIsAdding}
          handleAddOwner={handleAddOwner}
          role={CommonRoles.PortfolioOwner}
        />
      )}

      {owners && owners.length > 0 && (
        <TableResponsiveContainer>
          <Table style={{ marginTop: 10, marginBottom: 10 }}>
            <thead>
              <tr>
                <Th />
                <Th sortKey="email" sorter={sorter}>
                  {t('Administrate.Entity.Email')}
                </Th>
                <ThRight sortKey="lastLoginDate" sorter={sorter}>
                  {t('Administrate.Entity.LastLogin')}
                </ThRight>
                <Th />
              </tr>
            </thead>
            <tbody>
              {items?.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td style={{ width: 25 }}>
                      <BsPersonBadge />
                    </Td>
                    <Td>{item.email}</Td>
                    <TdRight
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {item.lastLoginDate
                        ? formatDate(parseISO(item.lastLoginDate))
                        : '-'}
                    </TdRight>
                    <TdRight>
                      <div
                        style={{
                          verticalAlign: 'middle',
                          display: 'flex',
                          justifyContent: 'end',
                        }}
                      >
                        <EditButtons
                          user={item}
                          setValidationErrors={setValidationErrors}
                          isReadOnly={isReadOnly}
                        />
                      </div>
                    </TdRight>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
        </TableResponsiveContainer>
      )}
    </>
  ) : null;
};

EntityOwners.displayName = 'EntityOwners';

const EditButtons = ({
  user,
  setValidationErrors,
  isReadOnly,
}: {
  user: Admin;
  setValidationErrors: (validationErrors: string[] | undefined) => void;
  isReadOnly: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedEntity = useAppSelector(selectSelectedEntity);
  const selectedPortfolio = useAppSelector(selectSelectedPortfolio);
  const [isDeleting, setIsDeleting] = useState(false);
  const [includeChildEntities, setIncludeChildEntities] = useState(true);
  const [removeOwnership] = useRemovePortfolioOwnershipMutation();

  const handleConfirmDelete = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (isReadOnly) {
      return;
    }

    if (!user.email) {
      return;
    }

    removeOwnership({
      owner: user.email,
      entityId: selectedEntity?.id,
      includeChildEntities,
    })
      .unwrap()
      .then(() => {
        if (selectedPortfolio?.owners) {
          const owners = selectedPortfolio.owners.filter(
            (owner) => owner !== user.email
          );
          dispatch(
            portfolioActions.selectedPortfolio({
              ...selectedPortfolio,
              owners,
            })
          );
        }
      });

    setIsDeleting(false);
    setIncludeChildEntities(true);
  };

  const handleDelete = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setValidationErrors(undefined);
    setIsDeleting(show);
  };

  return (
    <>
      {isDeleting && (
        <Modal
          title={t('Administrate.Entity.Owner.Delete.ConfirmTitle')}
          handleKeyEnter={(e) => handleConfirmDelete(e)}
          handleKeyEsc={(e) => handleDelete(e, false)}
        >
          <div>{t('Administrate.Entity.Owner.Delete.ConfirmText')}</div>

          {selectedEntity?.childCount !== undefined &&
            selectedEntity.childCount > 0 && (
              <div>
                <Label htmlFor="includeChildEntities">
                  <Checkbox
                    id="includeChildEntities"
                    name="includeChildEntities"
                    style={{ marginRight: 10 }}
                    checked={includeChildEntities ?? true}
                    onChange={(e) =>
                      setIncludeChildEntities(e.currentTarget.checked)
                    }
                  />
                  {t('Administrate.Entity.Owner.Delete.IncludeChildEntities')}
                </Label>
              </div>
            )}

          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={(e) => handleDelete(e, false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>

            <Button
              type="button"
              onClick={(e) => handleConfirmDelete(e)}
              disabled={isReadOnly}
            >
              {t('Common.UI.Yes')}
            </Button>
          </Buttons>
        </Modal>
      )}

      <Impersonate email={user.email as string} />
      <SendForgotPasswordEmail email={user.email as string} />

      <TooltipContent
        id="tt-owner-delete"
        title={t('Administrate.Entity.Owner.Delete.TooltipTitle')}
        text={t('Administrate.Entity.Owner.Delete.Tooltip')}
      />
      <BadgeMini
        data-tip
        data-for="tt-owner-delete"
        onClick={(e) => handleDelete(e, true)}
        style={{ marginLeft: 7 }}
      >
        <BsTrash />
      </BadgeMini>
    </>
  );
};
