import {
  Heatmap,
  HeatmapConfig,
} from '@dimatech/shared/lib/components/heatmap';
import { DataPlot } from '@dimatech/shared/lib/models';
import { Theme } from '@dimatech/shared/lib/themes';
import { OverviewRangeDetails } from 'components/OverviewRangeDetails';
import { Overview, ProjectIdea } from 'models';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';
import {
  getPercentRemainder,
  innovationEfficiencyToPercent,
  internalExternalToPercent,
} from 'utils';

export const IdeaHeatmapCompare = withTheme(
  ({
    theme,
    ideas: projects,
    showEstimated = true,
    name,
  }: {
    theme: Theme;
    ideas: ProjectIdea[];
    showEstimated?: boolean;
    name: string;
  }): JSX.Element => {
    const cellSize = 20;
    const cellSizePixels = 32;

    const { t } = useTranslation();

    const [showDetailsFor, setShowDetailsFor] = useState<
      Overview & { similar: Overview[] }
    >();

    const formatter = (value: number) =>
      `${100 - value - cellSize}-${100 - value} % - ${value}-${
        value + cellSize
      } %`;

    const overviews = projects.map(
      (project): Overview => ({
        id: project.id,
        externalId: project.externalId,
        label: project.title as string,
        entityName: project.entityName,
        portfolioId: project.portfolioId,
        internalExternal: showEstimated
          ? project.internalExternalClassification &&
            getPercentRemainder(
              internalExternalToPercent(project.internalExternalClassification)
            )
          : project.internalExternal,
        efficiencyInnovation: showEstimated
          ? project.innovationEfficiencyClassification &&
            getPercentRemainder(
              innovationEfficiencyToPercent(
                project.innovationEfficiencyClassification
              )
            )
          : project.efficiencyInnovation,
      })
    );

    const data = overviews.map(
      (overview) =>
        ({
          id: overview.id,
          y: overview.internalExternal,
          x: overview.efficiencyInnovation,
          z: 1,
          label: overview.label,
          info: overview.entityName,
        } as DataPlot)
    );

    const config: HeatmapConfig = {
      gridSize: { x: 5, y: 5 },
      cellSize,
      cellSizePixels,
      calcX: (index: number) => index * 20,
      calcY: (index: number) => index * 20,
      colorFrom: theme.colors.surface,
      colorTo: theme.colors.primary,
      tooltip: {
        showDetails: true,
        title: t('FutureProjects.ProjectIdea.Title'),
        labelX: showEstimated
          ? t('FutureProjects.ProjectIdea.EfficiencyInnovationEstimate')
          : t('FutureProjects.ProjectIdea.EfficiencyInnovation'),
        labelY: showEstimated
          ? t('FutureProjects.ProjectIdea.InternalExternalEstimate')
          : t('FutureProjects.ProjectIdea.InternalExternal'),
        formatterX: formatter,
        formatterY: formatter,
        formatterZ: formatter,
      },
    };

    return (
      <>
        <Heatmap data={data} config={config} name={name} />

        {showDetailsFor && (
          <OverviewRangeDetails
            showDetailsFor={showDetailsFor}
            setShowDetailsFor={setShowDetailsFor}
            title={t('FutureProjects.ProjectIdea.ProjectIdea')}
          />
        )}
      </>
    );
  }
);

IdeaHeatmapCompare.displayName = 'IdeaHeatmapCompare';
