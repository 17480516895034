import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  Table,
  Td,
  TdCentered,
  TdRight,
  Th,
  ThCentered,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { flags } from '@dimatech/shared/lib/feature-flags';
import {
  Breakpoints,
  Color,
  Theme,
  ThemeType,
} from '@dimatech/shared/lib/themes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetPortfoliosOverviewQuery } from 'api/portfolio/portfolioOverviewApi';
import { PortfoliosCompareHeatmap } from 'features/portfolio/components/PortfoliosCompareHeatmap';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Dimension, PortfolioOverview, ProjectIdea } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCircleFill } from 'react-icons/bs';
import styled, { withTheme } from 'styled-components';
import { formatAsCurrency, formatAsNumber, scaler } from 'utils';
import { IdeaHeatmapCompare } from './IdeaHeatmapCompare';
import { IdeaRadarChartCompare } from './IdeaRadarChartCompare';
import { IdeaScatterChart } from './IdeaScatterChart';

export const IdeaListPrioritiseCompare = withTheme(
  ({
    theme,
    ideas,
    classifications,
  }: {
    theme: Theme;
    ideas: ProjectIdea[];
    classifications: Dimension[];
  }): JSX.Element | null => {
    const { t } = useTranslation();
    const { accessToken } = useContext(AuthenticationContext);

    const [selectedPortfolios, setSelectedPortfolios] = useState<
      PortfolioOverview[]
    >([]);

    const isProjectEffectRealisationEnabledFlagOn =
      useFlags()[flags.permanent.app.pios.isProjectEffectRealisationEnabled];

    const { data: portfoliosOverview } = useGetPortfoliosOverviewQuery(
      accessToken.customerId && accessToken.user?.id
        ? {
            filter: {},
            _customerId: accessToken.customerId,
            _userId: accessToken.user.id,
          }
        : skipToken
    );

    const scale = scaler({
      colorFrom: theme.type === ThemeType.Dark ? Color.Black : Color.White,
      colorTo: Color.CaribbeanGreenLight,
      start: Math.min(...ideas.map((i) => i.quota ?? 0)),
      end: Math.max(...ideas.map((i) => i.quota ?? 0)),
      min: 0.3,
    });

    useEffect(() => {
      const selectedPortfolioIds = ideas.map((idea) => idea.portfolioId);

      const filteredPortfolios = portfoliosOverview?.portfolios?.filter(
        (portfolio) => selectedPortfolioIds.includes(portfolio.portfolioId)
      );

      setSelectedPortfolios(filteredPortfolios ?? []);
    }, [portfoliosOverview, ideas]);

    return (
      <Style>
        <div>
          {classifications && classifications.length > 0 && (
            <Table>
              <thead>
                <tr>
                  <Th />
                  <Th>{t('FutureProjects.ProjectIdea.ProjectIdea')}</Th>
                  <ThRight
                    sortKey="cost"
                    className="narrow"
                    style={{ width: 60 }}
                  >
                    {isProjectEffectRealisationEnabledFlagOn
                      ? t('Project.Budget', {
                          currency: t(
                            `Common.Currency.${accessToken.locale.currency}.Name`
                          ),
                        })
                      : t('FutureProjects.ProjectIdea.Cost', {
                          currency: t(
                            `Common.Currency.${accessToken.locale.currency}.Name`
                          ),
                        })}
                  </ThRight>
                  <ThRight
                    sortKey="netBenefit"
                    className="narrow"
                    style={{ width: 60 }}
                  >
                    {t('FutureProjects.ProjectIdea.NetBenefit', {
                      currency: t(
                        `Common.Currency.${accessToken.locale.currency}.Name`
                      ),
                    })}
                  </ThRight>
                  <ThCentered style={{ width: 80 }}>
                    {t(
                      'Administrate.CustomDimension.DimensionClassificationType.Benefit'
                    )}
                  </ThCentered>
                  <ThCentered style={{ width: 80 }}>
                    {t(
                      'Administrate.CustomDimension.DimensionClassificationType.Stake'
                    )}
                  </ThCentered>
                  <ThCentered style={{ width: 80 }}>
                    {t('FutureProjects.ProjectIdea.Quota')}
                  </ThCentered>
                </tr>
              </thead>
              <tbody>
                {[...(ideas as ProjectIdea[])]
                  .sort((a, b) => ((a.quota ?? 0) > (b.quota ?? 0) ? -1 : 1))
                  .map((project, index) => (
                    <Tr key={project.id}>
                      <Td className="narrow">
                        <BsCircleFill
                          style={{
                            color:
                              theme.colors.chart.colors[
                                index % theme.colors.chart.colors.length
                              ],
                            marginRight: 5,
                          }}
                        />
                      </Td>
                      <Td className="narrow">{project.title}</Td>
                      <TdRight style={{ paddingTop: 10, whiteSpace: 'nowrap' }}>
                        {formatAsCurrency(
                          isProjectEffectRealisationEnabledFlagOn
                            ? project.budget
                            : project.cost
                        )}
                      </TdRight>
                      <TdRight style={{ paddingTop: 10, whiteSpace: 'nowrap' }}>
                        {formatAsCurrency(
                          isProjectEffectRealisationEnabledFlagOn
                            ? (project.totalExpectedBenefit ?? 0) -
                                (project.budget ?? 0)
                            : project.netBenefit
                        )}
                      </TdRight>
                      <TdCentered
                        style={{
                          width: 'fit-content',
                          height: 'fit-content',
                        }}
                      >
                        {formatAsNumber(project.quotaBenefit, '-', 1)}
                      </TdCentered>
                      <TdCentered
                        style={{
                          width: 'fit-content',
                          height: 'fit-content',
                        }}
                      >
                        {formatAsNumber(project.quotaStake, '-', 1)}
                      </TdCentered>
                      <TdCentered
                        style={{
                          width: 'fit-content',
                          height: 'fit-content',
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: scale(project.quota).hex(),
                            borderRadius: 100,
                            padding: 2,
                          }}
                        >
                          {formatAsNumber(project.quota, '-', 1)}
                        </div>
                      </TdCentered>
                    </Tr>
                  ))}
              </tbody>
            </Table>
          )}

          <div>
            <div>
              <div>{t('FutureProjects.ProjectIdea.Title')}</div>
              <IdeaHeatmapCompare
                ideas={ideas}
                showEstimated={false}
                name={t('FutureProjects.ProjectIdea.Title')}
              />
            </div>

            {selectedPortfolios.length > 0 && (
              <div>
                <div>{t('Portfolio.Portfolios')}</div>
                <PortfoliosCompareHeatmap
                  selectedPortfolios={selectedPortfolios}
                  name={t('Portfolio.Portfolios')}
                />
              </div>
            )}

            <div style={{ marginTop: 0 }}>
              <IdeaScatterChart ideas={ideas} />
            </div>
          </div>
        </div>

        <IdeaRadarChartCompare
          ideas={ideas}
          classifications={classifications}
        />
      </Style>
    );
  }
);

IdeaListPrioritiseCompare.displayName = 'IdeaListPrioritiseCompare';

const Style = styled.div`
  display: flex;
  justify-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 50px;

  > div:first-of-type {
    > table {
      width: fit-content;
      height: fit-content;
      margin-top: 20px;
      width: 660px;

      @media screen and (max-width: ${Breakpoints.small}) {
        width: 330px;
      }
    }

    > div {
      display: flex;
      flex-wrap: wrap;
      align-self: center;
      gap: 20px;
      margin-top: 40px;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
      }
    }
  }
`;
