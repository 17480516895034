import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import {
  HelpIcon,
  HelpIconCentered,
} from '@dimatech/shared/lib/components/HelpIcon';
import { Progress } from '@dimatech/shared/lib/components/Progress';
import { Checkbox } from '@dimatech/shared/lib/components/form';
import {
  Pagination,
  useUiSortablePaginator,
} from '@dimatech/shared/lib/components/paginator';
import {
  SortElement,
  SortElements,
} from '@dimatech/shared/lib/components/sorter';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdCentered,
  TdRight,
  Th,
  ThCentered,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { TransArray } from '@dimatech/shared/lib/localization';
import { Theme } from '@dimatech/shared/lib/themes';
import {
  piosActions,
  selectFilter,
  selectSelectedProjectIdea,
} from 'api/piosSlice';
import {
  useArchiveProjectIdeaMutation,
  useConvertToProjectMutation,
  useReadyForReviewProjectIdeaMutation,
  useReviewProjectIdeaMutation,
  useUpdateProjectIdeaMutation,
} from 'api/project/projectIdeaApi';
import { useSelectAndNavigate } from 'features/project-idea/useSelectAndNavigate';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Calculation,
  CalculationType,
  Dimension,
  DimensionDisplayType,
  DimensionType,
  Permission,
  Phase,
  PiosColors,
  ProjectIdea,
  ProjectIdeaAction,
  SortDirection,
} from 'models';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowLeftRight, BsPencil } from 'react-icons/bs';
import { withTheme } from 'styled-components';
import { formatAsCurrency, formatAsNumber, hasPermission } from 'utils';
import { IdeaClassification } from './IdeaClassification';
import { IdeaListPrioritiseCompare } from './IdeaListPrioritiseCompare';
import { IdeaListPrioritiseConvertButtons } from './IdeaListPrioritiseConvertButtons';
import { IdeaListTableGroupHeader } from './IdeaListTableGroupHeader';
import { IdeaStatus } from './IdeaStatus';

// TODO: Fix, split code
/* eslint-disable max-lines-per-function */

export const IdeaListPrioritise = withTheme(
  ({
    ideas,
    classifications,
    calculations,
    isReviewing = false,
    theme,
  }: {
    ideas: ProjectIdea[];
    classifications: Dimension[];
    calculations?: Calculation[];
    isReviewing?: boolean;
    theme: Theme;
  }): JSX.Element => {
    const { t } = useTranslation();
    const { accessToken } = useContext(AuthenticationContext);
    const dispatch = useAppDispatch();
    const selectAndNavigate = useSelectAndNavigate();
    const filter = useAppSelector(selectFilter);

    const selectedIdea = useAppSelector(selectSelectedProjectIdea);

    const [readyForReviewIdea] = useReadyForReviewProjectIdeaMutation();
    const [convertToProject] = useConvertToProjectMutation();
    const [archiveIdea] = useArchiveProjectIdeaMutation();

    const [isComparing, setIsComparing] = useState(false);
    const [selectedIdeas, setSelectedIdeas] = useState<ProjectIdea[]>([]);
    const [hasChanges, setHasChanges] = useState(false);
    const [portfolioId, setPortfolioId] = useState(selectedIdea?.portfolioId);

    const [updateProjectIdea] = useUpdateProjectIdeaMutation();
    const [reviewProjectIdea] = useReviewProjectIdeaMutation();

    const isProjectEffectRealisationEnabledFlagOn =
      useFlags()[flags.permanent.app.pios.isProjectEffectRealisationEnabled];

    const columnCountStake = classifications.filter(
      (c) =>
        c.type === DimensionType.ClassificationStake &&
        c.displayType === DimensionDisplayType.Rating
    ).length;

    const columnCountBenefit = classifications.filter(
      (c) =>
        c.type === DimensionType.ClassificationBenefit &&
        c.displayType === DimensionDisplayType.Rating
    ).length;

    const hasCalculations =
      ideas.some(
        (idea) =>
          idea.calculation && idea.calculation.type !== CalculationType.System
      ) &&
      calculations &&
      calculations.length > 0;

    const {
      items: sortedIdeas,
      setPage,
      paginator,
      sorter,
    } = useUiSortablePaginator({
      orderBy: 'quota',
      orderDirection: SortDirection.Desc,
      pageSize: 25,
      data: {
        totalRecords: ideas.length,
        records: ideas,
      },
    });

    const handleSelect = (idea: ProjectIdea) => {
      selectAndNavigate(
        isReviewing ? ProjectIdeaAction.Review : ProjectIdeaAction.Prioritise,
        selectedIdea?.projectId !== idea.projectId ? idea : undefined
      );
    };

    const handleMark = (idea: ProjectIdea) => {
      const newIdeas = [...selectedIdeas];
      const index = newIdeas.findIndex((s) => s.id === idea.id);

      if (index === -1) {
        newIdeas.push(idea);
      } else {
        newIdeas.splice(index, 1);
      }

      setSelectedIdeas(newIdeas);
    };

    const handleChange = (idea: ProjectIdea) => {
      dispatch(piosActions.setSelectedProjectIdea({ ...idea }));

      setHasChanges(true);
    };

    const handleSave = (
      status: Phase | 'Project' | 'Archived' | undefined | null
    ) => {
      if (!selectedIdea) {
        return;
      }

      if (isReviewing) {
        if (!hasChanges && status === 'Archived') {
          handleConvert(selectedIdea, status);
          return;
        }

        reviewProjectIdea({
          idea: {
            ...selectedIdea,
            projectPhase:
              status && status !== 'Project' && status !== 'Archived'
                ? status
                : status === 'Project'
                ? undefined
                : selectedIdea.projectPhase,
          },
          newPortfolioId: status === 'Project' ? portfolioId : undefined,
        })
          .unwrap()
          .then(() => {
            if (
              status === 'Archived' ||
              status === Phase.Draft ||
              status === Phase.Ready
            ) {
              handleConvert(selectedIdea, status);
            }
            setHasChanges(false);
          });
        return;
      }

      if (hasChanges) {
        updateProjectIdea(selectedIdea)
          .unwrap()
          .then((result) => {
            setHasChanges(false);
            handleConvert(result, status);
          });
      } else {
        handleConvert(selectedIdea, status);
      }
    };

    const handleConvert = (
      idea: ProjectIdea,
      status: Phase | 'Project' | 'Archived' | undefined | null
    ) => {
      if (!status) {
        return;
      }

      const reselectIdea = (result: ProjectIdea) => {
        dispatch(
          piosActions.setSelectedProjectIdea({ ...selectedIdea, ...result })
        );
        dispatch(piosActions.setHasUnsavedChanges(false));
      };

      switch (status) {
        case 'Archived':
          archiveIdea(idea).unwrap().then(reselectIdea);
          break;
        case Phase.Draft:
          updateProjectIdea({
            ...idea,
            projectPhase: status,
            isPublishedPublicly: false,
          })
            .unwrap()
            .then(reselectIdea);
          break;
        case Phase.Ready:
          updateProjectIdea({
            ...idea,
            projectPhase: status,
            isPublishedPublicly: false,
          })
            .unwrap()
            .then(reselectIdea);
          break;
        case Phase.ReadyForReview:
          readyForReviewIdea(idea).unwrap().then(reselectIdea);
          break;
        case 'Project':
          convertToProject({
            idea,
            newPortfolioId: portfolioId,
          })
            .unwrap()
            .then(reselectIdea);
          break;
      }
    };

    const groupBorderStyle = `1px solid ${theme.colors.border}`;

    useEffect(() => {
      setPage(1);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    useEffect(() => {
      setPortfolioId(selectedIdea?.portfolioId);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIdea]);

    return (
      <>
        <div style={{ marginTop: 0, marginBottom: 20 }}>
          {t('FutureProjects.ProjectIdea.PrioritiseText')}
        </div>

        {ideas.length === 0 && (
          <div style={{ marginTop: 10, marginBottom: 0 }}>
            {t('FutureProjects.ProjectIdea.NoProjectIdeas')}
          </div>
        )}

        {sortedIdeas.length > 0 && (
          <LinkWithTooltip
            style={{ marginTop: 20 }}
            handleClick={(e) => {
              setIsComparing(!isComparing);
            }}
            title={
              !isComparing
                ? t(`FutureProjects.ProjectIdea.CompareSelected.Title`)
                : t(`FutureProjects.ProjectIdea.UnCompareSelected.Title`)
            }
            tooltipTitle={t(
              `FutureProjects.ProjectIdea.CompareSelected.TooltipTitle`
            )}
            tooltip={t(`FutureProjects.ProjectIdea.CompareSelected.Tooltip`)}
            icon={<BsArrowLeftRight />}
            isDisabled={!selectedIdeas || selectedIdeas.length < 2}
          />
        )}

        {isComparing && selectedIdeas && selectedIdeas.length > 0 && (
          <IdeaListPrioritiseCompare
            classifications={classifications.filter(
              (classification) =>
                classification.displayType === DimensionDisplayType.Rating
            )}
            ideas={selectedIdeas}
          />
        )}

        {sortedIdeas.length > 0 && (
          <>
            <Pagination
              currentPage={paginator.page}
              totalCount={ideas.length}
              pageSize={paginator.pageSize}
              handlePageChange={(page) => setPage(page)}
            />

            <TableResponsiveContainer style={{ marginTop: 20 }}>
              <Table>
                <IdeaListTableGroupHeader
                  startColumnsCount={hasCalculations ? 7 : 6}
                  classifications={classifications}
                  showComparisonHelp={true}
                />

                <thead>
                  <tr>
                    <Th className="narrow" style={{ width: 25 }} />
                    <ThCentered
                      className="narrow"
                      sortKey="projectPhase"
                      sorter={sorter}
                      style={{ width: 50 }}
                    >
                      {t('FutureProjects.ProjectIdea.ProjectIdeaStatus')}
                    </ThCentered>
                    <Th
                      className="narrow"
                      sortKey="externalId"
                      sorter={sorter}
                      style={{ width: 60 }}
                    >
                      {t('Project.Id')}
                    </Th>
                    <Th>
                      <SortElements>
                        <SortElement sortKey="title" sorter={sorter}>
                          {t('FutureProjects.ProjectIdea.ProjectIdea')}
                        </SortElement>
                        <SortElement sortKey="portfolioName" sorter={sorter}>
                          {t('Portfolio.Portfolio')}
                        </SortElement>
                      </SortElements>
                    </Th>
                    <ThRight
                      sortKey="cost"
                      sorter={sorter}
                      className="narrow"
                      style={{ width: 60 }}
                    >
                      {isProjectEffectRealisationEnabledFlagOn
                        ? t('Project.Budget', {
                            currency: t(
                              `Common.Currency.${accessToken.locale.currency}.Name`
                            ),
                          })
                        : t('FutureProjects.ProjectIdea.Cost', {
                            currency: t(
                              `Common.Currency.${accessToken.locale.currency}.Name`
                            ),
                          })}
                    </ThRight>
                    <ThRight
                      sortKey="netBenefit"
                      sorter={sorter}
                      className="narrow"
                      style={{ width: 60 }}
                    >
                      {t('FutureProjects.ProjectIdea.NetBenefit', {
                        currency: t(
                          `Common.Currency.${accessToken.locale.currency}.Name`
                        ),
                      })}
                    </ThRight>

                    <ThCentered
                      sortKey="efficiencyInnovation"
                      sorter={sorter}
                      className="narrow"
                    >
                      {t('FutureProjects.ProjectIdea.EfficiencyInnovation')}
                    </ThCentered>
                    <ThCentered
                      sortKey="internalExternal"
                      sorter={sorter}
                      className="narrow"
                    >
                      {t('FutureProjects.ProjectIdea.InternalExternal')}
                    </ThCentered>

                    {classifications.length > 0 && (
                      <ThCentered
                        sortKey="quota"
                        sorter={sorter}
                        className="narrow"
                      >
                        <HelpIconCentered>
                          {t('FutureProjects.ProjectIdea.Quota')}

                          <HelpIcon
                            showTiny={true}
                            style={{ marginLeft: 5 }}
                            title={t('FutureProjects.ProjectIdea.Quota')}
                            text={
                              <TransArray i18nKey="FutureProjects.ProjectIdea.QuotaCalculation" />
                            }
                          />
                        </HelpIconCentered>
                      </ThCentered>
                    )}

                    {hasCalculations && (
                      <ThCentered
                        sortKey="calculationId"
                        sorter={sorter}
                        className="narrow"
                      >
                        <HelpIconCentered>
                          {t('FutureProjects.ProjectIdea.Calculation')}

                          <HelpIcon
                            showTiny={true}
                            style={{ marginLeft: 5 }}
                            title={t('FutureProjects.ProjectIdea.Calculation')}
                            text={t(
                              'FutureProjects.ProjectIdea.CalculationDescription'
                            )}
                          />
                        </HelpIconCentered>
                      </ThCentered>
                    )}

                    {classifications?.map((classification, index) => (
                      <ThCentered
                        key={classification.id}
                        className="narrow"
                        sortKey={`classification-${classification.id}`}
                        sorter={sorter}
                        style={{
                          borderLeft:
                            index === 0 &&
                            columnCountBenefit + columnCountStake > 0
                              ? groupBorderStyle
                              : undefined,
                          borderRight:
                            index === columnCountBenefit - 1 ||
                            index === columnCountBenefit + columnCountStake - 1
                              ? groupBorderStyle
                              : undefined,
                        }}
                        sortPredicate={(projectA, projectB) => {
                          const dimensionA = projectA.customDimensions?.find(
                            (cd) => cd.projectDimensionId === classification.id
                          );
                          const dimensionB = projectB.customDimensions?.find(
                            (cd) => cd.projectDimensionId === classification.id
                          );

                          // This is used to force empty/null string to be sorted last
                          const forceLast = 'ÖÖ';

                          return {
                            a: dimensionA?.value ?? forceLast,
                            b: dimensionB?.value ?? forceLast,
                          };
                        }}
                      >
                        <HelpIconCentered>
                          {classification.name}

                          {classification.description && (
                            <HelpIcon
                              showTiny={true}
                              style={{ marginLeft: 5 }}
                              title={classification.name}
                              text={classification.description}
                            />
                          )}
                        </HelpIconCentered>
                      </ThCentered>
                    ))}

                    <Th style={{ width: 20 }} />
                  </tr>
                </thead>

                <tbody>
                  {sortedIdeas.map((idea) => (
                    <Fragment key={idea.id}>
                      <Tr
                        className="main"
                        onSelect={(e) => {
                          if (
                            ['INPUT', 'SELECT'].includes(
                              (e.target as HTMLElement).nodeName
                            )
                          ) {
                            return;
                          }

                          handleSelect(idea);
                        }}
                      >
                        <Td className="narrow">
                          <Checkbox
                            onChange={() => {
                              handleMark(idea);
                            }}
                            id={idea.projectId}
                            name={idea.projectId}
                            value={idea.projectId}
                            checked={selectedIdeas.some(
                              (p) => p.id === idea.id
                            )}
                          />
                        </Td>
                        <TdCentered className="narrow">
                          <IdeaStatus status={idea.projectPhase} />
                        </TdCentered>
                        <Td className="narrow">{idea.externalId}</Td>
                        <Td>
                          <div className="b">{idea.title}</div>
                          <div className="i">{idea.portfolioName}</div>
                        </Td>
                        <TdRight
                          style={{ paddingTop: 10, whiteSpace: 'nowrap' }}
                        >
                          {formatAsCurrency(
                            isProjectEffectRealisationEnabledFlagOn
                              ? idea.budget
                              : idea.cost
                          )}
                        </TdRight>
                        <TdRight
                          style={{ paddingTop: 10, whiteSpace: 'nowrap' }}
                        >
                          {formatAsCurrency(
                            isProjectEffectRealisationEnabledFlagOn
                              ? (idea.totalExpectedBenefit ?? 0) -
                                  (idea.budget ?? 0)
                              : idea.netBenefit
                          )}
                        </TdRight>

                        <TdCentered className="narrow">
                          <Progress
                            width="80px"
                            value={idea.efficiencyInnovation}
                            color={PiosColors.efficiency}
                            backgroundColor={PiosColors.innovation}
                          />
                        </TdCentered>
                        <TdCentered className="narrow">
                          <Progress
                            width="80px"
                            value={idea.internalExternal}
                            color={PiosColors.internal}
                            backgroundColor={PiosColors.external}
                          />
                        </TdCentered>

                        {classifications.length > 0 && (
                          <TdCentered className="narrow b">
                            {formatAsNumber(idea.quota, '', 1)}
                          </TdCentered>
                        )}

                        {hasCalculations && (
                          <TdCentered className="narrow">
                            <HelpIconCentered>
                              {idea.calculation?.name}

                              {idea.calculation?.description && (
                                <HelpIcon
                                  showTiny={true}
                                  style={{ marginLeft: 5 }}
                                  title={idea.calculation?.name}
                                  text={idea.calculation?.description}
                                />
                              )}
                            </HelpIconCentered>
                          </TdCentered>
                        )}

                        {classifications &&
                          classifications?.map((classification, index) => (
                            <TdCentered
                              key={classification.id}
                              className="narrow"
                              style={{
                                borderLeft:
                                  index === 0 &&
                                  columnCountBenefit + columnCountStake > 0
                                    ? groupBorderStyle
                                    : undefined,
                                borderRight:
                                  index === columnCountBenefit - 1 ||
                                  index ===
                                    columnCountBenefit + columnCountStake - 1
                                    ? groupBorderStyle
                                    : undefined,
                              }}
                            >
                              <IdeaClassification
                                idea={
                                  selectedIdea && selectedIdea.id === idea.id
                                    ? selectedIdea
                                    : idea
                                }
                                setIdea={(newIdea) => handleChange(newIdea)}
                                classification={classification}
                                isEditing={
                                  isReviewing &&
                                  selectedIdea?.projectId === idea.id
                                }
                              />
                            </TdCentered>
                          ))}

                        <TdRight>
                          {selectedIdea?.projectId !== idea.id &&
                            hasPermission(Permission.Review, idea) && (
                              <BadgeMini
                                data-tip
                                data-for="tt-project-edit"
                                onClick={() => {
                                  handleSelect(idea);
                                }}
                              >
                                <BsPencil />
                              </BadgeMini>
                            )}
                        </TdRight>
                      </Tr>

                      {selectedIdea?.projectId === idea.id && (
                        <Tr>
                          <Td
                            colSpan={100}
                            style={{
                              paddingBottom: 0,
                              paddingTop: 20,
                              paddingRight: 20,
                              backgroundColor: theme.colors.surfaceVariant,
                            }}
                          >
                            <IdeaListPrioritiseConvertButtons
                              handleSave={handleSave}
                              hasChanges={hasChanges}
                              portfolioId={portfolioId}
                              setPortfolioId={setPortfolioId}
                              classifications={classifications.filter(
                                (classification) =>
                                  classification.displayType ===
                                  DimensionDisplayType.Rating
                              )}
                            />
                          </Td>
                        </Tr>
                      )}
                    </Fragment>
                  ))}
                </tbody>
              </Table>
            </TableResponsiveContainer>

            <Pagination
              currentPage={paginator.page}
              totalCount={ideas.length}
              pageSize={paginator.pageSize}
              handlePageChange={(page) => setPage(page)}
            />
          </>
        )}
      </>
    );
  }
);

IdeaListPrioritise.displayName = 'IdeaListPrioritise';
