import { AdministrateAccount as AdministrateAccountFeature } from 'features/administrate-account';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const AdministrateAccount = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View
      title={`${t('Navigation.Administrate.Administrate')} - ${t(
        'Administrate.Account.Title'
      )}`}
    >
      <AdministrateAccountFeature />
    </View>
  );
};

AdministrateAccount.displayName = 'AdministrateAccount';
