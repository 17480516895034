import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import { BadgeLarge, BadgeSmall } from '@dimatech/shared/lib/components/Badge';
import { HelpIcon } from '@dimatech/shared/lib/components/HelpIcon';
import {
  Button,
  ButtonFooterWithToast,
  ButtonLink,
  ButtonSecondary,
  Buttons,
  Checkbox,
  Input,
  InputNumber,
  Label,
  MonthPicker,
  TextArea,
  YearPicker,
} from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { UnsavedChangesWarning } from '@dimatech/shared/lib/components/modal';
import { Td, Tr } from '@dimatech/shared/lib/components/table';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import {
  Heading1,
  Heading4,
  Heading5,
} from '@dimatech/shared/lib/components/typography';
import {
  CardRow,
  CardTile,
  ViewHeader,
  ViewHeaderActions,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { Theme } from '@dimatech/shared/lib/themes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetDimensionsQuery } from 'api/dimension/dimensionApi';
import { piosActions, selectSelectedProjectIdea } from 'api/piosSlice';
import { useGetPortfolioOverviewQuery } from 'api/portfolio/portfolioOverviewApi';
import {
  useAddEffectRealisationResponsibleMutation,
  useDeleteEffectRealisationResponsibleMutation,
} from 'api/project/effectRealisationResponsibleApi';
import {
  useAddGoalMutation,
  useDeleteGoalMutation,
  useGetGoalsQuery,
  useUpdateGoalMutation,
} from 'api/project/projectGoalApi';
import {
  useAddProjectIdeaMutation,
  useArchiveProjectIdeaMutation,
  useConvertToProjectMutation,
  useDraftProjectIdeaMutation,
  useReadyForReviewProjectIdeaMutation,
  useReadyProjectIdeaMutation,
  useUpdateProjectIdeaMutation,
} from 'api/project/projectIdeaApi';
import {
  useAddManagerMutation,
  useDeleteManagerMutation,
} from 'api/project/projectManagerApi';
import {
  useAddOrderingPartyMutation,
  useDeleteOrderingPartyMutation,
} from 'api/project/projectOrderingPartyApi';
import { GoalEditList } from 'features/project-goal/components/GoalEditList';
import { GoalEditListEffectRealisation } from 'features/project-goal/components/GoalEditListEffectRealisation';
import { useSelectAndNavigate } from 'features/project-idea/useSelectAndNavigate';
import { EffectRealisationResponsibleList } from 'features/project/components/EffectRealisationResponsibleList';
import { ProjectDataTable } from 'features/project/components/ProjectDataTable';
import { ProjectDimensionsEdit } from 'features/project/components/ProjectDimensionsEdit';
import { ProjectDimensionsList } from 'features/project/components/ProjectDimensionsList';
import { ProjectManagersList } from 'features/project/components/ProjectManagersList';
import { ProjectOrderingPartiesList } from 'features/project/components/ProjectOrderingPartiesList';
import { ProjectPeriodEdit } from 'features/project/components/ProjectPeriodEdit';
import { ProjectPeriodList } from 'features/project/components/ProjectPeriodList';
import { ProjectPublishedPubliclyConfirmation } from 'features/project/components/ProjectPublishedPubliclyConfirmation';
import { ProjectPublishedPubliclyIcon } from 'features/project/components/ProjectPublishedPubliclyIcon';
import { ProjectStatusEdit } from 'features/project/components/ProjectStatusEdit';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Permission,
  Phase,
  ProjectDimension,
  ProjectDimensionMutation,
  ProjectGoal,
  ProjectIdea,
  ProjectIdeaAction,
  ProjectPeriod,
} from 'models';
import { nanoid } from 'nanoid';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowLeft, BsCalculator, BsX } from 'react-icons/bs';
import { FaSave } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import {
  calculatePeriodsAndBudgets,
  formatAsCurrency,
  hasPermission,
  isPiosReadOnly,
  isReadOnly,
} from 'utils';
import { mergeDimensions } from 'utils/dimension';
import { IdeaArchiveConfirmation } from './IdeaArchiveConfirmation';
import { IdeaConvertConfirmation } from './IdeaConvertConfirmation';
import { IdeaDraftConfirmation } from './IdeaDraftConfirmation';
import { IdeaReadyConfirmation } from './IdeaReadyConfirmation';
import { IdeaReadyForReviewConfirmation } from './IdeaReadyForReviewConfirmation';
import { IdeaStatusEdit } from './IdeaStatusEdit';

// TODO: Split code
/* eslint-disable max-lines-per-function */

export const IdeaEdit = withTheme(
  ({ theme }: { theme: Theme }): JSX.Element | null => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const selectAndNavigate = useSelectAndNavigate();

    const { accessToken } = useContext(AuthenticationContext);
    const isViewReadOnly = isPiosReadOnly(accessToken);

    const selectedIdea = useAppSelector(selectSelectedProjectIdea);

    const isProjectIdeasReviewersEnabledFlagOn =
      useFlags()[flags.permanent.app.pios.isProjectIdeasReviewersEnabled];
    const isProjectEffectRealisationEnabledFlagOn =
      useFlags()[flags.permanent.app.pios.isProjectEffectRealisationEnabled];
    const isStartAndEndMonthEnabledFlagOn =
      useFlags()[flags.permanent.app.pios.isStartAndEndMonthEnabled];
    const isPublishPubliclyEnabledFlagOn =
      useFlags()[flags.permanent.app.pios.isPublishPubliclyEnabled];

    const [readyForReviewIdea] = useReadyForReviewProjectIdeaMutation();
    const [readyIdea] = useReadyProjectIdeaMutation();
    const [convertToProject] = useConvertToProjectMutation();
    const [archiveIdea] = useArchiveProjectIdeaMutation();
    const [draftIdea] = useDraftProjectIdeaMutation();

    const [addProjectIdea, { isLoading: addPosting, error: addErrors }] =
      useAddProjectIdeaMutation();
    const [updateIdea, { isLoading: updatePosting, error: updateErrors }] =
      useUpdateProjectIdeaMutation();

    const [portfolioId, setPortfolioId] = useState(selectedIdea?.portfolioId);
    const [isChangesSaved, setIsChangesSaved] = useState(false);
    const [isChangingPublishedPublicly, setIsChangingPublishedPublicly] =
      useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [dimensions, setDimensions] = useState<ProjectDimensionMutation[]>();
    const [isValid, setIsValid] = useState(true);

    const [periods, setPeriods] = useState<ProjectPeriod[]>();
    const [isArchiving, setIsArchiving] = useState(false);
    const [isDrafting, setIsDrafting] = useState(false);
    const [isConverting, setIsConverting] = useState(false);
    const [isReadying, setIsReadying] = useState(false);
    const [isReadyingForReview, setIsReadyingForReview] = useState(false);
    const [isUnsavedChangesWarningShown, setIsUnsavedChangesWarningShown] =
      useState(false);
    const [idea, setIdea] = useState<ProjectIdea>();
    const [goals, setGoals] = useState<ProjectGoal[]>([]);
    const [managers, setManagers] = useState<string[]>();
    const [effectRealisationResponsible, setEffectRealisationResponsible] =
      useState<string[]>();
    const [orderingParties, setOrderingParties] = useState<string[]>();

    const [
      isViewLimitedAsEffectRealisationResponsible,
      setIsViewLimitedAsEffectRealisationResponsible,
    ] = useState(false);

    const [addGoal, { isLoading: addGoalPosting }] = useAddGoalMutation();
    const [updateGoal, { isLoading: updateGoalPosting }] =
      useUpdateGoalMutation();
    const [deleteGoal, { isLoading: deleteGoalPosting }] =
      useDeleteGoalMutation();

    const [createManager] = useAddManagerMutation();
    const [deleteManager] = useDeleteManagerMutation();
    const [createEffectRealisationResponsible] =
      useAddEffectRealisationResponsibleMutation();
    const [deleteEffectRealisationResponsible] =
      useDeleteEffectRealisationResponsibleMutation();
    const [createOrderingParty] = useAddOrderingPartyMutation();
    const [deleteOrderingParty] = useDeleteOrderingPartyMutation();

    const { data: customDimensions, isFetching: isFetchingDimensions } =
      useGetDimensionsQuery(
        accessToken.customerId ? accessToken.customerId : skipToken
      );

    const {
      data: portfolio,
      isLoading: isLoadingPortfolio,
      isFetching: isFetchingPortfolio,
    } = useGetPortfolioOverviewQuery(
      accessToken.customerId && accessToken.user?.id && idea?.portfolioId
        ? {
            filter: { portfolioIds: [idea?.portfolioId] },
            _customerId: accessToken.customerId,
            _userId: accessToken.user.id,
          }
        : skipToken
    );

    const {
      data: ideaGoals,
      isLoading: isLoadingGoals,
      isFetching: isFetchingGoals,
    } = useGetGoalsQuery(
      accessToken.customerId && idea?.id
        ? {
            projectId: idea.id,
            _customerId: accessToken.customerId,
          }
        : skipToken
    );

    const isGoalPosting =
      updateGoalPosting || addGoalPosting || deleteGoalPosting;
    const isPosting = updatePosting || addPosting || isGoalPosting;
    const isLoading = isLoadingPortfolio || isFetchingPortfolio;

    const handleConfirmCancel = () => {
      if (hasChanges) {
        setIsUnsavedChangesWarningShown(true);
      } else {
        handleCancel();
      }
    };

    const handleCancel = () => {
      if (!idea?.id) {
        selectAndNavigate(ProjectIdeaAction.Identify);

        return;
      }

      selectAndNavigate(ProjectIdeaAction.View, selectedIdea);
    };

    const handleChange = (idea: ProjectIdea) => {
      setHasChanges(true);

      setIdea({
        ...idea,
        customDimensions: dimensions,
      });
    };

    const handelChangePublishedPublicly = () => {
      if (!idea) {
        return;
      }

      setIsChangingPublishedPublicly(false);
      setHasChanges(true);
      setIdea({
        ...idea,
        isPublishedPublicly: !idea.isPublishedPublicly,
      });
    };

    const handleSave = () => {
      setIsValid(true);

      if (isViewReadOnly || !idea) {
        return;
      }

      if (!idea.title?.trim() || !idea.projectPhase) {
        setIsValid(false);
        return;
      }

      if (idea.startYear && idea.endYear && idea.startYear > idea.endYear) {
        return;
      }

      if (
        goals.some(
          (goal) =>
            !goal.innovationEfficiencyClassification ||
            !goal.internalExternalClassification
        )
      ) {
        setIsValid(false);
        return;
      }

      const originalDimensions = idea.customDimensions ?? [];
      const customDimensions =
        dimensions?.map(
          (dimension) =>
            ({
              projectDimensionId: dimension.projectDimensionId,
              value: dimension.value || null,
            } as ProjectDimension)
        ) ?? [];

      const newIdea = {
        ...idea,
        title: idea.title.trim(),
        description: idea.description?.trim(),
        benefitsAndEffects: idea.benefitsAndEffects?.trim(),
        portfolioName: portfolio?.portfolioName,
        customDimensions: [
          ...customDimensions,
          ...originalDimensions.filter(
            (d) =>
              !customDimensions.find(
                (cd) => cd.projectDimensionId === d.projectDimensionId
              )
          ),
        ],
        periods: periods ? [...periods] : [],
        projectManagers: managers,
        effectRealizationResponsible: effectRealisationResponsible,
        orderingParties,
        isPublishedPublicly:
          idea.projectPhase === Phase.Draft || idea.projectPhase === Phase.Ready
            ? false
            : idea.isPublishedPublicly,
      } as ProjectIdea;

      if (idea.id) {
        updateIdea(newIdea)
          .unwrap()
          .then(() => {
            addUpdateGoals(idea);
            addUpdateManagers(idea);
            addUpdateEffectRealisationResponsible(idea);
            addUpdateOrderingParties(idea);
          })
          .finally(() => {
            setHasChanges(false);
            setIsChangesSaved(true);

            dispatch(piosActions.setSelectedProjectIdea(newIdea));
          });
      } else {
        addProjectIdea({
          ...newIdea,
          goals: [...goals],
        })
          .unwrap()
          .then((result) => {
            setHasChanges(false);
            setIsChangesSaved(true);

            const addedIdea = {
              ...newIdea,
              ...result,
              projectId: result.id,
            };

            selectAndNavigate(ProjectIdeaAction.Edit, addedIdea);
          });
      }
    };

    const addUpdateGoals = (idea: ProjectIdea) => {
      const goalsToAdd = goals.filter((goal) => !goal.id && goal.isDirty);
      const goalsToUpdate = goals.filter((goal) => goal.id && goal.isDirty);
      const goalsToDelete = ideaGoals?.filter(
        (ideaGoal) => !goals.find((goal) => goal.id === ideaGoal.id)
      );

      goalsToAdd.forEach((goal) => {
        addGoal({ ...goal, projectId: idea.id });
      });
      goalsToUpdate.forEach((goal) => {
        updateGoal({ ...goal });
      });
      goalsToDelete?.forEach((goal) => {
        deleteGoal({ ...goal });
      });
    };

    const addUpdateManagers = (idea: ProjectIdea) => {
      const initialManagers = idea?.projectManagers;

      const managersToAdd =
        managers?.filter((email) => !initialManagers?.includes(email)) ?? [];

      const managersToDelete =
        initialManagers?.filter((email) => !managers?.includes(email)) ?? [];

      if (
        idea.portfolioId &&
        idea.id &&
        (managersToAdd.length > 0 || managersToDelete.length > 0)
      ) {
        createManager({
          portfolioId: idea.portfolioId,
          projectId: idea.id,
          projectUsers: managersToAdd,
        })
          .unwrap()
          .then(() => {
            if (managersToDelete.length > 0) {
              deleteManager({
                portfolioId: idea.portfolioId as string,
                projectId: idea.id as string,
                projectUsers: managersToDelete,
              });
            }
          });
      }
    };

    const addUpdateEffectRealisationResponsible = (idea: ProjectIdea) => {
      const initialEffectRealisationResponsible =
        idea.effectRealizationResponsible;

      const effectRealisationResponsibleToAdd =
        effectRealisationResponsible?.filter(
          (email) => !initialEffectRealisationResponsible?.includes(email)
        ) ?? [];

      const effectRealisationResponsibleToDelete =
        initialEffectRealisationResponsible?.filter(
          (email) => !effectRealisationResponsible?.includes(email)
        ) ?? [];

      if (
        idea.portfolioId &&
        idea.id &&
        (effectRealisationResponsibleToAdd.length > 0 ||
          effectRealisationResponsibleToDelete.length > 0)
      ) {
        createEffectRealisationResponsible({
          portfolioId: idea.portfolioId,
          projectId: idea.id,
          projectUsers: effectRealisationResponsibleToAdd,
        })
          .unwrap()
          .then(() => {
            if (effectRealisationResponsibleToDelete.length > 0) {
              deleteEffectRealisationResponsible({
                portfolioId: idea.portfolioId as string,
                projectId: idea.id as string,
                projectUsers: effectRealisationResponsibleToDelete,
              });
            }
          });
      }
    };

    const addUpdateOrderingParties = (idea: ProjectIdea) => {
      const initialOrderingParties = idea?.orderingParties;

      const orderingPartiesToAdd =
        orderingParties?.filter(
          (email) => !initialOrderingParties?.includes(email)
        ) ?? [];

      const orderingPartiesToDelete =
        initialOrderingParties?.filter(
          (email) => !orderingParties?.includes(email)
        ) ?? [];

      if (
        idea.portfolioId &&
        idea.id &&
        (orderingPartiesToAdd.length > 0 || orderingPartiesToDelete.length > 0)
      ) {
        createOrderingParty({
          portfolioId: idea.portfolioId,
          projectId: idea.id,
          projectUsers: orderingPartiesToAdd,
        })
          .unwrap()
          .then(() => {
            if (orderingPartiesToDelete.length > 0) {
              deleteOrderingParty({
                portfolioId: idea.portfolioId as string,
                projectId: idea.id as string,
                projectUsers: orderingPartiesToDelete,
              });
            }
          });
      }
    };

    const handleConvert = (
      status: Phase | 'Project' | 'Archived' | undefined
    ) => {
      if (!idea || !status) {
        return;
      }

      const reselectIdea = (result: ProjectIdea) => {
        selectAndNavigate(ProjectIdeaAction.Edit, { ...idea, ...result });
      };

      switch (status) {
        case 'Archived':
          archiveIdea(idea)
            .unwrap()
            .then((result) => {
              selectAndNavigate(ProjectIdeaAction.Identify, {
                ...idea,
                ...result,
              });
            });
          break;
        case Phase.Draft:
          draftIdea(idea).unwrap().then(reselectIdea);
          break;
        case Phase.Ready:
          readyIdea(idea).unwrap().then(reselectIdea);
          break;
        case Phase.ReadyForReview:
          readyForReviewIdea(idea).unwrap().then(reselectIdea);
          break;
        case 'Project':
          convertToProject({
            idea,
            newPortfolioId: portfolioId,
          })
            .unwrap()
            .then(reselectIdea);
          break;
      }
    };

    const calculatePeriods = (distributeBudget: boolean) => {
      const calculatedPeriods = calculatePeriodsAndBudgets(
        idea,
        periods,
        distributeBudget,
        isStartAndEndMonthEnabledFlagOn
      );

      if (!calculatedPeriods) {
        return;
      }

      setPeriods(calculatedPeriods);
    };

    useEffect(() => {
      if (idea) {
        setIsViewLimitedAsEffectRealisationResponsible(
          !hasPermission(Permission.EditIdea, idea) &&
            hasPermission(Permission.EditEffectRealization, idea)
        );
      }
    }, [idea, accessToken]);

    useEffect(() => {
      calculatePeriods(!periods || periods.length === 0);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idea?.budget, idea?.startYear, idea?.endYear]);

    useEffect(() => {
      if (idea) {
        if (customDimensions) {
          const dimensionMutations = mergeDimensions(
            customDimensions,
            false,
            idea
          );

          setDimensions(dimensionMutations);
        }
      }
    }, [idea, customDimensions]);

    useEffect(() => {
      setGoals(
        ideaGoals?.map((goal) => ({
          ...goal,
          isDirty: false,
          uid: nanoid(6),
        })) ?? []
      );
    }, [ideaGoals]);

    useEffect(() => {
      if (idea?.projectId) {
        setManagers(idea.projectManagers ?? []);
        setEffectRealisationResponsible(
          idea.effectRealizationResponsible ?? []
        );
        setOrderingParties(idea.orderingParties ?? []);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idea?.projectId]);

    useEffect(() => {
      if (selectedIdea) {
        setIdea(selectedIdea);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIdea]);

    useEffect(() => {
      dispatch(piosActions.setHasUnsavedChanges(hasChanges));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasChanges]);

    return idea ? (
      <Style>
        <ViewHeader style={{ marginLeft: 0, marginTop: 0 }}>
          <div>
            <TooltipContent id="tt-navigate-back" text={t('Common.UI.Back')} />
            <BadgeSmall
              data-tip
              data-for="tt-navigate-back"
              onClick={handleConfirmCancel}
              $inverted={true}
              style={{ marginRight: 15 }}
            >
              <BsArrowLeft />
            </BadgeSmall>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Heading1>
              {idea.id ? idea.title : t('FutureProjects.ProjectIdea.Add.Title')}
            </Heading1>
            {portfolio && (
              <ButtonLink
                onClick={() => navigate(`/portfolio/${portfolio.portfolioId}`)}
              >
                {portfolio.portfolioName}
              </ButtonLink>
            )}
          </div>

          <ViewHeaderActions style={{ marginBottom: 0, marginRight: 10 }}>
            <div
              style={{
                display: 'flex',
                gap: 10,
              }}
            >
              <TooltipContent id="tt-cancel" text={t('Common.Form.Cancel')} />
              <TooltipContent id="tt-save" text={t('Common.Form.Save')} />

              <BadgeLarge
                data-tip
                data-for="tt-cancel"
                onClick={handleConfirmCancel}
                $inverted={true}
              >
                <BsX />
              </BadgeLarge>

              <BadgeLarge
                data-tip
                data-for="tt-save"
                onClick={handleSave}
                $inverted={true}
                disabled={isViewReadOnly || isPosting || !hasChanges}
                style={
                  hasChanges
                    ? {
                        backgroundColor: theme.colors.secondary,
                        color: theme.colors.onSecondary,
                      }
                    : undefined
                }
              >
                <FaSave />
              </BadgeLarge>
            </div>
          </ViewHeaderActions>
        </ViewHeader>

        {isValid && (
          <AlertErrors
            error={addErrors ?? updateErrors}
            translationPath="Project.ValidationError"
          />
        )}

        {isUnsavedChangesWarningShown && (
          <UnsavedChangesWarning
            handleContinue={handleCancel}
            handleCancel={() => setIsUnsavedChangesWarningShown(false)}
          />
        )}

        <CardRow>
          <CardTile>
            <Label htmlFor="title">
              {t('Project.Title')}
              <ProjectPublishedPubliclyIcon project={idea} />
            </Label>
            <Input
              autoFocus
              type="text"
              id="title"
              name="title"
              value={idea.title ?? ''}
              invalid={!isValid && !idea.title?.trim()}
              onChange={(e) => {
                handleChange({ ...idea, title: e.currentTarget.value });
              }}
              readOnly={isViewLimitedAsEffectRealisationResponsible}
            />
          </CardTile>
        </CardRow>

        <CardRow style={{ gap: 0 }}>
          <CardTile
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            isLoading={isLoading}
          >
            <TableStyle>
              <tbody>
                <Tr>
                  <Td>
                    <Label htmlFor="externalId">
                      {t('Project.ExternalId')}

                      <HelpIcon
                        showTiny={true}
                        style={{ marginLeft: 7 }}
                        title={t('Project.ExternalId')}
                        text={t('Project.ExternalIdHelp')}
                      />
                    </Label>
                  </Td>
                  <Td>
                    <Input
                      type="text"
                      id="externalId"
                      name="externalId"
                      value={idea.externalId ?? ''}
                      style={{ width: 150 }}
                      onChange={(e) => {
                        handleChange({
                          ...idea,
                          externalId: e.currentTarget.value,
                        });
                      }}
                      readOnly={isViewLimitedAsEffectRealisationResponsible}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Label htmlFor="projectPhase">
                      {t('FutureProjects.ProjectIdea.ProjectIdeaStatus')}
                      <ProjectPublishedPubliclyIcon project={idea} />
                    </Label>
                  </Td>
                  <Td>
                    <IdeaStatusEdit
                      idea={idea}
                      style={{ width: 200 }}
                      handleChange={(status) => {
                        handleChange({
                          ...idea,
                          projectPhase: status,
                        });
                      }}
                      isViewReadOnly={
                        isViewLimitedAsEffectRealisationResponsible
                      }
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Label>{t('Project.Status.Status')}</Label>
                  </Td>
                  <Td>
                    <ProjectStatusEdit
                      project={idea}
                      setProject={(project) => {
                        handleChange(project);
                      }}
                    />
                  </Td>
                </Tr>
              </tbody>
            </TableStyle>
          </CardTile>

          <CardTile
            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            isLoading={isLoading}
          >
            <TableStyle>
              <tbody>
                <Tr>
                  <Td>
                    <Label htmlFor="hasEffectTakeHomePlan">
                      {t('Project.EffectTakeHome.Label')}
                    </Label>
                  </Td>
                  <Td>
                    <Checkbox
                      id="hasEffectTakeHomePlan"
                      name="hasEffectTakeHomePlan"
                      checked={!!idea.hasEffectTakeHomePlan}
                      style={{ marginRight: 7 }}
                      onChange={(e) => {
                        if (isViewLimitedAsEffectRealisationResponsible) {
                          return;
                        }

                        handleChange({
                          ...idea,
                          hasEffectTakeHomePlan: e.currentTarget.checked,
                        });
                      }}
                      readOnly={isViewLimitedAsEffectRealisationResponsible}
                    />
                  </Td>
                </Tr>

                {isPublishPubliclyEnabledFlagOn && (
                  <>
                    <Tr>
                      <Td>
                        <Label htmlFor="isPublishedPublicly">
                          {t('Project.IsPublishedPublicly.Label')}
                        </Label>
                      </Td>
                      <Td>
                        {idea.projectPhase !== Phase.Draft &&
                        idea.projectPhase !== Phase.Ready ? (
                          <Checkbox
                            id="isPublishedPublicly"
                            name="isPublishedPublicly"
                            checked={!!idea.isPublishedPublicly}
                            style={{ marginRight: 7 }}
                            onChange={() => {
                              if (isViewLimitedAsEffectRealisationResponsible) {
                                return;
                              }

                              setIsChangingPublishedPublicly(true);
                            }}
                            readOnly={
                              isViewLimitedAsEffectRealisationResponsible
                            }
                          />
                        ) : (
                          <Checkbox checked={false} disabled={true} />
                        )}
                      </Td>
                    </Tr>
                    {(idea.projectPhase === Phase.Draft ||
                      idea.projectPhase === Phase.Ready) && (
                      <Tr style={{ height: '10px' }}>
                        <Td colSpan={2}>
                          <HelpText>
                            {t('Project.IsPublishedPublicly.HelpText')}
                          </HelpText>
                        </Td>
                      </Tr>
                    )}
                  </>
                )}
              </tbody>
            </TableStyle>
          </CardTile>
        </CardRow>

        {customDimensions && customDimensions.length > 0 && (
          <CardRow style={{ gap: 0 }}>
            {isFetchingDimensions && <LoaderSmall />}

            {isViewLimitedAsEffectRealisationResponsible &&
              !isFetchingDimensions && (
                <ProjectDimensionsList
                  project={idea}
                  customDimensions={dimensions ?? []}
                  isProject={false}
                />
              )}

            {!isViewLimitedAsEffectRealisationResponsible &&
              !isFetchingDimensions && (
                <ProjectDimensionsEdit
                  projectDimensions={dimensions ?? []}
                  setProjectDimensions={setDimensions}
                  setHasChanges={setHasChanges}
                />
              )}
          </CardRow>
        )}

        <CardRow>
          <CardTile style={{ boxShadow: 'none' }}>
            <div>
              <Label htmlFor="description">
                {t('Project.Description')}
                <ProjectPublishedPubliclyIcon project={idea} />
              </Label>
            </div>
            <div>
              <TextArea
                id="description"
                name="description"
                value={idea.description ?? ''}
                onChange={(e) => {
                  handleChange({
                    ...idea,
                    description: e.currentTarget.value,
                  });
                }}
                style={{ height: 150 }}
                readOnly={isViewLimitedAsEffectRealisationResponsible}
              />
            </div>
          </CardTile>

          <CardTile style={{ boxShadow: 'none' }}>
            <div>
              <Label htmlFor="benefitsAndEffects">
                {t('Project.BenefitsAndEffects')}
                <ProjectPublishedPubliclyIcon project={idea} />
              </Label>
            </div>

            <div>
              <TextArea
                id="benefitsAndEffects"
                name="benefitsAndEffects"
                value={idea.benefitsAndEffects ?? ''}
                onChange={(e) => {
                  handleChange({
                    ...idea,
                    benefitsAndEffects: e.currentTarget.value,
                  });
                }}
                style={{ height: 150 }}
                readOnly={isViewLimitedAsEffectRealisationResponsible}
              />
            </div>
          </CardTile>
        </CardRow>

        <CardRow>
          <CardTile>
            {isViewLimitedAsEffectRealisationResponsible && (
              <ProjectPeriodList project={idea} />
            )}

            {!isViewLimitedAsEffectRealisationResponsible && (
              <>
                <Heading4 style={{ marginBottom: 10 }}>
                  {t('Project.Period.Title')}
                </Heading4>
                <div className="field">
                  <div>
                    <Label htmlFor="budget">
                      {t('Project.Budget', {
                        currency: t(
                          `Common.Currency.${accessToken.locale.currency}.Name`
                        ),
                      })}
                      <ProjectPublishedPubliclyIcon project={idea} />
                    </Label>
                    <InputNumber
                      id="budget"
                      name="budget"
                      value={idea.budget ?? ''}
                      onValueChange={({ value }) => {
                        handleChange({
                          ...idea,
                          budget: !isNaN(parseInt(value)) ? +value : undefined,
                        });
                      }}
                    />
                  </div>

                  <div>
                    <Label htmlFor="startYear">{t('Project.StartYear')}</Label>
                    <YearPicker
                      name="startYear"
                      date={idea.startYear?.toString() ?? ''}
                      min={new Date(1987, 0, 0)}
                      max={new Date(2100, 0, 0)}
                      setDate={(date: Date | null) => {
                        handleChange({
                          ...idea,
                          startYear: date ? date.getFullYear() : undefined,
                        });
                      }}
                    />
                  </div>

                  {isStartAndEndMonthEnabledFlagOn && (
                    <div>
                      <Label htmlFor="startMonth">
                        {t('Project.StartMonth')}
                      </Label>
                      <MonthPicker
                        name="startMonth"
                        date={idea.startMonth?.toString() ?? ''}
                        setDate={(date: Date | null) => {
                          handleChange({
                            ...idea,
                            startMonth: date ? date.getMonth() + 1 : undefined,
                          });
                        }}
                      />
                    </div>
                  )}

                  <div>
                    <Label htmlFor="endYear">{t('Project.EndYear')}</Label>
                    <YearPicker
                      name="endYear"
                      min={new Date(1987, 0, 0)}
                      max={new Date(2100, 0, 0)}
                      date={idea.endYear?.toString() ?? ''}
                      setDate={(date: Date | null) => {
                        handleChange({
                          ...idea,
                          endYear: date ? date.getFullYear() : undefined,
                        });
                      }}
                    />
                  </div>

                  {isStartAndEndMonthEnabledFlagOn && (
                    <div>
                      <Label htmlFor="endMonth">{t('Project.EndMonth')}</Label>
                      <MonthPicker
                        name="endMonth"
                        date={idea.endMonth?.toString() ?? ''}
                        setDate={(date: Date | null) => {
                          handleChange({
                            ...idea,
                            endMonth: date ? date.getMonth() + 1 : undefined,
                          });
                        }}
                      />
                    </div>
                  )}

                  <div style={{ alignSelf: 'flex-end', marginBottom: 5 }}>
                    <TooltipContent
                      id="tt-calculate"
                      title={t('Project.Period.CalculateTooltipTitle')}
                      text={t('Project.Period.CalculateTooltip')}
                    />

                    <Button
                      data-tip
                      data-for="tt-calculate"
                      onClick={() => {
                        setHasChanges(true);
                        calculatePeriods(true);
                      }}
                      className="size-xl"
                      style={{ padding: '7px 7px 5px 7px' }}
                    >
                      <BsCalculator />
                    </Button>
                  </div>

                  {isProjectEffectRealisationEnabledFlagOn && (
                    <div className="benefits">
                      <div>
                        <Heading5>
                          {t('ImplementedProjects.ExpectedBenefit', {
                            currency: t(
                              `Common.Currency.${accessToken.locale.currency}.Name`
                            ),
                          })}
                        </Heading5>
                        {formatAsCurrency(
                          goals?.reduce(
                            (total, goal) =>
                              total + (goal.totalExpectedBenefit ?? 0),
                            0
                          )
                        )}
                      </div>
                      <div>
                        <Heading5>
                          {t('ImplementedProjects.ActualBenefit', {
                            currency: t(
                              `Common.Currency.${accessToken.locale.currency}.Name`
                            ),
                          })}
                        </Heading5>
                        {formatAsCurrency(
                          goals?.reduce(
                            (total, goal) =>
                              total + (goal.totalActualBenefit ?? 0),
                            0
                          )
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <ProjectPeriodEdit
                  budget={idea.budget}
                  projectPeriods={periods ?? []}
                  setProjectPeriods={setPeriods}
                  setHasChanges={setHasChanges}
                />
              </>
            )}
          </CardTile>
        </CardRow>

        <CardRow>
          <CardTile isLoading={isLoadingGoals || isFetchingGoals}>
            <Heading4 style={{ marginBottom: 10 }}>
              {isProjectEffectRealisationEnabledFlagOn
                ? `${t('Project.Goals.GoalsAndBenefits')}`
                : `${t('Project.Goals.Goals')}`}
            </Heading4>

            {isProjectEffectRealisationEnabledFlagOn ? (
              <GoalEditListEffectRealisation
                project={idea}
                goals={goals}
                setGoals={setGoals}
                setHasChanges={setHasChanges}
                isValid={isValid}
              />
            ) : (
              <GoalEditList
                project={idea}
                goals={goals}
                setGoals={setGoals}
                setHasChanges={setHasChanges}
                isValid={isValid}
              />
            )}
          </CardTile>
        </CardRow>

        <CardRow>
          <CardTile>
            <ProjectManagersList
              managers={managers}
              setManagers={setManagers}
              setHasChanges={setHasChanges}
              isEditAvailable={!isViewLimitedAsEffectRealisationResponsible}
            />
          </CardTile>

          {isProjectEffectRealisationEnabledFlagOn && (
            <CardTile>
              <EffectRealisationResponsibleList
                effectRealisationResponsible={effectRealisationResponsible}
                setEffectRealisationResponsible={
                  setEffectRealisationResponsible
                }
                setHasChanges={setHasChanges}
                isEditAvailable={!isViewLimitedAsEffectRealisationResponsible}
              />
            </CardTile>
          )}

          <CardTile>
            <ProjectOrderingPartiesList
              orderingParties={orderingParties}
              setOrderingParties={setOrderingParties}
              setHasChanges={setHasChanges}
              isEditAvailable={!isViewLimitedAsEffectRealisationResponsible}
            />
          </CardTile>
        </CardRow>

        <IdeaArchiveConfirmation
          isArchiving={isArchiving}
          handleSave={(isConfirmed: boolean) => {
            if (isConfirmed) {
              handleConvert('Archived');
            }
            setIsArchiving(false);
          }}
        />
        <IdeaConvertConfirmation
          isConverting={isConverting}
          setPortfolioId={setPortfolioId}
          portfolioId={portfolioId}
          handleSave={(isConfirmed: boolean) => {
            if (isConfirmed) {
              handleConvert('Project');
            }
            setIsConverting(false);
          }}
        />
        <IdeaDraftConfirmation
          isDrafting={isDrafting}
          handleSave={(isConfirmed: boolean) => {
            if (isConfirmed) {
              handleConvert(Phase.Draft);
            }
            setIsDrafting(false);
          }}
        />

        <IdeaReadyConfirmation
          isReadying={isReadying}
          handleSave={(isConfirmed: boolean) => {
            if (isConfirmed) {
              handleConvert(Phase.Ready);
            }
            setIsReadying(false);
          }}
        />
        <IdeaReadyForReviewConfirmation
          isReadyingForReview={isReadyingForReview}
          handleSave={(isConfirmed: boolean) => {
            if (isConfirmed) {
              handleConvert(Phase.ReadyForReview);
            }
            setIsReadyingForReview(false);
          }}
        />

        {isPublishPubliclyEnabledFlagOn && isChangingPublishedPublicly && (
          <ProjectPublishedPubliclyConfirmation
            project={idea}
            setIsChangingPublishedPublicly={setIsChangingPublishedPublicly}
            handelChangePublishedPublicly={handelChangePublishedPublicly}
          />
        )}

        <ButtonFooterWithToast
          isSaved={isChangesSaved}
          setIsSaved={setIsChangesSaved}
          style={{ margin: '0 10px' }}
        >
          {!isViewLimitedAsEffectRealisationResponsible && idea.id && (
            <Buttons>
              {isProjectIdeasReviewersEnabledFlagOn &&
                idea.projectPhase !== Phase.ReadyForReview &&
                idea.projectPhase !== Phase.Ready && (
                  <ButtonSecondary
                    onClick={() => setIsReadying(true)}
                    disabled={isReadOnly(idea)}
                  >
                    {t('FutureProjects.ProjectIdea.Ready.Title')}
                  </ButtonSecondary>
                )}

              {idea.projectPhase !== Phase.ReadyForReview && (
                <ButtonSecondary
                  onClick={() => setIsReadyingForReview(true)}
                  disabled={isReadOnly(idea)}
                >
                  {isProjectIdeasReviewersEnabledFlagOn
                    ? t('FutureProjects.ProjectIdea.ReadyForReview.Title')
                    : t('FutureProjects.ProjectIdea.Publish.Title')}
                </ButtonSecondary>
              )}

              {(idea.projectPhase === Phase.Ready ||
                idea.projectPhase === Phase.ReadyForReview) && (
                <ButtonSecondary
                  onClick={() => setIsDrafting(true)}
                  disabled={isReadOnly(idea)}
                >
                  {t('FutureProjects.ProjectIdea.Draft.Title')}
                </ButtonSecondary>
              )}

              <ButtonSecondary
                onClick={() => setIsArchiving(true)}
                disabled={isReadOnly(idea)}
              >
                {t('FutureProjects.ProjectIdea.Archive.Title')}
              </ButtonSecondary>
            </Buttons>
          )}

          <Buttons>
            <ButtonSecondary type="button" onClick={handleConfirmCancel}>
              {t('Common.Form.Cancel')}
            </ButtonSecondary>
            <Button
              type="button"
              disabled={isViewReadOnly || isPosting || !hasChanges}
              onClick={handleSave}
            >
              {t('Common.Form.Save')}
            </Button>
          </Buttons>
        </ButtonFooterWithToast>
      </Style>
    ) : null;
  }
);

IdeaEdit.displayName = 'IdeaEdit';

const TableStyle = styled(ProjectDataTable)`
  > tbody {
    > tr {
      height: 40px;
    }
  }
`;

const Style = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 10px 30px 10px;
  margin: -10px;

  column-gap: 15px;
  row-gap: 15px;

  background-color: ${({ theme }: { theme: Theme }) =>
    theme.colors.surfaceVariant};
  color: ${({ theme }: { theme: Theme }) => theme.colors.onSurfaceVariant};

  label {
    font-weight: bold;
  }

  .field {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;

    > div {
      padding: 0 0 10px 0;
      button {
        margin-right: 20px;
      }

      &.benefits {
        display: flex;

        > div {
          padding: 0 20px 10px 0;
        }
      }
    }
  }
`;

const HelpText = styled.div`
  font-weight: normal;
  font-style: italic;
  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.s};
`;
