import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { HelpIcon } from '@dimatech/shared/lib/components/HelpIcon';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { Td, Tr } from '@dimatech/shared/lib/components/table';
import { Heading5 } from '@dimatech/shared/lib/components/typography';
import { CardRow, CardTile } from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { Color, Theme } from '@dimatech/shared/lib/themes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetDimensionsQuery } from 'api/dimension/dimensionApi';
import { piosActions, selectSelectedProjectIdea } from 'api/piosSlice';
import { DebugInfo } from 'components/DebugInfo';
import { ProjectStatus } from 'components/ProjectStatus';
import { ProjectDocumentList } from 'features/project-document/components/ProjectDocumentList';
import { GoalList } from 'features/project-goal/components/GoalList';
import { GoalListEffectRealisation } from 'features/project-goal/components/GoalListEffectRealisation';
import { NoteList } from 'features/project-note/components/NoteList';
import { EffectRealisationResponsibleList } from 'features/project/components/EffectRealisationResponsibleList';
import { ProjectDataTable } from 'features/project/components/ProjectDataTable';
import { ProjectDimensionsList } from 'features/project/components/ProjectDimensionsList';
import { ProjectManagersList } from 'features/project/components/ProjectManagersList';
import { ProjectNationalTagList } from 'features/project/components/ProjectNationalTagList';
import { ProjectOrderingPartiesList } from 'features/project/components/ProjectOrderingPartiesList';
import { ProjectPeriodList } from 'features/project/components/ProjectPeriodList';
import { ProjectPublishedPubliclyIcon } from 'features/project/components/ProjectPublishedPubliclyIcon';
import { ProjectTagList } from 'features/project/components/ProjectTagList';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  DimensionType,
  Permission,
  Phase,
  ProjectIdea,
  ProjectIdeaAction,
  Tag,
} from 'models';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCheckCircleFill, BsCircle } from 'react-icons/bs';
import styled from 'styled-components';
import { hasPermission, isReadOnly } from 'utils';
import { IdeaHeader } from './IdeaHeader';
import { IdeaStatus } from './IdeaStatus';

// eslint-disable-next-line max-lines-per-function
export const IdeaView = ({
  showEditButtons = true,
  backAction,
  ideas,
}: {
  showEditButtons?: boolean;
  backAction?: ProjectIdeaAction;
  ideas?: ProjectIdea[];
}): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { accessToken } = useContext(AuthenticationContext);

  const selectedIdea = useAppSelector(selectSelectedProjectIdea);

  const isViewLimitedAsEffectRealisationResponsible =
    selectedIdea &&
    !hasPermission(Permission.EditIdea, selectedIdea) &&
    hasPermission(Permission.EditEffectRealization, selectedIdea);

  const isTagsEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isTagsEnabled];
  const isProjectEffectRealisationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectEffectRealisationEnabled];
  const isPublishPubliclyEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isPublishPubliclyEnabled];
  const isAreasOfOperationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isAreasOfOperationEnabled];

  const { data: customDimensions, isFetching: isFetchingDimensions } =
    useGetDimensionsQuery(
      accessToken.customerId ? accessToken.customerId : skipToken
    );

  useEffect(() => {
    dispatch(piosActions.setHasUnsavedChanges(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const idea = ideas?.find((idea) => idea.id === selectedIdea?.id);
    if (idea) {
      dispatch(
        piosActions.setSelectedProjectIdea({
          ...idea,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ideas]);

  return selectedIdea ? (
    <Style>
      <IdeaHeader showEditButtons={showEditButtons} backAction={backAction} />

      <DebugInfo idea={selectedIdea} />

      <CardRow style={{ gap: 0 }}>
        <CardTile
          style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        >
          <ProjectDataTable>
            <tbody>
              <Tr>
                <Td>
                  <div style={{ display: 'flex' }}>
                    {t('Project.ExternalId')}
                    <HelpIcon
                      showTiny={true}
                      style={{ marginLeft: 7 }}
                      title={t('Project.ExternalId')}
                      text={t('Project.ExternalIdHelp')}
                    />
                  </div>
                </Td>
                <Td>{selectedIdea.externalId ?? '-'}</Td>
              </Tr>
              <Tr>
                <Td>
                  <div style={{ display: 'flex' }}>
                    {t('FutureProjects.ProjectIdea.ProjectIdeaStatus')}
                    <ProjectPublishedPubliclyIcon project={selectedIdea} />
                  </div>
                </Td>
                <Td>
                  <IdeaStatus
                    status={selectedIdea.projectPhase}
                    isLabelShown={true}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>{t('Project.Status.Status')}</Td>
                <Td>
                  <ProjectStatus
                    status={selectedIdea.projectStatus}
                    isLabelShown={true}
                  />
                </Td>
              </Tr>
            </tbody>
          </ProjectDataTable>
        </CardTile>

        <CardTile style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
          <ProjectDataTable>
            <tbody>
              <Tr>
                <Td>{t('Project.EffectTakeHome.Label')}</Td>
                <Td>
                  {!selectedIdea.hasEffectTakeHomePlan && (
                    <div>
                      <BsCircle />
                      {t('Common.UI.No')}
                    </div>
                  )}

                  {selectedIdea.hasEffectTakeHomePlan && (
                    <div>
                      <BsCheckCircleFill
                        style={{ color: Color.CaribbeanGreenLight }}
                      />
                      {t('Common.UI.Yes')}
                    </div>
                  )}
                </Td>
              </Tr>

              {isPublishPubliclyEnabledFlagOn && (
                <Tr>
                  <Td>{t('Project.IsPublishedPublicly.Label')}</Td>
                  <Td>
                    {(!selectedIdea.isPublishedPublicly ||
                      selectedIdea.projectPhase === Phase.Draft ||
                      selectedIdea.projectPhase === Phase.Ready) && (
                      <div>
                        <BsCircle />
                        {t('Common.UI.No')}
                      </div>
                    )}

                    {selectedIdea.isPublishedPublicly &&
                      selectedIdea.projectPhase !== Phase.Draft &&
                      selectedIdea.projectPhase !== Phase.Ready && (
                        <div>
                          <BsCheckCircleFill
                            style={{ color: Color.CaribbeanGreenLight }}
                          />
                          {t('Common.UI.Yes')}
                        </div>
                      )}
                  </Td>
                </Tr>
              )}
            </tbody>
          </ProjectDataTable>
        </CardTile>
      </CardRow>

      {customDimensions &&
        customDimensions.filter(
          (dimension) =>
            dimension.projectPhases?.includes(Phase.Draft) &&
            dimension.type === DimensionType.Data
        ).length > 0 && (
          <CardRow style={{ gap: 0 }}>
            {isFetchingDimensions && <LoaderSmall />}

            {!isFetchingDimensions && (
              <ProjectDimensionsList
                project={selectedIdea}
                customDimensions={customDimensions}
                isProject={false}
              />
            )}
          </CardRow>
        )}

      {selectedIdea.id && (
        <CardRow>
          {isPublishPubliclyEnabledFlagOn && isAreasOfOperationEnabledFlagOn && (
            <CardTile>
              <ProjectNationalTagList
                canEdit={
                  !isViewLimitedAsEffectRealisationResponsible &&
                  !isReadOnly(selectedIdea)
                }
                project={selectedIdea}
              />
            </CardTile>
          )}

          {isTagsEnabledFlagOn && (
            <CardTile>
              <ProjectTagList
                canEdit={
                  !isViewLimitedAsEffectRealisationResponsible &&
                  !isReadOnly(selectedIdea) &&
                  showEditButtons
                }
                tags={selectedIdea.tags}
                projectId={selectedIdea.id}
                portfolioId={selectedIdea.portfolioId as string}
                handleChangeTag={(tags: Tag[]) =>
                  dispatch(
                    piosActions.setSelectedProjectIdea({
                      ...selectedIdea,
                      tags,
                    })
                  )
                }
              />
            </CardTile>
          )}
        </CardRow>
      )}

      <CardRow>
        <CardTile>
          <Heading5 style={{ display: 'flex' }}>
            {t('Project.Description')}
            <ProjectPublishedPubliclyIcon project={selectedIdea} />
          </Heading5>
          <div>{selectedIdea.description ?? '-'}</div>
        </CardTile>

        <CardTile>
          <Heading5 style={{ display: 'flex' }}>
            {t('Project.BenefitsAndEffects')}
            <ProjectPublishedPubliclyIcon project={selectedIdea} />
          </Heading5>
          <div>{selectedIdea.benefitsAndEffects ?? '-'}</div>
        </CardTile>
      </CardRow>

      <CardRow>
        <CardTile>
          <ProjectPeriodList project={selectedIdea} />
        </CardTile>
      </CardRow>

      {selectedIdea.id && (
        <>
          <CardRow>
            <CardTile>
              {isProjectEffectRealisationEnabledFlagOn ? (
                <GoalListEffectRealisation projectId={selectedIdea.id} />
              ) : (
                <GoalList projectId={selectedIdea.id} />
              )}
            </CardTile>
          </CardRow>

          <CardRow>
            <CardTile>
              <ProjectManagersList managers={selectedIdea.projectManagers} />
            </CardTile>

            {isProjectEffectRealisationEnabledFlagOn && (
              <CardTile>
                <EffectRealisationResponsibleList
                  effectRealisationResponsible={
                    selectedIdea.effectRealizationResponsible
                  }
                />
              </CardTile>
            )}

            <CardTile>
              <ProjectOrderingPartiesList
                orderingParties={selectedIdea.orderingParties}
              />
            </CardTile>
          </CardRow>

          <CardRow>
            <CardTile>
              <NoteList
                projectId={selectedIdea.id}
                canEdit={
                  !isViewLimitedAsEffectRealisationResponsible &&
                  !isReadOnly(selectedIdea) &&
                  showEditButtons
                }
              />
            </CardTile>

            <CardTile>
              <ProjectDocumentList
                projectId={selectedIdea.id}
                canEdit={!isReadOnly(selectedIdea) && showEditButtons}
              />
            </CardTile>
          </CardRow>
        </>
      )}
    </Style>
  ) : null;
};

IdeaView.displayName = 'IdeaView';

const Style = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 10px 30px 10px;
  margin: -10px;

  column-gap: 15px;
  row-gap: 15px;

  background-color: ${({ theme }: { theme: Theme }) =>
    theme.colors.surfaceVariant};
  color: ${({ theme }: { theme: Theme }) => theme.colors.onSurfaceVariant};
`;
