import { OrganisationHistory as OrganisationHistoryFeature } from '@dimatech/features-core/lib/features/history';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const OrganisationHistory = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('Administrate.History.Title')}>
      <OrganisationHistoryFeature />
    </View>
  );
};

OrganisationHistory.displayName = 'OrganisationHistory';
