import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { BadgeLarge, BadgeMini } from '@dimatech/shared/lib/components/Badge';
import {
  Button,
  ButtonSecondary,
  Buttons,
  Checkbox,
  Label,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import {
  useDeleteProjectMutation,
  useMoveProjectMutation,
} from 'api/project/projectApi';
import { Project } from 'models';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BsArrowsMove,
  BsClockHistory,
  BsLightbulb,
  BsPencil,
  BsTrash,
} from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { isPiosReadOnly, isProjectOngoing, isReadOnly } from 'utils';
import { SelectPortfolio } from './SelectPortfolio';

/* eslint-disable max-lines-per-function */

export const ProjectActions = ({
  project,
  showLargeIcons = false,
  userCanEditPortfolio,
}: {
  project: Project;
  showLargeIcons?: boolean;
  userCanEditPortfolio: boolean;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { accessToken } = useContext(AuthenticationContext);
  const isViewReadOnly = isPiosReadOnly(accessToken);
  const translationPath = accessToken.isBaseLicense ? 'Copy' : 'MoveOrCopy';

  const [deleteProject] = useDeleteProjectMutation();
  const [moveProject] = useMoveProjectMutation();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const [isCopying, setIsCopying] = useState(false);
  const [newPortfolioId, setNewPortfolioId] = useState<string>();

  const handleDelete = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (isViewReadOnly) {
      return;
    }

    deleteProject({ ...project });

    setIsDeleting(false);
    if (!accessToken.isBaseLicense) {
      navigate(`/portfolio/${project.portfolioId}`);
    } else {
      navigate(`/projects`);
    }
  };

  const handleConfirmDelete = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setIsDeleting(show);
  };

  const handleMove = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (isViewReadOnly || !newPortfolioId) {
      return;
    }

    moveProject({
      portfolioId: project.portfolioId as string,
      projectId: project.projectId as string,
      newPortfolioId,
      copy: accessToken.isBaseLicense ? true : isCopying,
    })
      .unwrap()
      .then((result) => {
        if (isCopying || accessToken.isBaseLicense) {
          // If creating a copy, navigate to the edit page
          navigate(`/project/${newPortfolioId}/${result.projectId}/edit`);
        } else {
          navigate(`/project/${newPortfolioId}/${result.projectId}`);
        }
      })
      .finally(() => setIsMoving(false));
  };

  const handleConfirmMove = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setIsMoving(show);
  };

  const handleEdit = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    navigate(`/project/${project.portfolioId}/${project.projectId}/edit`);
  };

  const handleViewHistory = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    navigate(`/project/${project.portfolioId}/${project.projectId}/history`);
  };

  const handleViewProjectIdea = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    navigate(`/project/${project.portfolioId}/${project.projectId}/idea`);
  };

  useEffect(() => {
    if (accessToken.isBaseLicense) {
      setNewPortfolioId(project.portfolioId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken.isBaseLicense]);

  // Do not show the buttons if project is read only
  if (isReadOnly(project)) {
    return null;
  }

  return (
    <>
      {isDeleting && (
        <Modal
          title={t('Project.Delete.ConfirmTitle')}
          handleKeyEsc={(e) => handleConfirmDelete(e, false)}
        >
          <div>{t('Project.Delete.ConfirmText')}</div>

          <AlertWarning style={{ margin: '10px 0' }}>
            {t(
              `Project.Delete.${
                accessToken.isBaseLicense ? 'BaseLicenseWarning' : 'Warning'
              }`
            )}
          </AlertWarning>

          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={(e) => handleConfirmDelete(e, false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>
            <Button
              type="button"
              onClick={(e) => handleDelete(e)}
              disabled={isViewReadOnly}
            >
              {t('Common.UI.Yes')}
            </Button>
          </Buttons>
        </Modal>
      )}

      {isMoving && (
        <Modal
          title={t(`Project.${translationPath}.Title`)}
          handleKeyEnter={handleMove}
          handleKeyEsc={(e) => handleConfirmMove(e, false)}
        >
          <div>{t(`Project.${translationPath}.Text`)}</div>

          {!accessToken.isBaseLicense && (
            <>
              <SelectPortfolio
                setPortfolio={(portfolio) => setNewPortfolioId(portfolio?.id)}
                style={{ marginTop: 20 }}
              />

              <div>
                <Label htmlFor="copy">
                  <Checkbox
                    name="copy"
                    id="copy"
                    checked={isCopying}
                    onChange={(e) => setIsCopying(e.currentTarget.checked)}
                    style={{ marginRight: 10 }}
                  />
                  {t('Project.MoveOrCopy.Copy')}
                </Label>
              </div>
            </>
          )}

          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={(e) => handleConfirmMove(e, false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>
            <Button
              type="button"
              onClick={handleMove}
              disabled={
                ((project.portfolioId === newPortfolioId && !isCopying) ||
                  !newPortfolioId) &&
                !accessToken.isBaseLicense
              }
            >
              {t('Common.Form.Save')}
            </Button>
          </Buttons>
        </Modal>
      )}

      <TooltipContent
        id="tt-project-edit"
        title={t('Project.Edit.TooltipTitle')}
        text={t('Project.Edit.Tooltip')}
      />
      <TooltipContent
        id="tt-project-delete"
        title={t('Project.Delete.TooltipTitle')}
        text={t('Project.Delete.Tooltip')}
      />
      <TooltipContent
        id="tt-project-move"
        title={t(`Project.${translationPath}.TooltipTitle`)}
        text={t(`Project.${translationPath}.Tooltip`)}
      />

      <TooltipContent
        id="tt-project-history"
        title={t('Project.History.TooltipTitle')}
        text={t('Project.History.Tooltip')}
      />

      {!accessToken.isBaseLicense &&
        project.projectPhase &&
        isProjectOngoing(project.projectPhase) && (
          <TooltipContent
            id="tt-project-idea"
            title={t('Project.Idea.TooltipTitle')}
            text={t('Project.Idea.Tooltip')}
          />
        )}
      {!showLargeIcons && (
        <div
          style={{
            display: 'flex',
            gap: 10,
          }}
        >
          {userCanEditPortfolio && (
            <BadgeMini
              data-tip
              data-for="tt-project-move"
              onClick={(e) => handleConfirmMove(e, true)}
              $inverted={true}
            >
              <BsArrowsMove />
            </BadgeMini>
          )}

          <BadgeMini
            data-tip
            data-for="tt-project-edit"
            onClick={(e) => handleEdit(e)}
            $inverted={true}
          >
            <BsPencil />
          </BadgeMini>

          {userCanEditPortfolio && (
            <BadgeMini
              data-tip
              data-for="tt-project-delete"
              onClick={(e) => handleConfirmDelete(e, true)}
              $inverted={true}
            >
              <BsTrash />
            </BadgeMini>
          )}
        </div>
      )}

      {showLargeIcons && (
        <div
          style={{
            display: 'flex',
            gap: 10,
          }}
        >
          {!accessToken.isBaseLicense &&
            project.projectPhase &&
            isProjectOngoing(project.projectPhase) && (
              <BadgeLarge
                data-tip
                data-for="tt-project-idea"
                onClick={(e) => handleViewProjectIdea(e)}
                $inverted={true}
              >
                <BsLightbulb />
              </BadgeLarge>
            )}

          <BadgeLarge
            data-tip
            data-for="tt-project-history"
            onClick={(e) => handleViewHistory(e)}
            $inverted={true}
          >
            <BsClockHistory />
          </BadgeLarge>

          {userCanEditPortfolio && (
            <BadgeLarge
              data-tip
              data-for="tt-project-move"
              onClick={(e) => handleConfirmMove(e, true)}
              $inverted={true}
            >
              <BsArrowsMove />
            </BadgeLarge>
          )}

          <BadgeLarge
            data-tip
            data-for="tt-project-edit"
            onClick={(e) => handleEdit(e)}
            $inverted={true}
          >
            <BsPencil />
          </BadgeLarge>

          {userCanEditPortfolio && (
            <BadgeLarge
              data-tip
              data-for="tt-project-delete"
              onClick={(e) => handleConfirmDelete(e, true)}
              $inverted={true}
            >
              <BsTrash />
            </BadgeLarge>
          )}
        </div>
      )}
    </>
  );
};

ProjectActions.displayName = 'ProjectActions';
