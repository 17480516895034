import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  NavigationHeader,
  NavigationItem,
  NavigationSeparatorSlim,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CommonRoles, View } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsBriefcase, BsClipboardData, BsSearch } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';
import { isPortfolioEditAvailable } from 'utils';

export const NavigationOngoing = ({
  isReader,
  defaultView,
}: {
  isReader: boolean;
  defaultView: View;
}): JSX.Element => {
  const { accessToken } = useContext(AuthenticationContext);
  const { t } = useTranslation();
  const location = useLocation();

  const isGlobalAdmin = accessToken.isInRole(CommonRoles.GlobalAdmin);
  const isProjectManager = accessToken.isInRole(CommonRoles.PiosProjectManager);
  const isEffectRealisationResponsible = accessToken.isInRole(
    CommonRoles.PiosEffectRealizationResponsible
  );
  const isProjectOrderingParty = accessToken.isInRole(
    CommonRoles.PiosOrderingParty
  );

  const isStructuredNavigationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isStructuredNavigationEnabled];

  return (
    <>
      {isStructuredNavigationEnabledFlagOn && (
        <>
          <NavigationSeparatorSlim />

          <NavigationHeader>{t('Navigation.OngoingProjects')}</NavigationHeader>
        </>
      )}

      {isPortfolioEditAvailable(accessToken) && (
        <NavigationItem
          $selected={
            location.pathname.startsWith('/myportfolio') ||
            (location.pathname === '/' && defaultView === View.MyPortfolios)
          }
          $disabled={accessToken.isBaseLicense}
        >
          <BsBriefcase />
          <Link to="/myportfolios">{t('Navigation.MyPortfolios')}</Link>
        </NavigationItem>
      )}

      <>
        {isReader && (
          <NavigationItem
            $selected={
              location.pathname.startsWith('/portfolio') ||
              location.pathname.startsWith('/portfolio/') ||
              (location.pathname === '/' && defaultView === View.Portfolios)
            }
            $disabled={accessToken.isBaseLicense}
          >
            <BsBriefcase />
            <Link to="/portfolios">{t('Navigation.Portfolios')}</Link>
          </NavigationItem>
        )}

        {(isProjectManager ||
          isEffectRealisationResponsible ||
          isProjectOrderingParty ||
          isGlobalAdmin) && (
          <NavigationItem
            $selected={
              location.pathname === '/myprojects' ||
              (location.pathname === '/' && defaultView === View.MyProjects)
            }
            $disabled={accessToken.isBaseLicense}
          >
            <BsClipboardData />
            <Link to="/myprojects">{t('Navigation.MyProjects')}</Link>
          </NavigationItem>
        )}

        {isReader && (
          <>
            <NavigationItem
              $selected={
                location.pathname === '/projects' ||
                location.pathname.startsWith('/projects/') ||
                (location.pathname === '/' && defaultView === View.Projects)
              }
            >
              <BsClipboardData />
              <Link to="/projects">{t('Navigation.Projects')}</Link>
            </NavigationItem>

            <NavigationItem
              $selected={location.pathname === '/search'}
              $disabled={accessToken.isBaseLicense}
            >
              <BsSearch />
              <Link to="/search">{t('Navigation.Search')}</Link>
            </NavigationItem>
          </>
        )}
      </>
    </>
  );
};

NavigationOngoing.displayName = 'NavigationOngoing';
