import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { Td, Tr } from '@dimatech/shared/lib/components/table';
import { Heading5 } from '@dimatech/shared/lib/components/typography';
import { CardRow, CardTile } from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { Color } from '@dimatech/shared/lib/themes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetDimensionsQuery } from 'api/dimension/dimensionApi';
import { ProjectPhase } from 'components/ProjectPhase';
import { ProjectStatus } from 'components/ProjectStatus';
import { ProjectDocumentList } from 'features/project-document/components/ProjectDocumentList';
import { GoalList } from 'features/project-goal/components/GoalList';
import { GoalListEffectRealisation } from 'features/project-goal/components/GoalListEffectRealisation';
import { NoteList } from 'features/project-note/components/NoteList';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Permission, Phase, Project } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCheckCircleFill, BsCircle } from 'react-icons/bs';
import styled from 'styled-components';
import { hasPermission, isReadOnly } from 'utils';
import { EffectRealisationResponsibleList } from './EffectRealisationResponsibleList';
import { ProjectDataTable } from './ProjectDataTable';
import { ProjectDimensionsList } from './ProjectDimensionsList';
import { ProjectManagersList } from './ProjectManagersList';
import { ProjectNationalTagList } from './ProjectNationalTagList';
import { ProjectOrderingPartiesList } from './ProjectOrderingPartiesList';
import { ProjectPeriodList } from './ProjectPeriodList';
import { ProjectPublishedPubliclyIcon } from './ProjectPublishedPubliclyIcon';
import { ProjectTagList } from './ProjectTagList';

export const ProjectView = ({
  project,
}: {
  project?: Project;
}): JSX.Element | null => {
  const { accessToken } = useContext(AuthenticationContext);

  const isTagsEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isTagsEnabled];
  const isPublicApiEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isPublicApiEnabled];
  const isProjectEffectRealisationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectEffectRealisationEnabled];
  const isPublishPubliclyEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isPublishPubliclyEnabled];
  const isAreasOfOperationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isAreasOfOperationEnabled];

  const { t } = useTranslation();

  const { data: customDimensions, isFetching: isFetchingDimensions } =
    useGetDimensionsQuery(
      accessToken.customerId ? accessToken.customerId : skipToken
    );

  const isViewLimitedAsEffectRealisationResponsible =
    project &&
    !hasPermission(Permission.Edit, project) &&
    hasPermission(Permission.EditEffectRealization, project);

  if (!project) {
    return null;
  }

  return (
    <Style>
      <CardRow style={{ gap: 0 }}>
        <CardTile
          style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        >
          <ProjectDataTable>
            <tbody>
              <Tr>
                <Td>{t('Project.ExternalId')}</Td>
                <Td>{project.externalId ?? '-'}</Td>
              </Tr>
              <Tr>
                <Td>
                  <div style={{ display: 'flex' }}>
                    {t('Project.Phase.Phase')}
                    <ProjectPublishedPubliclyIcon project={project} />
                  </div>
                </Td>
                <Td>
                  {project.projectPhase ? (
                    <ProjectPhase phase={project.projectPhase} />
                  ) : (
                    '-'
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td>{t('Project.Status.Status')}</Td>
                <Td>
                  <ProjectStatus
                    status={project.projectStatus}
                    isLabelShown={true}
                  />
                </Td>
              </Tr>
            </tbody>
          </ProjectDataTable>
        </CardTile>

        <CardTile style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
          <ProjectDataTable>
            <tbody>
              <Tr>
                <Td>{t('Project.EffectTakeHome.Label')}</Td>
                <Td>
                  {!project.hasEffectTakeHomePlan && (
                    <div>
                      <BsCircle />
                      {t('Common.UI.No')}
                    </div>
                  )}

                  {project.hasEffectTakeHomePlan && (
                    <div>
                      <BsCheckCircleFill
                        style={{ color: Color.CaribbeanGreenLight }}
                      />
                      {t('Common.UI.Yes')}
                    </div>
                  )}
                </Td>
              </Tr>

              {isPublicApiEnabledFlagOn && (
                <Tr>
                  <Td>{t('Project.IsReadyForExport.Label')}</Td>
                  <Td>
                    {!project.isReadyForExport && (
                      <div>
                        <BsCircle />
                        {t('Common.UI.No')}
                      </div>
                    )}

                    {project.isReadyForExport && (
                      <div>
                        <BsCheckCircleFill
                          style={{ color: Color.CaribbeanGreenLight }}
                        />
                        {t('Common.UI.Yes')}
                      </div>
                    )}
                  </Td>
                </Tr>
              )}

              {isPublishPubliclyEnabledFlagOn && (
                <Tr>
                  <Td>{t('Project.IsPublishedPublicly.Label')}</Td>
                  <Td>
                    {(!project.isPublishedPublicly ||
                      project.projectPhase === Phase.Draft ||
                      project.projectPhase === Phase.Ready) && (
                      <div>
                        <BsCircle />
                        {t('Common.UI.No')}
                      </div>
                    )}

                    {project.isPublishedPublicly &&
                      project.projectPhase !== Phase.Draft &&
                      project.projectPhase !== Phase.Ready && (
                        <div>
                          <BsCheckCircleFill
                            style={{ color: Color.CaribbeanGreenLight }}
                          />
                          {t('Common.UI.Yes')}
                        </div>
                      )}
                  </Td>
                </Tr>
              )}
            </tbody>
          </ProjectDataTable>
        </CardTile>
      </CardRow>

      {customDimensions && (
        <CardRow style={{ gap: 0 }}>
          {isFetchingDimensions && <LoaderSmall />}

          {!isFetchingDimensions && (
            <ProjectDimensionsList
              project={project}
              customDimensions={customDimensions}
              isProject={true}
            />
          )}
        </CardRow>
      )}
      {project.id && (
        <CardRow>
          {isPublishPubliclyEnabledFlagOn && isAreasOfOperationEnabledFlagOn && (
            <CardTile>
              <ProjectNationalTagList
                canEdit={
                  !isViewLimitedAsEffectRealisationResponsible &&
                  !isReadOnly(project)
                }
                project={project}
              />
            </CardTile>
          )}

          {isTagsEnabledFlagOn && (
            <CardTile>
              <ProjectTagList
                canEdit={
                  !isViewLimitedAsEffectRealisationResponsible &&
                  !isReadOnly(project)
                }
                tags={project.tags}
                projectId={project.id}
                portfolioId={project.portfolioId as string}
              />
            </CardTile>
          )}
        </CardRow>
      )}
      <CardRow>
        <CardTile>
          <Heading5 style={{ display: 'flex' }}>
            {t('Project.Description')}
            <ProjectPublishedPubliclyIcon project={project} />
          </Heading5>
          <div>{project.description ?? '-'}</div>
        </CardTile>

        <CardTile>
          <Heading5 style={{ display: 'flex' }}>
            {t('Project.BenefitsAndEffects')}
            <ProjectPublishedPubliclyIcon project={project} />
          </Heading5>
          <div>{project.benefitsAndEffects ?? '-'}</div>
        </CardTile>
      </CardRow>

      <CardRow>
        <CardTile>
          <ProjectPeriodList project={project} />
        </CardTile>
      </CardRow>

      {project.id && (
        <>
          <CardRow>
            <CardTile>
              {isProjectEffectRealisationEnabledFlagOn ? (
                <GoalListEffectRealisation
                  projectId={project.id}
                  projectPhase={project.projectPhase}
                />
              ) : (
                <GoalList projectId={project.id} />
              )}
            </CardTile>
          </CardRow>

          <CardRow>
            <CardTile>
              <ProjectManagersList managers={project.projectManagers} />
            </CardTile>

            {isProjectEffectRealisationEnabledFlagOn && (
              <CardTile>
                <EffectRealisationResponsibleList
                  effectRealisationResponsible={
                    project.effectRealizationResponsible
                  }
                />
              </CardTile>
            )}

            <CardTile>
              <ProjectOrderingPartiesList
                orderingParties={project.orderingParties}
              />
            </CardTile>
          </CardRow>

          <CardRow>
            <CardTile>
              <NoteList
                projectId={project.id}
                canEdit={
                  !isViewLimitedAsEffectRealisationResponsible &&
                  !isReadOnly(project)
                }
              />
            </CardTile>

            <CardTile>
              <ProjectDocumentList
                projectId={project.id}
                canEdit={!isReadOnly(project)}
              />
            </CardTile>
          </CardRow>
        </>
      )}
    </Style>
  );
};

ProjectView.displayName = 'ProjectView';

const Style = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;

  column-gap: 15px;
  row-gap: 15px;
`;
