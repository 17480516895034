import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectFilter } from 'api/piosSlice';
import { useGetPortfoliosOverviewByPeriodQuery } from 'api/portfolio/portfolioOverviewApi';
import { BudgetAndBalanceOverTime } from 'components/BudgetAndBalanceOverTime';
import { useAppSelector } from 'hooks';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const PortfolioBudgetAndBalanceOverTime = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const filter = useAppSelector(selectFilter);
  const { id = '' } = useParams();

  const { data, isFetching } = useGetPortfoliosOverviewByPeriodQuery(
    accessToken.customerId && accessToken.user?.id && id
      ? {
          filter: {
            ...filter,
            period: undefined,
            portfolioIds: [id],
          },
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  if (data && data.length === 0) {
    return null;
  }

  return (
    <Card
      helpTextTitle={t('Portfolio.BudgetAndBalanceOverTime.Title')}
      helpText={<Trans i18nKey={'Portfolio.BudgetAndBalanceOverTime.Help'} />}
      canBeSavedAsPng={true}
      style={{ paddingTop: 10 }}
    >
      <CardBody isLoading={isFetching}>
        {data && (
          <BudgetAndBalanceOverTime
            overviewByPeriod={data}
            name={t('Portfolio.BudgetAndBalanceOverTime.Title')}
          />
        )}
      </CardBody>
    </Card>
  );
};

PortfolioBudgetAndBalanceOverTime.displayName =
  'PortfolioBudgetAndBalanceOverTime';
