import { PortfolioHistory as PortfolioHistoryFeature } from 'features/portfolio/PortfolioHistory';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const PortfolioHistory = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View
      title={`${t('Portfolio.Title')} - ${t('Portfolio.History.TooltipTitle')}`}
    >
      <PortfolioHistoryFeature />
    </View>
  );
};

PortfolioHistory.displayName = 'PortfolioHistory';
