import { Chart } from '@dimatech/features-core/lib/components/Chart';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Theme } from '@dimatech/shared/lib/themes';
import { trackEvent } from '@dimatech/shared/lib/tracking';
import { formatAsPercent } from '@dimatech/shared/lib/utils';
import { OverviewDetails } from 'components/OverviewDetails';
import { OverviewScatterChartTooltip } from 'components/OverviewScatterChartTooltip';
import { EventType, Overview } from 'models';
import { CSSProperties, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CartesianGrid,
  Label,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from 'recharts';
import { withTheme } from 'styled-components';

export const BudgetAndBalanceScatterChart = withTheme(
  ({
    overviews,
    tooltip,
    name,
    theme,
    style,
  }: {
    overviews: Overview[];
    tooltip: { title: string };
    name: string;
    theme: Theme;
    style?: CSSProperties;
  }): JSX.Element => {
    const { t } = useTranslation();
    const { accessToken } = useContext(AuthenticationContext);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const chartRef = useRef<any>();
    const [showDetailsFor, setShowDetailsFor] = useState<Overview>();

    const handleClick = (item: Overview) => {
      setShowDetailsFor(item);

      chartRef.current.setState({
        isTooltipActive: false,
      });

      trackEvent(EventType.ChartClick, {
        name,
        x: item.efficiencyInnovation,
        y: item.internalExternal,
      });
    };

    return (
      <>
        <Chart style={style}>
          <ScatterChart ref={chartRef}>
            <CartesianGrid
              stroke={theme.colors.chart.gridStroke}
              strokeWidth={0.5}
            />

            <XAxis
              type="number"
              dataKey="efficiencyInnovation"
              name={t('Project.EfficiencyInnovation')}
              domain={[0, 100]}
              tickCount={5}
              tickLine={false}
              tickFormatter={(value) => formatAsPercent(value, '0', 0)}
              stroke={theme.colors.chart.axisStroke}
            >
              <Label
                value={t('Project.EfficiencyInnovation') as string}
                position="insideBottom"
                offset={-5}
                fill={theme.colors.onSurface}
              />
            </XAxis>

            <YAxis
              type="number"
              dataKey="internalExternal"
              name={t('Project.InternalExternal')}
              domain={[0, 100]}
              tickCount={5}
              tickLine={false}
              tickFormatter={(value) => formatAsPercent(value, '0', 0)}
              stroke={theme.colors.chart.axisStroke}
            >
              <Label
                value={t('Project.InternalExternal') as string}
                offset={15}
                position="insideLeft"
                angle={-90}
                fill={theme.colors.onSurface}
              />
            </YAxis>

            <ZAxis
              type="number"
              dataKey="budget"
              name={
                t('Project.Budget', {
                  currency: t(
                    `Common.Currency.${accessToken.locale.currency}.Name`
                  ),
                }) as string
              }
              range={[100, 1000]}
              domain={[0, 100]}
            />

            {overviews.map((item, index) => {
              return (
                <Scatter
                  onClick={() => handleClick(item)}
                  style={{ cursor: 'pointer' }}
                  key={index}
                  data={[item]}
                  name={item.label}
                  fill={
                    theme.colors.chart.colors[
                      index % theme.colors.chart.colors.length
                    ]
                  }
                  opacity={0.8}
                />
              );
            })}

            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              content={
                <OverviewScatterChartTooltip
                  data={overviews}
                  title={tooltip.title}
                />
              }
            />
          </ScatterChart>
        </Chart>

        {showDetailsFor && (
          <OverviewDetails
            data={overviews}
            showDetailsFor={showDetailsFor}
            setShowDetailsFor={setShowDetailsFor}
            title={tooltip.title}
          />
        )}
      </>
    );
  }
);

BudgetAndBalanceScatterChart.displayName = 'BudgetAndBalanceScatterChart';
