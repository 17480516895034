import { trackEvent } from '@dimatech/shared/lib/tracking';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { filterToProjectQuery } from 'api/utils';
import axios from 'axios';
import { config } from 'config';
import { saveAs } from 'file-saver';
import { EventType, PiosFilter } from 'models';
import { RootState } from 'store';

const initialState: {
  isPosting: boolean;
  error?: string | null;
} = {
  isPosting: false,
  error: null,
};

export const getProjectsExportRaw = createAsyncThunk(
  'overview/projects/export/raw',
  async ({ filter, name }: { filter: PiosFilter; name: string }) => {
    const url = `overview/projects/export/raw`;
    const filters = filterToProjectQuery(filter);
    return axios({
      url: `${config.apiUrl.product.pios}${url}?${filters}`,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const fileName = `${name}.xlsx`;

      const file = new File([response.data], fileName);

      saveAs(file, fileName);

      trackEvent(EventType.ReportExport, { name });
    });
  }
);

export const {
  reducer: projectsExportRawReducer,
  actions: projectsExportRawActions,
} = createSlice({
  name: 'overview/projects/export/raw',
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build
      .addCase(getProjectsExportRaw.fulfilled, (state) => {
        state.isPosting = false;
      })
      .addCase(getProjectsExportRaw.pending, (state) => {
        state.isPosting = true;
        state.error = null;
      })
      .addCase(getProjectsExportRaw.rejected, (state, action) => {
        state.isPosting = false;
        state.error = action.error.name;
      });
  },
});

export const selectProjectsExportRawContentError = (
  state: RootState
): string | null | undefined => state.projectsExportRaw.error;

export const selectProjectsExportRawIsPosting = (state: RootState): boolean =>
  state.projectsExportRaw.isPosting;
