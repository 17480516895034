import { MultiSelect } from '@dimatech/shared/lib/components/form';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useGetPhaseText } from 'hooks/useGetPhaseText';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Node, Phase } from 'models';
import { useTranslation } from 'react-i18next';

export const SelectPhases = ({
  phases,
  setPhases,
  inverted = false,
}: {
  phases?: string[];
  setPhases: (phases: Phase[]) => void;
  inverted?: boolean;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const getPhaseText = useGetPhaseText();

  const isProjectEffectRealisationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectEffectRealisationEnabled];

  const nodes: Node<Phase>[] = Object.keys(Phase)
    .filter(
      // Filter out effect realisation phases if effect realisation flag is off
      (key) =>
        isProjectEffectRealisationEnabledFlagOn ||
        (key !== Phase.FinishedEffectRealizationStarted &&
          key !== Phase.FinishedEffectRealizationConcluded)
    )
    .map((value) => ({
      id: value,
      name: getPhaseText({
        value: value as Phase,
      }),
      parentId: null,
      payload: value as Phase,
    }));

  const handleChange = (selectedNodes: Node<Phase>[]) => {
    setPhases(selectedNodes.map((node) => node.payload));
  };

  return (
    <>
      {nodes && (
        <MultiSelect
          inverted={inverted}
          style={{
            minWidth: isProjectEffectRealisationEnabledFlagOn ? 330 : 150,
          }}
          placeholder={t('Project.Phase.AllPhases')}
          options={nodes}
          setSelected={(selectedNodes) => {
            handleChange(selectedNodes);
          }}
          selected={nodes.filter((node) => phases?.includes(node.id))}
        />
      )}
    </>
  );
};

SelectPhases.displayName = 'SelectPhases';
