import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { ButtonSecondary, Buttons } from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { Tab, Tabs } from '@dimatech/shared/lib/components/tab';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
  ViewHeaderActions,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectFilter } from 'api/piosSlice';
import {
  getProjectsExportRaw,
  selectProjectsExportRawIsPosting,
} from 'api/project/projectExportRawSlice';
import {
  getProjectsExport,
  selectProjectsExportIsPosting,
} from 'api/project/projectExportSlice';
import { useGetProjectsOverviewQuery } from 'api/project/projectOverviewApi';
import { SelectExtendedFilters } from 'components/SelectExtendedFilters';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { OverviewTabIndex } from 'models';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFileExcel } from 'react-icons/bs';
import { FaRegFileExcel } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProjectsBudgetAndBalance } from './components/ProjectsBudgetAndBalance';
import { ProjectsBudgetAndBalanceOverTime } from './components/ProjectsBudgetAndBalanceOverTime';
import { ProjectsKeyStatistics } from './components/ProjectsKeyStatistics';
import { ProjectsList } from './components/ProjectsList';
import { ProjectsTagTreeTable } from './components/ProjectsTagTreeTable';
import { ProjectsTimeline } from './components/ProjectsTimeline';

export const Projects = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { accessToken } = useContext(AuthenticationContext);
  const filter = useAppSelector(selectFilter);

  const location = useLocation();
  const navigate = useNavigate();

  const isTagsEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isTagsEnabled];

  const isExportRegularPosting = useAppSelector(selectProjectsExportIsPosting);
  const isExportRawPosting = useAppSelector(selectProjectsExportRawIsPosting);
  const isExportPosting = isExportRegularPosting || isExportRawPosting;

  const [showSelectExportType, setShowSelectExportType] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    location.pathname.endsWith('balance-over-time')
      ? OverviewTabIndex.BalanceOverTime
      : location.pathname.endsWith('balance')
      ? OverviewTabIndex.Balance
      : location.pathname.endsWith('tag')
      ? OverviewTabIndex.Tag
      : location.pathname.endsWith('table')
      ? OverviewTabIndex.Table
      : location.pathname.endsWith('timeline')
      ? OverviewTabIndex.Timeline
      : OverviewTabIndex.Summary
  );

  const { data: projectsOverview } = useGetProjectsOverviewQuery(
    accessToken.customerId && accessToken.user?.id
      ? {
          filter,
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const hasData =
    projectsOverview?.projects && projectsOverview.projects.length > 0;

  const handleSelectTab = (index: number) => {
    setSelectedTabIndex(index);

    navigate(
      `/projects/${
        index === OverviewTabIndex.BalanceOverTime
          ? 'balance-over-time'
          : index === OverviewTabIndex.Balance
          ? 'balance'
          : index === OverviewTabIndex.Tag
          ? 'tag'
          : index === OverviewTabIndex.Table
          ? 'table'
          : index === OverviewTabIndex.Timeline
          ? 'timeline'
          : 'summary'
      }`,
      {
        state: { from: location.pathname },
      }
    );
  };

  const handleExportDownload = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(
      getProjectsExport({
        filter,
        name: t('Projects.Title'),
      })
    );
  };

  const handleExportRawDownload = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(
      getProjectsExportRaw({
        filter,
        name: t('Projects.Title'),
      })
    );
  };

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Projects.Title')}</Heading1>

        {hasData && (
          <ViewHeaderActions>
            <LinkWithTooltip
              handleClick={() =>
                !isExportPosting && setShowSelectExportType(true)
              }
              title={t('Projects.Export.Download.TooltipTitle')}
              tooltipTitle={t('Projects.Export.Download.TooltipTitle')}
              tooltip={t('Projects.Export.Download.Tooltip')}
              icon={isExportPosting ? <LoaderSmall /> : <FaRegFileExcel />}
              isInverted={true}
              isDisabled={isExportPosting}
            />
          </ViewHeaderActions>
        )}
      </ViewHeader>

      <Card>
        <CardBody>
          <SelectExtendedFilters
            isTagFilterShown={true}
            isPhaseFilterShown={true}
            isStatusFilterShown={true}
            isResetFilterShown={true}
            isCustomDimensionFiltersShown={true}
            isProjectReadyForExportFilterShown={true}
            isProjectPublishedPubliclyFilterShown={true}
            isProjectEffectTakeHomeFilterShown={true}
            isPortfolioFilterShown={true}
            isPeriodMonthFilterShown={true}
          />
        </CardBody>
      </Card>

      {projectsOverview?.projects && projectsOverview.projects.length === 0 && (
        <Card>
          <CardBody>{t('Projects.NoProjects')}</CardBody>
        </Card>
      )}

      {projectsOverview?.projects && projectsOverview.projects.length > 0 && (
        <Tabs>
          <Tab
            isSelected={selectedTabIndex === OverviewTabIndex.Summary}
            handleSelect={() => handleSelectTab(OverviewTabIndex.Summary)}
          >
            {t('Projects.KeyStatistics.Title')}
          </Tab>
          <Tab
            isSelected={selectedTabIndex === OverviewTabIndex.Balance}
            handleSelect={() => handleSelectTab(OverviewTabIndex.Balance)}
          >
            {t('Projects.BudgetAndBalance.Title')}
          </Tab>
          <Tab
            isSelected={selectedTabIndex === OverviewTabIndex.BalanceOverTime}
            handleSelect={() =>
              handleSelectTab(OverviewTabIndex.BalanceOverTime)
            }
          >
            {t('Projects.BudgetAndBalanceOverTime.Title')}
          </Tab>
          {isTagsEnabledFlagOn && (
            <Tab
              isSelected={selectedTabIndex === OverviewTabIndex.Tag}
              handleSelect={() => handleSelectTab(OverviewTabIndex.Tag)}
            >
              {t('Projects.TagTable.Title')}
            </Tab>
          )}
          <Tab
            isSelected={selectedTabIndex === OverviewTabIndex.Timeline}
            handleSelect={() => handleSelectTab(OverviewTabIndex.Timeline)}
          >
            {t('Projects.Timeline.Title')}
          </Tab>
          <Tab
            isSelected={selectedTabIndex === OverviewTabIndex.Table}
            handleSelect={() => handleSelectTab(OverviewTabIndex.Table)}
          >
            {t('Projects.List.Title')}
          </Tab>
        </Tabs>
      )}

      {selectedTabIndex === OverviewTabIndex.Summary && (
        <ProjectsKeyStatistics />
      )}

      {selectedTabIndex === OverviewTabIndex.Balance && (
        <ProjectsBudgetAndBalance />
      )}

      {selectedTabIndex === OverviewTabIndex.BalanceOverTime && (
        <ProjectsBudgetAndBalanceOverTime />
      )}

      {selectedTabIndex === OverviewTabIndex.Tag && isTagsEnabledFlagOn && (
        <ProjectsTagTreeTable />
      )}

      {selectedTabIndex === OverviewTabIndex.Timeline && <ProjectsTimeline />}

      {selectedTabIndex !== OverviewTabIndex.Timeline && <ProjectsList />}

      {showSelectExportType && (
        <Modal
          title={t('Projects.Export.Download.TooltipTitle')}
          handleKeyEsc={() => setShowSelectExportType(false)}
        >
          <div>{t('Projects.Export.Download.Text')}</div>

          <LinkWithTooltip
            title={t('Projects.Export.Download.Download')}
            tooltipTitle={t('Projects.Export.Download.TooltipTitle')}
            tooltip={t('Projects.Export.Download.Download')}
            handleClick={(e) => !isExportPosting && handleExportDownload(e)}
            isDisabled={isExportPosting}
            icon={isExportRegularPosting ? <LoaderSmall /> : <BsFileExcel />}
            style={{ margin: '15px 0' }}
          />

          <LinkWithTooltip
            title={t('Projects.Export.Download.DownloadRaw')}
            tooltipTitle={t('Projects.Export.Download.TooltipTitle')}
            tooltip={t('Projects.Export.Download.DownloadRaw')}
            handleClick={(e) => !isExportPosting && handleExportRawDownload(e)}
            isDisabled={isExportPosting}
            icon={isExportRawPosting ? <LoaderSmall /> : <BsFileExcel />}
          />

          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={() => setShowSelectExportType(false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>
          </Buttons>
        </Modal>
      )}
    </>
  );
};

Projects.displayName = 'Projects';
