import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { HeatmapConfigTooltip } from '@dimatech/shared/lib/components/heatmap';
import { getPercentRemainder } from '@dimatech/shared/lib/utils';
import { BudgetAndBalanceHeatmap } from 'components/BudgetAndBalanceHeatmap';
import { Overview, PortfolioOverview } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export const PortfoliosCompareHeatmap = ({
  selectedPortfolios,
  name,
}: {
  selectedPortfolios: PortfolioOverview[];
  name: string;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const tooltip: HeatmapConfigTooltip = {
    showDetails: true,
    title: t('Portfolio.Portfolios'),
    labelZ: t('Portfolio.TotalBudget', {
      currency: t(`Common.Currency.${accessToken.locale.currency}.Name`),
    }),
    labelX: t('Project.EfficiencyInnovation'),
    labelY: t('Project.InternalExternal'),
  };

  const overviews = selectedPortfolios.map(
    (portfolio): Overview => ({
      id: portfolio.portfolioId,
      externalId: portfolio.portfolioExternalId,
      label: portfolio.portfolioName,
      entityName: portfolio.entityName,
      portfolioId: portfolio.portfolioId,
      internalExternal: getPercentRemainder(portfolio.internalExternal),
      efficiencyInnovation: getPercentRemainder(portfolio.efficiencyInnovation),
      budget: portfolio.totalBudget === 0 ? undefined : portfolio.totalBudget,
    })
  );

  if (!overviews) {
    return null;
  }

  return (
    <BudgetAndBalanceHeatmap
      overviews={overviews}
      tooltip={tooltip}
      cellSizePixels={16}
      name={name}
    />
  );
};

PortfoliosCompareHeatmap.displayName = 'PortfoliosCompareHeatmap';
