import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { HeatmapConfigTooltip } from '@dimatech/shared/lib/components/heatmap';
import {
  Card,
  CardBody,
  CardFocused,
  CardFocusedBody,
} from '@dimatech/shared/lib/components/workspace';
import { getPercentRemainder } from '@dimatech/shared/lib/utils';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectFilter } from 'api/piosSlice';
import { useGetPortfoliosOverviewQuery } from 'api/portfolio/portfolioOverviewApi';
import { BudgetAndBalanceHeatmap } from 'components/BudgetAndBalanceHeatmap';
import { BudgetAndBalanceScatterChart } from 'components/BudgetAndBalanceScatterChart';
import { useAppSelector } from 'hooks';
import { Overview } from 'models';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const PortfoliosBudgetAndBalance = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const filter = useAppSelector(selectFilter);

  const [overviews, setOverviews] = useState<Overview[]>();

  const { data, isFetching } = useGetPortfoliosOverviewQuery(
    accessToken.customerId && accessToken.user?.id
      ? {
          filter,
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const tooltip: HeatmapConfigTooltip = {
    showDetails: true,
    title: t('Portfolio.Portfolios'),
    labelZ: t('Portfolio.TotalBudget', {
      currency: t(`Common.Currency.${accessToken.locale.currency}.Name`),
    }),
    labelX: t('Project.EfficiencyInnovation'),
    labelY: t('Project.InternalExternal'),
  };

  useEffect(() => {
    if (data) {
      const overviews = data.portfolios.map((portfolio): Overview => {
        return {
          label: portfolio.portfolioName,
          entityName: portfolio.entityName,
          portfolioId: portfolio.portfolioId,
          internalExternal: getPercentRemainder(portfolio.internalExternal),
          efficiencyInnovation: getPercentRemainder(
            portfolio.efficiencyInnovation
          ),
          budget:
            portfolio.totalBudget === 0 ? undefined : portfolio.totalBudget,
        };
      });

      setOverviews(overviews);
    }
  }, [data]);

  if (data && data.portfolios.length === 0) {
    return null;
  }

  return (
    <Card style={{ paddingTop: 10 }}>
      <CardBody isLoading={isFetching}>
        {overviews && (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-around',
            }}
          >
            <CardFocused
              helpTextTitle={t('Portfolios.BudgetAndBalance.Title')}
              helpText={
                <Trans
                  i18nKey={'Portfolios.BudgetAndBalance.ScatterChart.Help'}
                />
              }
              canBeSavedAsPng={true}
            >
              <CardFocusedBody style={{ paddingTop: 10 }}>
                <BudgetAndBalanceScatterChart
                  overviews={overviews}
                  tooltip={{ title: t('Portfolios.Title') }}
                  name={t('Portfolios.BudgetAndBalance.Title')}
                  style={{ width: 450, height: 450 }}
                />
              </CardFocusedBody>
            </CardFocused>

            <CardFocused
              helpTextTitle={t('Portfolios.BudgetAndBalance.Title')}
              helpText={
                <Trans i18nKey={'Portfolios.BudgetAndBalance.Heatmap.Help'} />
              }
              canBeSavedAsPng={true}
            >
              <CardFocusedBody style={{ paddingTop: 10 }}>
                <BudgetAndBalanceHeatmap
                  overviews={overviews}
                  tooltip={tooltip}
                  name={t('Portfolios.BudgetAndBalance.Title')}
                />
              </CardFocusedBody>
            </CardFocused>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

PortfoliosBudgetAndBalance.displayName = 'PortfoliosBudgetAndBalance';
