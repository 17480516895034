import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import {
  NavigationHeader,
  NavigationItem,
  NavigationSeparatorSlim,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CommonRoles } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BsBriefcase,
  BsCardList,
  BsChat,
  BsDiagram3,
  BsEnvelopeFill,
  BsGear,
  BsPeople,
  BsUiRadios,
} from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';

export const NavigationAdministrate = (): JSX.Element => {
  const { accessToken } = useContext(AuthenticationContext);
  const { t } = useTranslation();
  const location = useLocation();

  const isGlobalAdmin = accessToken.isInRole(CommonRoles.GlobalAdmin);
  const isResearcher = accessToken.isInRole(CommonRoles.Researcher);
  const isDemoUser = accessToken.isInRole(CommonRoles.DemoUser);

  const isCustomerAccountAdmin =
    accessToken.customerId &&
    (isGlobalAdmin || accessToken.isInRole(CommonRoles.CustomerAccountAdmin));

  const isAdmin =
    accessToken.customerId &&
    (isCustomerAccountAdmin ||
      isGlobalAdmin ||
      accessToken.isInRole(CommonRoles.CustomerAdmin));

  const isUserMessagesForCustomersEnabled =
    useFlags()[flags.permanent.shared.isUserMessagesForCustomersEnabled];
  const isFeatureFlagsEditEnabled =
    useFlags()[flags.permanent.shared.isFeatureFlagsEditEnabled];

  return (
    <>
      {(isAdmin || isResearcher || isDemoUser) && (
        <>
          <NavigationSeparatorSlim />

          <NavigationHeader>
            {t('Navigation.Administrate.Administrate')}
          </NavigationHeader>

          <NavigationItem
            $selected={location.pathname === '/administrate/organisation'}
            $disabled={accessToken.isBaseLicense}
          >
            <BsDiagram3 />
            <Link to="/administrate/organisation">
              {t('Navigation.Administrate.Organisation')}
            </Link>
          </NavigationItem>
        </>
      )}

      {(isAdmin || isResearcher || isDemoUser) && (
        <>
          <NavigationItem
            $selected={location.pathname === '/administrate/portfolios'}
            $disabled={accessToken.isBaseLicense}
          >
            <BsBriefcase />
            <Link to="/administrate/portfolios">
              {t('Navigation.Administrate.Portfolios')}
            </Link>
          </NavigationItem>
        </>
      )}

      {isCustomerAccountAdmin && (
        <>
          <NavigationItem
            $selected={location.pathname.startsWith('/administrate/users')}
          >
            <BsPeople />
            <Link to="/administrate/users">
              {t('Navigation.Administrate.Users')}
            </Link>
          </NavigationItem>

          <NavigationItem
            $selected={location.pathname === '/administrate/message-template'}
            $disabled={accessToken.isBaseLicense}
          >
            <BsEnvelopeFill />
            <Link to="/administrate/message-template">
              {t('Navigation.Administrate.MessageTemplates')}
            </Link>
          </NavigationItem>

          <NavigationItem
            $selected={
              location.pathname === '/administrate/custom-dimensions' ||
              location.pathname === '/administrate/custom-classifications' ||
              location.pathname === '/administrate/custom-calculations' ||
              location.pathname === '/administrate/custom-tags'
            }
            $disabled={accessToken.isBaseLicense}
          >
            <BsCardList />
            <Link to="/administrate/custom-dimensions">
              {t('Navigation.Administrate.CustomDimensions')}
            </Link>
          </NavigationItem>

          {isUserMessagesForCustomersEnabled && (
            <NavigationItem
              $selected={location.pathname === '/administrate/messages'}
              $disabled={accessToken.isBaseLicense}
            >
              <BsChat />
              <Link to="/administrate/messages">
                {t('Navigation.Administrate.Messages')}
              </Link>
            </NavigationItem>
          )}

          <NavigationItem
            $selected={location.pathname === '/administrate/account'}
          >
            <BsGear />
            <Link to="/administrate/account">
              {t('Navigation.Administrate.Account')}
            </Link>
          </NavigationItem>

          {isFeatureFlagsEditEnabled && (
            <NavigationItem
              $selected={location.pathname.startsWith('/administrate/features')}
              $disabled={accessToken.isBaseLicense}
            >
              <BsUiRadios />
              <Link to="/administrate/features">
                {t('Navigation.Administrate.FeatureFlag')}
              </Link>
            </NavigationItem>
          )}
        </>
      )}
    </>
  );
};

NavigationAdministrate.displayName = 'NavigationAdministrate';
