import { useGetEntitiesQuery } from '@dimatech/features-core/lib/api/entity/entityApi';
import { Administrators } from '@dimatech/features-core/lib/features/administrate-users/Administrators';
import { Viewers } from '@dimatech/features-core/lib/features/administrate-users/Viewers';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Tab, Tabs } from '@dimatech/shared/lib/components/tab';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { SortDirection } from '@dimatech/shared/lib/models';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { searchPortfolios } from 'api/portfolio/portfolioSearchSlice';
import { useAppDispatch } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { PortfolioOwners } from './components/PortfolioOwners';
import { ProjectEffectRealisationResponsible } from './components/ProjectEffectRealisationResponsible';
import { ProjectManagers } from './components/ProjectManagers';
import { ProjectOrderingParties } from './components/ProjectOrderingParties';
import { Reviewers } from './components/Reviewers';

enum TabIndexes {
  PortfolioOwner = 0,
  CustomerAdmin = 1,
  Reader = 2,
  PiosReviewer = 3,
  PiosProjectManager = 4,
  PiosEffectRealizationResponsible = 5,
  PiosOrderingParty = 6,
}

export const AdministrateUsers = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [selectedTabIndex, setSelectedTabIndex] = useState(
    location.pathname.endsWith('customer-admin')
      ? TabIndexes.CustomerAdmin
      : location.pathname.endsWith('reader')
      ? TabIndexes.Reader
      : location.pathname.endsWith('reviewer')
      ? TabIndexes.PiosReviewer
      : location.pathname.endsWith('project-manager')
      ? TabIndexes.PiosProjectManager
      : location.pathname.endsWith('effect-realisation-responsible')
      ? TabIndexes.PiosEffectRealizationResponsible
      : location.pathname.endsWith('ordering-party')
      ? TabIndexes.PiosOrderingParty
      : TabIndexes.PortfolioOwner
  );

  const { data: entities, isLoading } = useGetEntitiesQuery(
    accessToken.customerId && accessToken.user?.id
      ? { _customerId: accessToken.customerId, _userId: accessToken.user?.id }
      : skipToken
  );

  const rootEntityId = entities?.find((e) => !e.parentId);

  const isViewersEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isViewersEnabled];
  const isProjectIdeasReviewersEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectIdeasReviewersEnabled];
  const isProjectEffectRealisationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectEffectRealisationEnabled];

  const handleSelectTab = (index: number) => {
    setSelectedTabIndex(index);

    navigate(
      `/administrate/users/${
        index === TabIndexes.CustomerAdmin
          ? 'customer-admin'
          : index === TabIndexes.Reader
          ? 'reader'
          : index === TabIndexes.PiosReviewer
          ? 'reviewer'
          : index === TabIndexes.PiosProjectManager
          ? 'project-manager'
          : index === TabIndexes.PiosEffectRealizationResponsible
          ? 'effect-realisation-responsible'
          : index === TabIndexes.PiosOrderingParty
          ? 'ordering-party'
          : 'portfolio-owner'
      }`
    );
  };

  const handleNavigateSearch = (e: React.SyntheticEvent, id: string) => {
    e.stopPropagation();

    dispatch(
      searchPortfolios({
        entityIds: [id],
        paginator: {
          page: 1,
          pageSize: 50,
          orderBy: 'portfolioName',
          orderDirection: SortDirection.Asc,
        },
      })
    );

    navigate('/search', {
      state: { id },
    });
  };

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Administrate.Users.Title')}</Heading1>
      </ViewHeader>

      <Tabs>
        <Tab
          isSelected={selectedTabIndex === TabIndexes.PortfolioOwner}
          handleSelect={() => handleSelectTab(TabIndexes.PortfolioOwner)}
        >
          {t('UserRoles.PortfolioOwner.Title')}
        </Tab>

        {!accessToken.isBaseLicense && (
          <Tab
            isSelected={selectedTabIndex === TabIndexes.CustomerAdmin}
            handleSelect={() => handleSelectTab(TabIndexes.CustomerAdmin)}
          >
            {t('UserRoles.CustomerAdmin.Title')}
          </Tab>
        )}

        {isViewersEnabledFlagOn && (
          <Tab
            isSelected={selectedTabIndex === TabIndexes.Reader}
            handleSelect={() => handleSelectTab(TabIndexes.Reader)}
          >
            {t('UserRoles.Reader.Title')}
          </Tab>
        )}
        {isProjectIdeasReviewersEnabledFlagOn && !accessToken.isBaseLicense && (
          <Tab
            isSelected={selectedTabIndex === TabIndexes.PiosReviewer}
            handleSelect={() => handleSelectTab(TabIndexes.PiosReviewer)}
          >
            {t('UserRoles.PiosReviewer.Title')}
          </Tab>
        )}

        {!accessToken.isBaseLicense && (
          <Tab
            isSelected={selectedTabIndex === TabIndexes.PiosProjectManager}
            handleSelect={() => handleSelectTab(TabIndexes.PiosProjectManager)}
          >
            {t('UserRoles.PiosProjectManager.Title')}
          </Tab>
        )}

        {isProjectEffectRealisationEnabledFlagOn && !accessToken.isBaseLicense && (
          <Tab
            isSelected={
              selectedTabIndex === TabIndexes.PiosEffectRealizationResponsible
            }
            handleSelect={() =>
              handleSelectTab(TabIndexes.PiosEffectRealizationResponsible)
            }
          >
            {t('UserRoles.PiosEffectRealizationResponsible.Title')}
          </Tab>
        )}

        {!accessToken.isBaseLicense && (
          <Tab
            isSelected={selectedTabIndex === TabIndexes.PiosOrderingParty}
            handleSelect={() => handleSelectTab(TabIndexes.PiosOrderingParty)}
          >
            {t('UserRoles.PiosOrderingParty.Title')}
          </Tab>
        )}
      </Tabs>

      <Card>
        <CardBody>
          {selectedTabIndex === TabIndexes.PortfolioOwner && (
            <PortfolioOwners />
          )}

          {selectedTabIndex === TabIndexes.CustomerAdmin && (
            <Administrators
              handleNavigateSearch={handleNavigateSearch}
              isLoading={isLoading}
              entities={entities}
            />
          )}

          {selectedTabIndex === TabIndexes.Reader && (
            <>
              {!accessToken.isBaseLicense ? (
                <Viewers
                  handleNavigateSearch={handleNavigateSearch}
                  isLoading={isLoading}
                  entities={entities}
                />
              ) : (
                <Viewers
                  isEntityFilterShown={false}
                  rootEntityId={rootEntityId?.id}
                />
              )}
            </>
          )}

          {selectedTabIndex === TabIndexes.PiosReviewer && (
            <Reviewers
              handleNavigateSearch={handleNavigateSearch}
              isLoading={isLoading}
              entities={entities}
            />
          )}

          {selectedTabIndex === TabIndexes.PiosProjectManager && (
            <ProjectManagers />
          )}

          {selectedTabIndex === TabIndexes.PiosEffectRealizationResponsible && (
            <ProjectEffectRealisationResponsible />
          )}

          {selectedTabIndex === TabIndexes.PiosOrderingParty && (
            <ProjectOrderingParties />
          )}
        </CardBody>
      </Card>
    </>
  );
};

AdministrateUsers.displayName = 'AdministrateUsers';
