import { nameOf } from '@dimatech/shared/lib/utils';
import { HistoryLogChangeType, } from '../models/history';
import { getMetaData } from './history';
export var parseOrganisationHistoryChange = function (history, t) {
    var metaData = getMetaData(history);
    var organisationMetaData = getOrganisationMetaData(history, metaData, t);
    return organisationMetaData;
};
var getOrganisationMetaData = function (history, metaData, t) {
    var _a;
    var organisationMetaData = {
        type: history.path === 'ParentId' ? HistoryLogChangeType.Move : history.type,
        valueName: metaData.valueName,
        newValue: history.newValue || metaData.newValue,
        oldValue: history.oldValue,
    };
    var normalizedPath = history.path.replace('.Value', '').toUpperCase();
    var normalizedName = (_a = metaData.name) === null || _a === void 0 ? void 0 : _a.toUpperCase();
    if (metaData.name === '') {
        organisationMetaData.displayName = t === null || t === void 0 ? void 0 : t("Administrate.Entity.Title");
        organisationMetaData.newValue = history.newValue;
    }
    if (normalizedName === 'users'.toUpperCase()) {
        var path = organisationMetaData.type === HistoryLogChangeType.AddToSet
            ? t === null || t === void 0 ? void 0 : t("Administrate.History.To").toLowerCase()
            : HistoryLogChangeType.RemoveFromSet
                ? t === null || t === void 0 ? void 0 : t("Administrate.History.From").toLowerCase()
                : '';
        organisationMetaData.displayName = "".concat(t === null || t === void 0 ? void 0 : t("Administrate.History.Users.".concat(metaData.newValue)));
        organisationMetaData.additionalPropertyName = "".concat(path, "  ").concat(history.metaData.displayName);
        return organisationMetaData;
    }
    if (normalizedPath.endsWith(nameOf('name').toUpperCase())) {
        organisationMetaData.displayName = t === null || t === void 0 ? void 0 : t("Administrate.Entity.Name");
        organisationMetaData.oldValue = history.oldValue;
        organisationMetaData.newValue = history.newValue;
    }
    if (normalizedPath.endsWith(nameOf('parentId').toUpperCase())) {
        organisationMetaData.displayName = "".concat(t === null || t === void 0 ? void 0 : t("Administrate.Entity.Move.Title"));
        organisationMetaData.additionalPropertyName = history.metaData.displayName;
        organisationMetaData.oldValue = history.oldValue;
        organisationMetaData.newValue = history.newValue;
    }
    return organisationMetaData;
};
