import { AdministratePortfolios as AdministratePortfoliosFeature } from 'features/administrate-portfolios';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const AdministratePortfolios = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View
      title={`${t('Navigation.Administrate.Administrate')} - ${t(
        'Navigation.Administrate.Portfolios'
      )}`}
    >
      <AdministratePortfoliosFeature />
    </View>
  );
};

AdministratePortfolios.displayName = 'AdministratePortfolios';
