import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertLowPrio } from '@dimatech/shared/lib/components/Alert';
import {
  Button,
  Buttons,
  ButtonSecondary,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { Tag } from '@dimatech/shared/lib/components/Tag';
import { Heading4 } from '@dimatech/shared/lib/components/typography';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Phase, Project, Tag as TagModel } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatAsCurrency, isPiosReadOnly } from 'utils';

export const ProjectPublishedPubliclyConfirmation = ({
  project,
  setIsChangingPublishedPublicly,
  handelChangePublishedPublicly,
}: {
  project: Project;
  setIsChangingPublishedPublicly: (
    isChangingPublishedPublicly: boolean
  ) => void;
  handelChangePublishedPublicly: () => void;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const isPublishPubliclyEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isPublishPubliclyEnabled];
  const isAreasOfOperationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isAreasOfOperationEnabled];

  const isViewReadOnly = isPiosReadOnly(accessToken);

  if (!isPublishPubliclyEnabledFlagOn) {
    return null;
  }

  return (
    <Modal
      title={
        !project.isPublishedPublicly
          ? t('Project.IsPublishedPublicly.ConfirmTitleEnable')
          : t('Project.IsPublishedPublicly.ConfirmTitleDisable')
      }
    >
      <div>
        {!project.isPublishedPublicly
          ? t('Project.IsPublishedPublicly.ConfirmTextEnable')
          : t('Project.IsPublishedPublicly.ConfirmTextDisable')}
      </div>

      {!project.isPublishedPublicly && (
        <Style>
          <Heading4>{t('Project.IsPublishedPublicly.PublishedData')}</Heading4>

          <div>
            <div>{t('Project.Title')}</div>
            <div>{project.title}</div>
          </div>

          <div>
            <div>
              {t('Project.Budget', {
                currency: t(
                  `Common.Currency.${accessToken.locale.currency}.Name`
                ),
              })}
            </div>
            <div>{formatAsCurrency(project.budget)}</div>
          </div>

          <div>
            <div>{t('Project.Phase.Phase')}</div>
            <div>
              {project.projectPhase === Phase.ReadyForReview
                ? t(`Project.Phase.Published`)
                : t(`Project.Phase.${project.projectPhase}`)}
            </div>
          </div>

          {isAreasOfOperationEnabledFlagOn && (
            <div>
              <div>{t('Project.NationalTag.Tags')}</div>
              <div>
                {project.nationalTags && project.nationalTags.length > 0
                  ? [...(project.nationalTags ?? [])]
                      ?.sort((tagA, tagB) =>
                        tagA.displayName > tagB.displayName ? 1 : -1
                      )
                      .map((tag: TagModel) => <Tag key={tag.id} tag={tag} />)
                  : '-'}
              </div>
            </div>
          )}

          <div>
            <div>{t('Project.Description')}</div>
            <div style={{ maxHeight: 100, overflowY: 'auto' }}>
              {project.description ?? '-'}
            </div>
          </div>

          <div>
            <div>{t('Project.BenefitsAndEffects')}</div>
            <div style={{ maxHeight: 100, overflowY: 'auto' }}>
              {project.benefitsAndEffects ?? '-'}
            </div>
          </div>
        </Style>
      )}

      <Buttons>
        <ButtonSecondary
          type="button"
          onClick={(e) => setIsChangingPublishedPublicly(false)}
        >
          {t('Common.Form.Cancel')}
        </ButtonSecondary>
        <Button
          type="button"
          onClick={() => handelChangePublishedPublicly()}
          disabled={isViewReadOnly}
        >
          {t('Common.UI.Yes')}
        </Button>
      </Buttons>
    </Modal>
  );
};

ProjectPublishedPubliclyConfirmation.displayName =
  'ProjectPublishedPubliclyConfirmation';

const Style = styled(AlertLowPrio)`
  margin-top: 20px;
  padding: 10px;

  > div {
    margin-top: 5px;

    > div:first-of-type {
      font-weight: bold;
    }
  }
`;
