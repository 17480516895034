import { MultiSelect } from '@dimatech/shared/lib/components/form';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Node, Phase } from 'models';
import { useTranslation } from 'react-i18next';
import { projectIdeaPhases } from 'utils';

export const SelectIdeaStatuses = ({
  statuses,
  setStatuses,
  inverted = false,
}: {
  statuses?: string[];
  setStatuses: (statuses: Phase[]) => void;
  inverted?: boolean;
}): JSX.Element | null => {
  const { t } = useTranslation();

  const isProjectIdeasReviewersEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isProjectIdeasReviewersEnabled];

  const nodes: Node<Phase>[] = projectIdeaPhases.map((value) => ({
    id: value,
    name:
      !isProjectIdeasReviewersEnabledFlagOn && value === Phase.ReadyForReview
        ? t(`Project.Phase.Published`)
        : t(`Project.Phase.${value}`),
    parentId: null,
    payload: value as Phase,
  }));

  const handleChange = (selectedNodes: Node<Phase>[]) => {
    setStatuses(selectedNodes.map((node) => node.payload));
  };

  return (
    <>
      {nodes && (
        <MultiSelect
          inverted={inverted}
          style={{ marginRight: 10 }}
          placeholder={t('FutureProjects.ProjectIdea.AllProjectIdeaStatuses')}
          options={nodes}
          setSelected={(selectedNodes) => {
            handleChange(selectedNodes);
          }}
          showSelectAll={false}
          selected={nodes.filter((node) => statuses?.includes(node.id))}
        />
      )}
    </>
  );
};

SelectIdeaStatuses.displayName = 'SelectIdeaStatuses';
